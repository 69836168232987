import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, Subject, takeUntil } from 'rxjs';
import { UnicreditFormBody } from 'src/app/shared/models/unicredit-form.model';
import { UnicreditService } from '../unicredit-service/unicredit.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-unicredit-landing-page',
  templateUrl: './unicredit-landing-page.component.html',
  styleUrls: ['./unicredit-landing-page.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule]
})
export class UnicreditLandingPageComponent implements OnInit, AfterViewInit, OnDestroy {
  registrationForm!: UntypedFormGroup;
  formSubmitted = false;
  validationError: string = '';

  private unsubscribe = new Subject<void>()

  constructor(private unicreditService: UnicreditService, private router: Router, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((param) => {
      let token = param['token']
      this.unicreditService.getDecodedToken(token).subscribe((token) => {
        this.validationError = this.unicreditService.checkTokenExpiration(token.body.decoded);
        if (this.validationError) {
          console.error('An error occurred:', this.validationError);
        }
      })
    } );

    this.registrationForm = new UntypedFormGroup({
      nome: new UntypedFormControl('', [Validators.required]),
      cognome: new UntypedFormControl('', [Validators.required]),
      telefono: new UntypedFormControl('', [Validators.required, Validators.pattern(/^\d{10}$/)]), // Valida solo numeri di 10 cifre
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      citta: new UntypedFormControl('', [Validators.required]),
      provincia: new UntypedFormControl('', [Validators.required]),
      codiceFiscale: new UntypedFormControl('', [Validators.required, Validators.pattern('^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$')]),
      contactMode: new UntypedFormControl('', [Validators.required]),
      servizio: new UntypedFormControl('', [Validators.required]),
      privacy: new UntypedFormControl(false, [Validators.requiredTrue]),
      terms: new UntypedFormControl(false, [Validators.requiredTrue]),
      validationError: new UntypedFormControl(''),
    });
    this.registrationForm.valueChanges.pipe(debounceTime(250)).subscribe((value:any) => this.formSubmitted = false);
  }

  ngAfterViewInit(): void {
    this.rotateSpinner()
  }

  sendForm() {
    this.formSubmitted = true;
    const info: UnicreditFormBody = {
      ...this.registrationForm.value,
      validationError: this.validationError
    }


    this.unicreditService.sendForm(info).pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      if (this.registrationForm.get('servizio')!.value === 'babysitter-diurna' || this.registrationForm.get('servizio')!.value === 'babysitter-notturna'
        || this.registrationForm.get('servizio')!.value === 'taxi-sitter' || this.registrationForm.get('servizio')!.value === 'aiuto-compiti') {
        window.location.href = 'https://familyhappybenefit.com/thank-you-richiesta-preventivo-babysitter'
      } else if (this.registrationForm.get('servizio')!.value === 'badante-diurna' || this.registrationForm.get('servizio')!.value === 'badante-convivente'
        || this.registrationForm.get('servizio')!.value === 'badante-occasionale') {
        window.location.href = 'https://familyhappybenefit.com/thank-you-preventivo-badanti'
      } else if (this.registrationForm.get('servizio')!.value === 'petsitter') {
        window.location.href = 'https://familyhappybenefit.com/thank-you-richiesta-preventivo-petsitter'
      }
    })
  }


  private rotateSpinner() {
    // Seleziona tutti gli spinner
    const spinners = document.querySelectorAll('.spinner') as NodeListOf<HTMLElement>;

    spinners.forEach(spinner => {
      let currentRotation = 90; // Partenza da 90 gradi (posizione verticale)
      let lastScrollY = window.scrollY;
      let isInViewport = false; // Flag per sapere se lo spinner è visibile

      // Funzione per controllare se lo spinner è visibile nel viewport
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            isInViewport = true; // Lo spinner è visibile
          } else {
            isInViewport = false; // Lo spinner è fuori dal viewport
          }
        });
      });

      // Osserva lo spinner
      observer.observe(spinner);

      window.addEventListener('scroll', function () {
        // Esegui solo se lo spinner è visibile a schermo
        if (!isInViewport) return;

        const currentScrollY = window.scrollY;

        // Se l'utente scorre verso il basso e non abbiamo superato 180 gradi
        if (currentScrollY > lastScrollY && currentRotation < 270) {
          currentRotation += 1; // Incrementa la rotazione (senso orario)
        }
        // Se l'utente scorre verso l'alto e non abbiamo superato -180 gradi
        else if (currentScrollY < lastScrollY && currentRotation > 90) {
          currentRotation -= 1; // Decrementa la rotazione (senso antiorario)
        }

        // Applica la rotazione solo se siamo entro i limiti
        spinner.style.transform = `translateY(-50%) rotate(${currentRotation}deg)`;

        // Aggiorna la posizione dello scroll
        lastScrollY = currentScrollY;
      });
    });

  }

  ngOnDestroy(): void {
    this.unsubscribe.next()
    this.unsubscribe.complete()
  }
}
