import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fhdate',
  standalone:true
})
export class MyDatePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if (value === '') {
      return 'Prima possibile';
    }
    const date = new Date(value);
    const now = new Date();
    const daysDifference = (now.getTime() - date.getTime()) / (1000 * 3600 * 24);
    if (daysDifference > -3) {
      return 'Prima possibile';
    } else {
      return date.toLocaleDateString('it-IT', { day: '2-digit', month: 'long', year: 'numeric' });
    }
  }

}
