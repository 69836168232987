import { of, switchMap } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BabysitterService } from 'src/app/services/babysitter-service/babysitter.service';
import { FormService } from 'src/app/services/form-service/form.service';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { ModalLoaderComponent } from 'src/app/shared/components/modal-loader/modal-loader.component';

@Component({
  selector: 'app-psycho-attitudinal-test',
  templateUrl: './psycho-attitudinal-test.component.html',
  styleUrls: ['./psycho-attitudinal-test.component.scss'],
  standalone:true,
  imports:[CommonModule, ModalLoaderComponent, ReactiveFormsModule]
})
export class PsychoAttitudinalTestComponent implements OnInit {
  form = new UntypedFormGroup({
    1: new UntypedFormControl('', Validators.required),
    2: new UntypedFormControl('', Validators.required),
    3: new UntypedFormControl('', Validators.required),
    4: new UntypedFormControl('', Validators.required),
    5: new UntypedFormControl('', Validators.required),
    6: new UntypedFormControl('', Validators.required),
    7: new UntypedFormControl('', Validators.required),
    8: new UntypedFormControl('', Validators.required),
    9: new UntypedFormControl('', Validators.required),
    10: new UntypedFormControl('', Validators.required),
    11: new UntypedFormControl('', Validators.required),
    12: new UntypedFormControl('', Validators.required)
  });

  babysitterID!: string;
  isDangerous: boolean = false;
  modalLoader = false
  results = {
    "Facilitatore": 0,
    "Disciplina": 0,
    "Accondiscendente": 0,
    "Esecutore": 0
  };

  allAnswers = {
    name: '',
    surname: '',
    email: '',
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: ''
  };

  babysitterProfile!: string;

  constructor(private activatedRoute: ActivatedRoute, private babysitterService: BabysitterService, private formService: FormService, private router: Router, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe(map => {
      this.babysitterID = map.get('id') ?? '';
      this.babysitterService.getBabySitter(this.babysitterID).subscribe(babysitters => {
        let babysitter = babysitters[0];
        this.allAnswers.name = Object.values(babysitter)[2].name;
        this.allAnswers.surname = Object.values(babysitter)[2].surname;
        this.allAnswers.email = Object.values(babysitter)[2].email;
      });
    });

    this.form.valueChanges.subscribe(value => {
      this.results = {
        "Facilitatore": 0,
        "Disciplina": 0,
        "Accondiscendente": 0,
        "Esecutore": 0
      };

      let array: Array<string> = Object.values(value);
      if (this.form.valid) {
        array.forEach((element: string) => {
          switch (element) {
            case "C":
              this.results['Facilitatore']++;
              break;

            case "C&C":
              this.results['Disciplina']++;
              break;

            case "H":
              this.results['Accondiscendente']++;
              break;

            case "I":
              this.results['Esecutore']++;
              break;
          }
        });
      }

      if (this.form.get("11")?.value === "H") {
        this.isDangerous = true;
      } else {
        this.isDangerous = false;
      }
    });
  }

  shuffle(array: Array<any>) {
    let currentIndex = array.length, randomIndex;

    while (currentIndex != 0) {

      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  getResults() {
    let maxNumber = Math.max(this.results['Facilitatore'], this.results['Disciplina'], this.results['Accondiscendente'], this.results['Esecutore']);
    let results = this.shuffle(Object.entries(this.results));
    for (let i = 0; i < results.length; i++) {
      if (results[i][1] === maxNumber) {
        this.babysitterProfile = results[i][0];
        break;
      }
    }

    let answers = this.form.value;

    this.allAnswers[1] = answers[1];
    this.allAnswers[2] = answers[2];
    this.allAnswers[3] = answers[3];
    this.allAnswers[4] = answers[4];
    this.allAnswers[5] = answers[5];
    this.allAnswers[6] = answers[6];
    this.allAnswers[7] = answers[7];
    this.allAnswers[8] = answers[8];
    this.allAnswers[9] = answers[9];
    this.allAnswers[10] = answers[10];
    this.allAnswers[11] = answers[11];
    this.allAnswers[12] = answers[12];
  }

  submitForm() {
    this.modalLoader = true
    this.getResults();
    let data = {
      attitude: this.babysitterProfile,
      dangerous: this.isDangerous
    };
    this.babysitterService.setAttitude(data, this.babysitterID).subscribe((res) => {
      this.router.navigate(['thank-you/' + this.allAnswers.email], {queryParams: {fromCV: true, name: this.allAnswers.name}});
      this.modalLoader = false;
      this.modalService.dismissAll()
    });
  }

}
