import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'npx-family-happy-common';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-confirm-quotation',
  templateUrl: './confirm-quotation.component.html',
  styleUrls: ['./confirm-quotation.component.scss'],
  standalone:true,
  imports:[CommonModule]
})
export class ConfirmQuotationComponent implements OnInit {
  confirmed = false;

  constructor(private activatedRoute: ActivatedRoute, private http: HttpClient, private modalService: ModalService) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      const requestID = params.get('id');
      this.http.post(`${environment.baseURL}/v1/requests/confirm-quotation?requestID=${requestID}`, {}).subscribe(() => {
        this.modalService.outcomeType.next('Success');
        this.modalService.outcomeMessage.next('Preventivo confermato');
        this.modalService.showOutcome.next(true);
        this.confirmed = true;
      })
    })
  }

}
