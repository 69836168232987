<div class="container p-4" *ngIf="!isFaultySession">
  <div class="row mt-3" [ngClass]="isCaregiverPayment ? 'd-flex flex-column align-items-center': ''">
    <div class="col-md-6">
      <div class="d-grid justify-content-center">
        <img src="/assets/img/family-happy-logo.svg" alt="">
      </div>
      <p class="text-center my-5 fw-semibold" *ngIf="isCaregiverPayment">Ottimo, inizia ora la tua fase di
        <b>registrazione</b>.
      </p>
      <div class="mt-5 d-flex flex-column gap-3" *ngIf="serviceOverview && !isCaregiverPayment">
        <div class="d-flex flex-column align-items-center" *ngFor="let product of serviceOverview.products">
          <p class="text-center text-md-start grey-text-colored m-0 mb-2">{{product.productName}}</p>
          <div class="d-flex flex-column gap-1 align-items-center col col-md-12 mt-2">
            <div class="d-flex gap-1 align-items-center">
              <span *ngIf="!couponCode && !unicreditCustomer && product.amount > 0" class="text-center text-md-start fw-bold"
                style="font-size: 36px;">€{{(product.amount/100).toFixed(2)}}</span>
              <span *ngIf="!couponCode && !unicreditCustomer && !product.amount" class="text-center text-md-start fw-bold"
                style="font-size: 36px;">€{{(payment.amount/100).toFixed(2)}}</span>
              <p *ngIf="couponCode || unicreditCustomer">
                <span class="text-center text-md-start fw-bold"
                  style="font-size: 36px; text-decoration: line-through;">€ {{ (getOriginalPrice(product.amount)/
                  100).toFixed(2) }}</span>
                <span style="font-size: 36px;" class="ml-2 total-label fw-bold">€{{ (product.amount / 100).toFixed(2)
                  }}</span>
                <span class="subscription-label ps-1">{{getSubscriptionPeriod(product.productType)}}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="d-flex gap-1 align-items-center justify-content-center">
          <p class="total-label mt-3 text-center"
            *ngIf="(!couponCode && !unicreditCustomer) && serviceOverview.products.length > 1">
            Totale: €{{(serviceOverview.total/100).toFixed(2)}}
          </p>
          <p class="discount-label mt-3 text-center" *ngIf="(couponCode || unicreditCustomer)">
            <span class="total-label" style="text-decoration: line-through;">
              Totale: €{{(getOriginalPrice(serviceOverview.total)/100).toFixed(2)}}
            </span>
            <span class="ml-2 total-label">
              €{{ (serviceOverview.total / 100).toFixed(2) }}
            </span>
          </p>
        </div>
        <hr class="d-md-none w-100">
      </div>
    </div>
    <div class="col-md-6 h-md-100 mt-4 bg-light rounded shadow-container p-3">
      <h4 *ngIf="payment && payment.service.name === 'Servizio dipendenti Penny'">Dati di fatturazione del Dipendente
      </h4>
      <form [formGroup]="form" class="row py-3">
        <div class="col-12 mb-3">
          <label for="name" class="form-label">Nome<span class="text-danger">*</span></label>
          <input type="text" id="name" class="form-control" formControlName="name" autocomplete="hoff">
          <p class="form-error" *ngIf="form.get('name')?.errors?.['required'] && form.get('name')?.touched">Il campo è
            obbligatorio.</p>
        </div>
        <div class="col-12 mb-3">
          <label for="surname" class="form-label">Cognome<span class="text-danger">*</span></label>
          <input type="text" id="surname" class="form-control" formControlName="surname" autocomplete="hoff">
          <p class="form-error" *ngIf="form.get('surname')?.errors?.['required'] && form.get('surname')?.touched">Il
            campo
            è
            obbligatorio.</p>
        </div>
        <div class="col-12 mb-3">
          <label for="email" class="form-label">
            <span>Email<span class="text-danger">*</span></span>
            <!-- <div class="spinner-border text-primary ms-2" role="status" *ngIf="isLoading">
            </div> -->
          </label>
          <input type="text" id="email" class="form-control" formControlName="email" autocomplete="hoff"
            [readonly]="!isCaregiverPayment">
          <p class="form-error" *ngIf="form.get('email')?.errors?.['required'] && form.get('email')?.touched">Il campo è
            obbligatorio.</p>
          <p class="form-error" *ngIf="form.get('email')?.errors?.['email'] && form.get('email')?.touched">L'indirizzo
            email
            fornito non è valido.</p>
          <!-- <p class="form-error" *ngIf="form.get('email')?.valid && !isLoading && noPayerFound">L'indirizzo email
            fornito non è presente nei nostri sistemi. Si prega di inserire l'indirizzo email con cui ha effettuato la
            registrazione su FamilyHappy.</p> -->
        </div>
        <div>
          <div class="col-12 p-0 mb-3" *ngIf="isCaregiverPayment">
            <label for="phone" class="form-label">Cellulare<span class="text-danger">*</span></label>
            <input type="text" id="phone" class="form-control" formControlName="phone" autocomplete="hoff">
            <p class="form-error" *ngIf="form.get('phone')?.errors?.['required'] && form.get('phone')?.touched">Il campo
              è
              obbligatorio.</p>
          </div>
          <div class="col-12 p-0 mb-3" *ngIf="isCaregiverPayment">
            <label for="provinceCode" class="form-label">Provincia di interesse<span
                class="text-danger">*</span></label>
            <app-province-filter [provincia]="form.get('provinceCode')?.value"
              (click)="form.get('provinceCode')?.markAsTouched();"
              (valueChange)="updateProvincia($event)"></app-province-filter>
            <p class="form-error"
              *ngIf="form.get('provinceCode')?.errors?.['required'] && form.get('provinceCode')?.touched">Il campo è
              obbligatorio.</p>
          </div>
          <div class="col-12 p-0 mb-3">
            <label for="fiscalCode" class="form-label">Codice Fiscale<span class="text-danger">*</span></label>
            <input type="text" id="fiscalCode" class="form-control" formControlName="fiscalCode" autocomplete="hoff"
              oninput="this.value = this.value.toUpperCase()">
            <p class="form-error"
              *ngIf="form.get('fiscalCode')?.errors?.['required'] && form.get('fiscalCode')?.touched">
              Il
              campo è
              obbligatorio.</p>
            <p class="form-error"
              *ngIf="form.get('fiscalCode')?.errors?.['pattern'] && form.get('fiscalCode')?.touched">
              Il
              codice fiscale
              inserito non è valido.</p>
          </div>
          <div class="col-12 p-0 mb-3">
            <label for="fhinput" class="form-label">
              {{this.isCaregiverPayment ? 'Indirizzo' : 'Indirizzo di fatturazione'}}
              <span class="text-danger">*</span></label>
            <input type="text" id="fhinput" class="form-control" formControlName="street" autocomplete="on">
            <p class="form-error" *ngIf="form.get('street')?.errors?.['required'] && form.get('street')?.touched">Il
              campo è obbligatorio.</p>
            <p class="form-error" *ngIf="form.get('street')?.errors?.['pattern'] && form.get('street')?.touched">Il
              numero civico è obbligatorio.</p>
          </div>
          <div class="col-12 p-0 mb-3">
            <label for="city" class="form-label">Città<span class="text-danger">*</span></label>
            <input type="text" id="city" class="form-control" formControlName="city" autocomplete="hoff">
            <p class="form-error" *ngIf="form.get('city')?.errors?.['required'] && form.get('city')?.touched">Il campo è
              obbligatorio.</p>
          </div>
          <div class="col-12 p-0 mb-3">
            <label for="province" class="form-label">Provincia<span class="text-danger">*</span></label>
            <input type="text" id="province" class="form-control" formControlName="province" autocomplete="hoff">
            <p class="form-error" *ngIf="form.get('province')?.errors?.['required'] && form.get('province')?.touched">Il
              campo è
              obbligatorio.</p>
            <p class="form-error" *ngIf="form.get('province')?.errors?.['maxlength'] && form.get('province')?.touched">
              La provincia va inserita come sigla e non per esteso. (es. MI per Milano)</p>
          </div>
          <div class="col-12 p-0 mb-3">
            <label for="postalCode" class="form-label">Cap<span class="text-danger">*</span></label>
            <input type="text" id="postalCode" class="form-control" formControlName="postalCode" autocomplete="hoff">
            <p class="form-error"
              *ngIf="form.get('postalCode')?.errors?.['required'] && form.get('postalCode')?.touched">
              Il
              campo è
              obbligatorio.</p>
          </div>
          <div class="col-12 p-0 mb-3">
            <label for="country" class="form-label">Stato<span class="text-danger">*</span></label>
            <input type="text" id="country" class="form-control" formControlName="country" autocomplete="hoff">
            <p class="form-error" *ngIf="form.get('country')?.errors?.['required'] && form.get('country')?.touched">Il
              campo
              è
              obbligatorio.</p>
          </div>
          <div class="col-12 mb-3 form-check">
            <input type="checkbox" class="form-check-input" id="privacy" formControlName="privacy" autocomplete="hoff">
            <label for="privacy" class="form-check-label">Acconsento al trattamento dei dati in base alla <a
                href="https://babysitter.familyhappy.it/informativa-privacy">Privacy Policy</a> per comunicazioni
              commerciali e
              informative su prodotti e servizi di Family+Happy</label>
          </div>
          <p class="form-error" *ngIf="submissionError !== ''" [innerHTML]="submissionError"></p>
        </div>
      </form>
      <button class="btn btn-primary text-light mt-3" (click)="goToPayment()" [disabled]="!this.form.valid">
        <div class="spinner-border spinner-border-sm text-light me-1" *ngIf="isLoading"></div>
        <span id="button-text">Vai al pagamento</span>
      </button>
    </div>
    <p class="mt-5 p-2" style="font-size: 13px;" *ngIf="unicreditCustomer">
      <b>Messaggio pubblicitario con finalità promozionale.</b><br>

      La promozione si riferisce ai pagamenti effettuati esclusivamente sul sito Family+Happy, con la carta in modalità
      a
      saldo, contabilizzati entro l’ultimo giorno lavorativo del mese di riferimento e rateizzabili. Per tutti gli
      altri piani di rimborso in cui non sono previste iniziative a tasso zero, è prevista l’applicazione di un tasso
      di interesse che varia in base all’importo e al numero di rate scelte, come previsto dal contratto e
      rappresentato anche dalla tabella dei costi. Per le condizioni contrattuali delle carte di credito a rimborso
      opzionale della gamma UniCreditCardFlexia e delle carte di credito buddy by UniCredit, consultare il “Modulo
      Informazioni Europee di Base sul Credito ai Consumatori” in fase di richiesta. Le carte di credito della gamma
      Flexia e le carte di credito buddy by UniCredit sono emesse in modalità a saldo. Esempio di rimborso acquisto
      mensile (opzionale): TAN 0% e TAEG 0%, acquisto di 1.200,00€ rateizzato in 6 mesi, totale competenze 0 (di cui
      interessi 0€, altri costi 0€), rata mensile 200,00€, totale dovuto dal consumatore 1.200,00€. Il costo di
      rateizzazione corrisponde al tasso applicato. Prodotti venduti da UniCredit che si riserva la valutazione del
      merito creditizio. UniCredit S.p.A. è responsabile unicamente dei propri servizi e prodotti, ai prodotti
      Family+Happy
      si applicheranno le condizioni da quest’ultima praticate.
    </p>
  </div>
</div>
<app-page-message [title]="'Si è verificato un errore.'" *ngIf="isFaultySession">
  <p>Sembra che questo link di pagamento non sia più valido, rivolgersi al proprio <b>operatore</b> di riferimento
    oppure invia
    una mail a <a href="mailto:servizioclienti@familyhappybenefit.com">servizioclienti&#64;familyhappybenefit.com</a>.</p>
</app-page-message>
