import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { concatMap, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from 'src/environments/environment';
import { Payment, CustomerType, ProvinceFilterComponent } from 'npx-family-happy-common';
import { PaymentService } from '../../services/payment-service/payment.service';
import { InvoiceDetailBody } from '../../shared/models/invoice-detail-body.model';
import { ServicesService } from 'src/app/services/services-service/services.service';
import { ServiceOverview } from 'src/app/shared/models/service-overview.modet';
import { BabysitterService } from 'src/app/services/babysitter-service/babysitter.service';
import { NurseService } from 'src/app/services/nurse-service/nurse.service';
import { PetsitterService } from 'src/app/services/petsitter-service/petsitter.service';
import { CaregiverCreationBody } from 'src/app/shared/models/caregiver-creation-body.model';
import { ActiveCampaignService } from 'src/app/shared/services/active-campaign-service/active-campaign.service';
import { CaregiverPaymentCreationBody } from 'src/app/shared/models/caregiver-payment-creation-body.model';
import { CommonModule } from '@angular/common';
import { PageMessageComponent } from 'src/app/shared/components/page-message/page-message.component';
import { GoogleMapsService } from 'src/app/services/maps-service/google-maps.service';

@Component({
  selector: 'app-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ProvinceFilterComponent, PageMessageComponent]
})
export class CheckoutPageComponent implements OnInit {
  form = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    fiscalCode: new UntypedFormControl('', [Validators.required, Validators.pattern('^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$')]),
    name: new UntypedFormControl('', [Validators.required]),
    surname: new UntypedFormControl('', [Validators.required]),
    street: new UntypedFormControl('', [Validators.required, Validators.pattern('^.+,\\s+[\\dA-Z]+$')]),
    city: new UntypedFormControl('', [Validators.required]),
    province: new UntypedFormControl('', [Validators.required, Validators.maxLength(2)]),
    postalCode: new UntypedFormControl('', [Validators.required]),
    country: new UntypedFormControl('', [Validators.required]),
    privacy: new UntypedFormControl(false, [Validators.required, Validators.requiredTrue]),
    phone: new UntypedFormControl('', [Validators.required]),
    provinceCode: new UntypedFormControl('', [Validators.required])
  });
  category: string = '-';
  paymentID: string = '-';
  emailPayer: string = '';
  seller: string = '';
  noPayerFound = false;
  isLoading = false;

  stripe: any;
  stripeElements: any;
  payment!: Payment;
  stripeFormLoaded = false;
  couponCode: string | null = null
  unicreditCustomer: boolean = false

  isCaregiverPayment = false;
  expiredSession = false;
  isUpfrontPayment = false;
  submissionError = '';

  serviceOverview!: ServiceOverview;

  coordinates: any = {}

  private emailCheckFailed = false; // To avoid people misusing the payment information

  private unsubscribe = new Subject<void>();
  environment: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private servicesService: ServicesService,
    private paymentService: PaymentService,
    private babysitterService: BabysitterService,
    private petsitterService: PetsitterService,
    private nurseService: NurseService,
    private activeCampaignService: ActiveCampaignService,
    private changeRef: ChangeDetectorRef,
    private googleMapsService: GoogleMapsService
  ) {
    this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
      this.paymentID = params['payment'];
      this.emailPayer = params['email'];
      this.category = params['category'];
      if (this.category === 'BADANTI') {
        this.category = 'NURSE';
      }
      this.seller = params['seller'];
      if (!this.paymentID && !this.emailPayer && !this.category) {
        this.expiredSession = true;
      }
    })
  }

  ngOnInit(): void {
    if (this.paymentID) {
      // Hide phone and provinceCode fields, available for caregivers only
      this.form.get('phone')?.setValue('');
      this.form.get('provinceCode')?.setValue('');
      this.form.get('phone')?.removeValidators(Validators.required);
      this.form.get('provinceCode')?.removeValidators(Validators.required);

      // Get payment info from backend
      this.paymentService.getPaymentByID(+this.paymentID)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((payment) => {
          // Check whether the email provided is the one linked to the payment object
          if (payment.emailPayer === this.emailPayer) {
            //this.handlePaymentData(payment);
            if (payment.service.customerType === 'UNICREDIT') {
              this.unicreditCustomer = true
            } else if (payment.couponCode) {
              this.couponCode = payment.couponCode
            }
            // Check if the service linked is for upfront payment
            if (payment.service.name.match('UPFRONT')) {
              this.isUpfrontPayment = true;
              this.form.addControl('upfrontTerms', new UntypedFormControl(false, [Validators.required, Validators.requiredTrue]));
            }
            this.payment = payment;
            // Init form email so that customers cannot change it
            this.form.get('email')?.setValue(this.emailPayer);

            // Load Service details
            this.servicesService.getFHServiceOverview(payment.service.id, (payment.service.name === 'Pacchetto ore' || payment.service.customerType === CustomerType.B2B) ? null : payment.provinceCode).subscribe((overview) => {
              this.serviceOverview = overview;

              overview.products.sort((prodA, prodB) => {
                const valueA = prodA.productType === 'SERVICE' ? 1 : 0;
                const valueB = prodB.productType === 'SERVICE' ? 1 : 0;
                if (valueA > valueB) {
                  return -1;
                } else if (valueA < valueB) {
                  return 1;
                } else {
                  return 0;
                }
              })
            })
          } else {
            // If the email is not the same, show an error message and block everything else
            this.emailCheckFailed = true;
          }
        });
    } else {
      if (this.category) {
        this.isCaregiverPayment = true;
        // Load Service details
        this.servicesService.getFHServiceOverviewByBusiness(CustomerType.CAREGIVER, this.category).subscribe((overview) => {
          this.serviceOverview = overview;
        })
      }
    }

    if (!environment.production)
      this.form.valueChanges.pipe(takeUntil(this.unsubscribe)).subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit(): void {
    /* Google Maps */
    let inputAddress = document.getElementById('fhinput') as HTMLInputElement;
    const loader = new Loader({ apiKey: environment.googleMapsAPIKey, version: "weekly", libraries: ["places"], language: 'it-IT' }).load().then((google) => {
      /* const mapOptions = {
        fields: ["formatted_address", "geometry", "name"],
        strictBounds: false,
        types: ["establishment"],
      }; */

      const autocomplete = new google.maps.places.Autocomplete(inputAddress);

      // Required to avoid that users may use bad suggestions
      setTimeout(() => {
        // @ts-ignore Ignore lint error
        inputAddress.autocomplete = 'hoff';
      }, 2000);

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        let addressParts: Array<string> = [];
        let formAddress = this.form.get('street');
        let formCity = this.form.get('city');
        let formCap = this.form.get('postalCode');
        let street = '';
        let streetNumber = '';

        place.address_components?.forEach((component: any) => {
          component.types.forEach((type: any) => {
            addressParts.push(type);
          });

          if (component.types.includes('route')) {
            street = component.long_name;
          }

          if (component.types.includes('street_number')) {
            streetNumber = component.long_name;
          }

          if (component.types.includes('administrative_area_level_2')) {
            this.form.get('province')?.setValue(component.short_name);
          }
          // Compile other address fields according to place object
          if (component.types.includes('locality')) {
            formCity?.setValue(component.long_name);
          } else if (component.types.includes('administrative_area_level_3')) {
            formCity?.setValue(component.long_name);
          }
          if (component.types.includes('postal_code')) {
            formCap?.setValue(component.long_name);
          }
          if (component.types.includes('country')) {
            this.form.get('country')?.setValue(component.long_name);
          }
        });
        const address = `${street}, ${streetNumber}`;

        if (place.formatted_address !== undefined) {
          this.getCoordiantes(place.formatted_address).then((res) => {
            this.coordinates = {
              latitude: res.latitude,
              longitude: res.longitude
            }
          }).catch((err) => {
            console.log(err)
          })
        }
        formAddress?.setValue(address);
        this.changeRef.detectChanges();
        //formAddress?.setValidators([Validators.required, createAddressValidator(addressParts)]);
      })
    });
  }

  goToPayment() {
    if (this.form.valid) {
      this.isLoading = true;
      this.submissionError = '';
      // Update invoice details on payment
      const body: InvoiceDetailBody = {
        city: this.form.value.city,
        country: this.form.value.country,
        fiscalCode: this.form.value.fiscalCode,
        name: this.form.value.name,
        postalCode: this.form.value.postalCode,
        province: this.form.value.province,
        street: this.form.value.street,
        surname: this.form.value.surname,
        email: this.form.value.email,
        latitude: this.coordinates.latitude,
        longitude: this.coordinates.longitude
      }
      if (this.isCaregiverPayment) {
        const caregiverBody: CaregiverCreationBody = {
          ...body,
          phone: this.form.value.phone,
          provinceCode: this.form.value.provinceCode,
          latitude: this.coordinates.latitude,
          longitude: this.coordinates.longitude
        }
        // Create Payment for Caregiver
        const caregiverPaymentBody: CaregiverPaymentCreationBody = {
          billingInfo: body,
          phone: caregiverBody.phone,
          provinceCode: caregiverBody.provinceCode,
          alias: this.seller,
          serviceID: this.serviceOverview.serviceID,
          products: this.serviceOverview.products
        }
        // Create caregiver payment
        this.paymentService.createCaregiverPayment(caregiverPaymentBody)
          .pipe(switchMap((res) => this.paymentService.getPaymentByID(res.paymentID)))
          .subscribe((payment) => {
            // Create caregiver account
            switch (this.category) {
              case 'BABYSITTER':
                this.babysitterService.createBabysitterFromPayment(caregiverBody).pipe(
                  tap(() => console.log('Babysitter profile created.')),
                  concatMap((babysitter) =>
                    this.paymentService.updateURLRelated(payment.id, '/babysitter-detail/' + babysitter.docRef).pipe(
                      tap(() => console.log('URL Related updated.')),
                      concatMap(() =>
                        this.activeCampaignService.addCaregiverToACList(caregiverBody.email, 89).pipe(
                          tap(() => {
                            if (!environment.production) {
                              console.log('Babysitter added to start_checkout list.');
                            }
                          }),
                          tap(() => this.handlePaymentData(payment))
                        )
                      )
                    )
                  )
                ).subscribe();
                break;
              case 'PETSITTER':
                this.petsitterService.createPetsitterFromPayment(caregiverBody).pipe(
                  tap(() => console.log('Babysitter profile created.')),
                  concatMap((petsitter) =>
                  this.paymentService.updateURLRelated(payment.id, '/petsitter-detail/' + petsitter.docRef).pipe(
                    tap(() => console.log('URL Related updated.')),
                    concatMap(() => this.activeCampaignService.addCaregiverToACList(caregiverBody.email, 89).pipe(
                      tap(() => console.log('Petsitter added to start_checkout list.')),
                      tap(() => { this.handlePaymentData(payment) })
                    )
                    )
                  )
                )
                ).subscribe()
                break;
              case 'NURSE':
                this.nurseService.createNurseFromPayment(caregiverBody).pipe(
                  tap(() => console.log('Nurse profile created.')),
                  concatMap((nurse) =>
                  this.paymentService.updateURLRelated(payment.id, '/nurse-detail/' + nurse.docRef).pipe(
                    tap(() => console.log('URL Related updated.')),
                    concatMap(() => this.activeCampaignService.addCaregiverToACList(caregiverBody.email, 89).pipe(
                      tap(() => console.log('Nurse added to start_checkout list.')),
                      tap(() => { this.handlePaymentData(payment) })
                    )
                    )
                  )
                )
                ).subscribe()
                break;
              default:
                break;
            }
          });
      } else {
        this.paymentService.updateInvoiceDetail(this.payment.id, body, this.form.value.phone, this.form.value.provinceCode).subscribe({
          next: (payment) => {
            this.handlePaymentData(payment);
          },
          error: () => {
            this.submissionError = `Si è verificato un errore nell'aggiornamento delle informazioni di fatturazione.<br>
          Si prega di ricontrollare i dati e riprovare.<br>
          Se l'errore persiste, puoi segnalarlo a <a href="mailto:servizioclienti@familyhappybenefit.com">servizioclienti@familyhappybenefit.com</a>.`;
            this.isLoading = false;
          }
        });
      }
    }

  }

  getSubscriptionPeriod(period: string) {
    switch (period) {
      case 'SUBSCRIPTION':
        return 'ogni mese';
      case 'SUBSCRIPTION3':
        return 'ogni 3 mesi';
      case 'SUBSCRIPTION12':
        return 'ogni anno';
      case 'SUBSCRIPTION':
      default:
        return '';
    }
  }

  getOriginalPrice(amount: number) {
    switch (amount) {
      case 4910:
      default:
        return 7910;
      case 990:
        return 1990;
      case 5900:
        return 9900;
    }
  }

  updateProvincia(provinceCode: string) {
    this.form.get('provinceCode')?.setValue(provinceCode);
  }

  private handlePaymentData(payment: Payment) {
    this.payment = payment;
    if (this.payment.status === 'Completed') {
      this.expiredSession = true;
    } else {
      // Redirect to Invoice payment page
      window.location.href = payment.checkoutURL;
    }
  }

  get isFaultySession() {
    return this.emailCheckFailed || this.expiredSession;
  }

  //SCRIPT --- CONVERT ADDRESSES INTO COORDINATES
  private async getCoordiantes(address: string) {
    let response = await this.googleMapsService.getCoordinates(address).then((res) => {
      const lat = res[0].geometry.location.lat();
      const lng = res[0].geometry.location.lng();
      const coordinates = {
        latitude: lat,
        longitude: lng
      }
      return coordinates
    })
    return response
  }
}
