<div style="margin: 50px auto;" class="limit-content">
  <div class="title-container">
    <h1>Complimenti sei vicinissim* alla conclusione.</h1>

    <p>Ecco l’ultima fase. Completa il questionario attitudinale di 4 minuti sul tuo stile di babysitter.</p>
    <p>
      È l’ultimo sforzo. Dopo di che riceverai il tuo cv professionalizzato via mail.
      Questo ci aiuterà a trovare la famiglia più giusta per te ed è molto apprezzato dai genitori.
    </p>
  </div>

  <form [formGroup]="form">
    <div class="blue-box"></div>
    <div class="grid-container">
      <div>
        <span>Il bambino di 4 anni che ti è stato affidato scoppia a piangere perché vuole la mamma. Cosa
          fai?</span>
        <div class="radio-list">
          <label for="1-h">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('1')?.value === 'H')}">
              <input style="display: none" type="radio" id="1-h" formControlName="1" name="1" value="H" />
              Lo coccolo un po' e penso che gli passerà.
            </div>
          </label>

          <label for="1-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('1')?.value === 'C')}">
              <input style="display: none" type="radio" id="1-c" formControlName="1" name="1" value="C" />
              Lo conforto e provo a distrarlo proponendogli un'attività che potrebbe interessarlo.
            </div>
          </label>

          <label for="1-i">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('1')?.value === 'I')}">
              <input style="display: none" type="radio" id="1-i" formControlName="1" name="1" value="I" />
              Telefono alla mamma per chiedere consigli.
            </div>
          </label>

          <label for="1-cc">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('1')?.value === 'C&C')}">
              <input style="display: none" type="radio" id="1-cc" formControlName="1" name="1" value="C&C" />
              Gli dico di calmarsi e che la mamma arriverà presto.
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>Il bambino urla e non vuole mangiare la pappa. Cosa fai?</span>
        <div class="radio-list">
          <label for="2-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('2')?.value === 'C')}">
              <input style="display: none" type="radio" id="2-c" formControlName="2" name="2" value="C" />
              Trasformo il pranzo in un gioco per distrarlo e farlo mangiare.
            </div>
          </label>

          <label for="2-h">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('2')?.value === 'H')}">
              <input style="display: none" type="radio" id="2-h" formControlName="2" name="2" value="H" />
              Ritardo l’ora del pasto, quando sara’ affamato accetterà la pappa.
            </div>
          </label>

          <label for="2-cc">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('2')?.value === 'C&C')}">
              <input style="display: none" type="radio" id="2-cc" formControlName="2" name="2" value="C&C" />
              Lo faccio stare seduto finché non mangia.
            </div>
          </label>

          <label for="2-i">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('2')?.value === 'I')}">
              <input style="display: none" type="radio" id="2-i" formControlName="2" name="2" value="I" />
              Chiamo la mamma e chiedo se posso dargli qualcosa di sostitutivo.
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>Sei in casa con due bambini che ti sono stati affidati, i due bambini iniziano a
          litigare alzando le mani. Cosa fai?</span>
        <div class="radio-list">
          <label for="3-cc">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('3')?.value === 'C&C')}">
              <input style="display: none" type="radio" id="3-cc" formControlName="3" name="3" value="C&C" />
              Li separo e li faccio giocare separatamente finché non siano in grado di giocare
              senza litigare.
            </div>
          </label>

          <label for="3-h">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('3')?.value === 'H')}">
              <input style="display: none" type="radio" id="3-h" formControlName="3" name="3" value="H" />
              Li lascio litigare, è normale litigare fra bambini. Intervengo solo in casi estremi.
            </div>
          </label>

          <label for="3-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('3')?.value === 'C')}">
              <input style="display: none" type="radio" id="3-c" formControlName="3" name="3" value="C" />
              Li separo, spiego loro perché non va bene azzuffarsi e li coinvolgo in un’attività insieme
              con l’obiettivo di distrarli dal litigio.
            </div>
          </label>

          <label for="3-i">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('3')?.value === 'I')}">
              <input style="display: none" type="radio" id="3-i" formControlName="3" name="3" value="I" />
              Ho paura che si facciano male e cerco in tutti i modi di farli smettere.
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>Senza che tu te ne accorga il bambino ha scarabocchiato la parete con i pennarelli. Cosa fai?</span>
        <div class="radio-list">
          <label for="4-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('4')?.value === 'C')}">
              <input style="display: none" type="radio" id="4-c" formControlName="4" name="4" value="C" />
              Gli spiego perché disegnare sulla parete non va bene e mi faccio aiutare da lui
              per pulirla.
            </div>
          </label>

          <label for="4-i">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('4')?.value === 'I')}">
              <input style="display: none" type="radio" id="4-i" formControlName="4" name="4" value="I" />
              Gli dico che al loro rientro riferiro’ la cosa ai suoi genitori, decideranno loro
              come sgridarlo.
            </div>
          </label>

          <label for="4-cc">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('4')?.value === 'C&C')}">
              <input style="display: none" type="radio" id="4-cc" formControlName="4" name="4" value="C&C" />
              Lo sgrido e lo metto in punizione.
            </div>
          </label>

          <label for="4-h">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('4')?.value === 'H')}">
              <input style="display: none" type="radio" id="4-h" formControlName="4" name="4" value="H" />
              Non dico nulla, è un bel disegno, decideranno i genitori.
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>Il bambino che ti è stato affidato ha qualche linea di febbre. Cosa fai?</span>
        <div class="radio-list">
          <label for="5-i">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('5')?.value === 'I')}">
              <input style="display: none" type="radio" id="5-i" formControlName="5" name="5" value="I" />
              Chiamo uno dei genitori per accordarci sul da farsi.
            </div>
          </label>

          <label for="5-cc">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('5')?.value === 'C&C')}">
              <input style="display: none" type="radio" id="5-cc" formControlName="5" name="5" value="C&C" />
              Gli do la Tachipirina così che la febbre si abbassi, appena tornano i genitori li
              informo della cosa.
            </div>
          </label>

          <label for="5-h">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('5')?.value === 'H')}">
              <input style="display: none" type="radio" id="5-h" formControlName="5" name="5" value="H" />
              Aspetto un po’, magari passa.
            </div>
          </label>

          <label for="5-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('5')?.value === 'C')}">
              <input style="display: none" type="radio" id="5-c" formControlName="5" name="5" value="C" />
              Gli sto vicino, monitoro la febbre. Se si alzerà chiamerò i genitori.
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>È tardi ed il bambino non ne vuole sapere di dormire. Cosa fai?</span>
        <div class="radio-list">
          <label for="6-h">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('6')?.value === 'H')}">
              <input style="display: none" type="radio" id="6-h" formControlName="6" name="6" value="H" />
              Guardiamo insieme un po’ di TV: appena addormentato lo porterò a letto.
            </div>
          </label>

          <label for="6-i">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('6')?.value === 'I')}">
              <input style="display: none" type="radio" id="6-i" formControlName="6" name="6" value="I" />
              Facciamo ancora qualche gioco, quando sarà stanco lo porterò a letto.
            </div>
          </label>

          <label for="6-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('6')?.value === 'C')}">
              <input style="display: none" type="radio" id="6-c" formControlName="6" name="6" value="C" />
              Gli leggo una favola, ascoltandola si addormenterà.
            </div>
          </label>

          <label for="6-cc">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('6')?.value === 'C&C')}">
              <input style="display: none" type="radio" id="6-cc" formControlName="6" name="6" value="C&C" />
              Lo porto a letto, dicendo che è ora di andare a dormire.
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>Sei ai giardini ed il bambino che ti è stato affidato è caduto mentre correva. Cosa fai?</span>
        <div class="radio-list">
          <label for="7-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('7')?.value === 'C')}">
              <input style="display: none" type="radio" id="7-c" formControlName="7" name="7" value="C" />
              Controllo quello che è successo e, se vedo che non è nulla di grave, lo rassicuro e cerco
              di distrarlo con un’altra attività.
            </div>
          </label>

          <label for="7-i">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('7')?.value === 'I')}">
              <input style="display: none" type="radio" id="7-i" formControlName="7" name="7" value="I" />
              Chiamo i genitori per informarli dell’accaduto e chiedere cosa fare.
            </div>
          </label>

          <label for="7-cc">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('7')?.value === 'C&C')}">
              <input style="display: none" type="radio" id="7-cc" formControlName="7" name="7" value="C&C" />
              Torniamo a casa subito, per evitare che succeda di nuovo.
            </div>
          </label>

          <label for="7-h">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('7')?.value === 'H')}">
              <input style="display: none" type="radio" id="7-h" formControlName="7" name="7" value="H" />
              Sicuramente non è nulla di grave, può continuare a giocare.
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>Sei in un negozio con un bambino che ti è stato affidato che, vedendo un giocattolo, inizia a fare i
          capricci per averlo. Cosa fai?</span>
        <div class="radio-list">
          <label for="8-h">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('8')?.value === 'H')}">
              <input style="display: none" type="radio" id="8-h" formControlName="8" name="8" value="H" />
              Glielo compro tanto costa poco.
            </div>
          </label>

          <label for="8-cc">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('8')?.value === 'C&C')}">
              <input style="display: none" type="radio" id="8-cc" formControlName="8" name="8" value="C&C" />
              Dico al bimbo che non siamo nel negozio per comprare giocattoli e continuo quello che
              stavo facendo.
            </div>
          </label>

          <label for="8-i">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('8')?.value === 'I')}">
              <input style="display: none" type="radio" id="8-i" formControlName="8" name="8" value="I" />
              Chiamo i genitori per sapere se vogliono che gli prenda il giocattolo.
            </div>
          </label>

          <label for="8-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('8')?.value === 'C')}">
              <input style="display: none" type="radio" id="8-c" formControlName="8" name="8" value="C" />
              Spiego che siamo nel negozio per fare la spesa e che quando torneremo a casa faremo un bel
              gioco insieme.
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>Sono a casa con un bambino di 5 anni e devo anche sbrigare delle faccende domestiche. Cosa fai?</span>
        <div class="radio-list">
          <label for="9-cc">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('9')?.value === 'C&C')}">
              <input style="display: none" type="radio" id="9-cc" formControlName="9" name="9" value="C&C" />
              Metto il bambino a giocare nella stanza dove faccio le pulizie così da tenerlo d’occhio.
            </div>
          </label>

          <label for="9-h">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('9')?.value === 'H')}">
              <input style="display: none" type="radio" id="9-h" formControlName="9" name="9" value="H" />
              Gli faccio vedere un po’ di TV mentre io lavoro così sono sicura che starà tranquillo.
            </div>
          </label>

          <label for="9-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('9')?.value === 'C')}">
              <input style="display: none" type="radio" id="9-c" formControlName="9" name="9" value="C" />
              Mi faccio aiutare nelle pulizie con dei piccoli lavoretti così da averlo sotto
              controllo e fargli imparare qualcosa di nuovo.
            </div>
          </label>

          <label for="9-i">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('9')?.value === 'I')}">
              <input style="display: none" type="radio" id="9-i" formControlName="9" name="9" value="I" />
              Rimando le faccende domestiche: se lo perdo d’occhio potrebbe farsi male.
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>Sei andata a prendere il bambino di 5 anni a scuola e adesso devi portarlo a basket nella palestra li
          vicino. Cosa fai?</span>
        <div class="radio-list">
          <label for="10-h">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('10')?.value === 'H')}">
              <input style="display: none" type="radio" id="10-h" formControlName="10" name="10" value="H" />
              Lo siedi in macchina, davanti, vicino a te così mentre tu guidi lui fa merenda.
            </div>
          </label>

          <label for="10-cc">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('10')?.value === 'C&C')}">
              <input style="display: none" type="radio" id="10-cc" formControlName="10" name="10" value="C&C" />
              Lo fai salire e lo fai sedere al suo posto sul seggiolino. La merenda la farà dopo.
            </div>
          </label>

          <label for="10-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('10')?.value === 'C')}">
              <input style="display: none" type="radio" id="10-c" formControlName="10" name="10" value="C" />
              Gli dai prima la merenda e poi salite in macchina per andare in palestra.
            </div>
          </label>

          <label for="10-i">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('10')?.value === 'I')}">
              <input style="display: none" type="radio" id="10-i" formControlName="10" name="10" value="I" />
              Chiedo al bimbo cosa vuol fare.
            </div>
          </label>
        </div>

      </div>
      <div>
        <span>Stai per andare a prendere i bambini a scuola ma ti accorgi che ti manca un seggiolino perché è rimasto
          a casa. Cosa fai?</span>
        <div class="radio-list">
          <label for="11-h">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('11')?.value === 'H')}">
              <input style="display: none" type="radio" id="11-h" formControlName="11" name="11" value="H" />
              Non importa, il tragitto è breve ed è già tardi.
            </div>
          </label>

          <label for="11-cc">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('11')?.value === 'C&C')}">
              <input style="display: none" type="radio" id="11-cc" formControlName="11" name="11" value="C&C" />
              Torno indietro a prendere il seggiolino. Mi aspetteranno un momento.
            </div>
          </label>

          <label for="11-i">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('11')?.value === 'I')}">
              <input style="display: none" type="radio" id="11-i" formControlName="11" name="11" value="I" />
              Chiamo i genitori e chiedo se posso portarlo in macchina senza seggiolino.
            </div>
          </label>

          <label for="11-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('11')?.value === 'C')}">
              <input style="display: none" type="radio" id="11-c" formControlName="11" name="11" value="C" />
              Vado a prendere il seggiolino a casa e nel mentre avviso i genitori che sarò 10 minuti in
              ritardo e di avvisare la scuola.
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>Se devi prenderti cura del bambino negli orari di pasto, cosa fai?</span>
        <div class="radio-list">
          <label for="12-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('12')?.value === 'C')}">
              <input style="display: none" type="radio" id="12-c" formControlName="12" name="12" value="C" />
              Chiedo al genitore se il bambino segue una particolare alimentazione, e poi cucino in base
              a quanto mi viene detto coinvolgendo il bambino.
            </div>
          </label>

          <label for="12-cc">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('12')?.value === 'C&C')}">
              <input style="display: none" type="radio" id="12-cc" formControlName="12" name="12" value="C&C" />
              Non chiedo ai genitori, so preparare un piatto che andrà bene per l’età del bambino.
            </div>
          </label>

          <label for="12-i">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('12')?.value === 'I')}">
              <input style="display: none" type="radio" id="12-i" formControlName="12" name="12" value="I" />
              Lo faccio preparare ai genitori, non voglio avere questa responsabilità.
            </div>
          </label>

          <label for="12-h">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('12')?.value === 'H')}">
              <input style="display: none" type="radio" id="12-h" formControlName="12" name="12" value="H" />
              Apro il frigo e faccio scegliere al bambino cosa vuole mangiare.
            </div>
          </label>
        </div>
      </div>
    </div>

    <div class="submit-btn-container">
      <button type="submit" class="button" [disabled]="!form.valid" (click)="submitForm()">Invia</button>
    </div>
    <div class="pink-box"></div>
  </form>
</div>
<app-modal-loader [openModal]="modalLoader" *ngIf="modalLoader"></app-modal-loader>
