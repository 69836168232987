import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function createSpacesValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    let name = control.value as string;
    let startsWithSpace = name.match(/^\s/);
    let endsWithSpace = name.match(/\s$/);

    if (startsWithSpace || endsWithSpace) {
      return { spaces: true }
    } else {
      return null
    }
  }
}
