import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Payment, PossiblePayer } from 'npx-family-happy-common';
import { CaregiverPaymentCreationBody } from 'src/app/shared/models/caregiver-payment-creation-body.model';
import { InvoiceDetailBody } from 'src/app/shared/models/invoice-detail-body.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  private baseURL = environment.paymentsAPI;

  constructor(private http: HttpClient) {
  }

  getPaymentByID(id: number) {
    return this.http.get<Payment>(`${this.baseURL}/${id}`);
  }

  getPossiblePayers(email: string) {
    return this.http.get<PossiblePayer[]>(`${this.baseURL}/possible-payers?email=${email}`)
  }

  updateInvoiceDetail(id: number, body: InvoiceDetailBody, phone: string, provinceCode: string) {
    if (!!phone && !!provinceCode) {
      const params = [
        `phone=${phone}`,
        `provinceCode=${provinceCode}`
      ].join('&');
      return this.http.patch<Payment>(`${this.baseURL}/${id}/billing?${params}`, body);
    } else {
      return this.http.patch<Payment>(`${this.baseURL}/${id}/billing`, body);
    }
  }

  createCaregiverPayment(body: CaregiverPaymentCreationBody) {
    return this.http.post<{ paymentID: number }>(`${this.baseURL}/caregiver-payment`, body);
  }

  updateURLRelated(paymentID: number, urlRelated: string) {
    return this.http.patch<void>(`${this.baseURL}/${paymentID}/url-related?urlRelated=${urlRelated}`, {});
  }
}
