import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Request } from 'npx-family-happy-common';
import { map } from 'rxjs';

export interface BodyRequestApplication {
  name: string,
  surname: string,
  email: string,
  phone: string,
  provincia: string,
  requestURL: string
}

@Injectable({
  providedIn: 'root'
})
export class RequestService {
  baseURL = environment.requestServiceURL + '/v1/requests';

  constructor(private http: HttpClient) { }

  setAttitude(attitude: string, id: string) {
    return this.http.patch(this.baseURL + '/request/update-parent-attitude', attitude, { params: { id: id } });
  }

  getRequest(id: string) {
    return this.http.post<Request[]>(this.baseURL + '/find', { id: id, sortDirection: 'DESC' }).pipe(map((page: any) => page.content));
  }

  getRequestByEmail(email: string) {
    return this.http.post<Request[]>(this.baseURL + '/find', { email: email }).pipe(map((page: any) => page.content));
  }

  sendApplication(body: BodyRequestApplication) {
    return this.http.post(this.baseURL + '/request/risposte-bacheca', body);
  }
}
