import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-loader',
  templateUrl: './modal-loader.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./modal-loader.component.scss'],
  standalone:true,
  imports:[CommonModule]
})
export class ModalLoaderComponent implements OnInit, AfterViewInit {
  @Input() openModal!:boolean
  @ViewChild('content') content!: TemplateRef<any>
  closeResult!: string;

  constructor(private modalService: NgbModal) {
  }
  ngAfterViewInit(): void {
    if(this.openModal === true){
      this.openVerticallyCentered(this.content)
    } else {
      this.modalService.dismissAll()
    }
  }

  ngOnInit(): void {
  }
  openVerticallyCentered(content:any) {
    this.modalService.open(content, {
      backdrop: 'static',
      centered: true,
      windowClass: 'modalClass',
      keyboard: false
    });
  }
}
