import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone:true,
  imports:[CommonModule, RouterOutlet]
})
export class AppComponent {
  title = 'FamilyHappy-Babysitter';

  app = initializeApp(environment.firebaseConfig)
}
