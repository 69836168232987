import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ApplicationConfig, importProvidersFrom, LOCALE_ID } from "@angular/core";
import { GoogleMap } from "@angular/google-maps";
import { ActivatedRoute, provideRouter } from "@angular/router";
import { NgbActiveModal, NgbModule, NgbTypeaheadConfig } from "@ng-bootstrap/ng-bootstrap";
import { NpxFamilyHappyCommonModule } from "npx-family-happy-common";
import { environment } from "src/environments/environment";
import { CoreHttpInterceptor } from "./core/interceptor/http-interceptor.service";
import { routes } from "./app.route";
import { BrowserModule } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GtagModule } from "angular-gtag";
import { AngularFireModule } from "@angular/fire/compat";
import { AngularFirestoreModule } from "@angular/fire/compat/firestore";
import { AngularFireStorageModule } from "@angular/fire/compat/storage";

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    // TitleCasePipe,
    //     AngularEditorService,
    //     DatePipe,
    //     TitleCasePipe,
    //     CaregiverCardDatePipe,
    //     CaregiverCardDistancePipe,
    //     UpperCasePipe,
      // MyDatePipe,
    //     TruncateStringPipe,
        GoogleMap,
        { provide: HTTP_INTERCEPTORS, useClass: CoreHttpInterceptor, multi: true },
        { provide: LOCALE_ID, useValue: 'it' },
        NgbTypeaheadConfig,

    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      FormsModule,
      ReactiveFormsModule,
      AngularFireModule.initializeApp(environment.firebaseConfig),
      AngularFirestoreModule,
      AngularFireStorageModule,
      HttpClientModule,
      NpxFamilyHappyCommonModule,
      NgbModule,
      GtagModule.forRoot({ trackingId: environment.gaTrackingID, trackPageviews: true })
    ),
  ],

};
