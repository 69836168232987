<header>
  <div style="background-color: #3C66A3;">
    <div class="blue-section limit-content">
      <p>
        <a href="mailto:servizioclienti@familyhappybenefit.com">servizioclienti&#64;familyhappybenefit.com</a>
        <a href="tel:+393463233515">+39 3463233515</a>
      </p>

      <a href="https://babysitter.familyhappy.it/login-genitore">Login genitore</a>
    </div>
  </div>

  <div class="blue-section-mobile limit-content">
    <p>Login</p>

    <a href="https://babysitter.familyhappy.it/form-richiesta-preventivo">
      <button class="my-button">Richiedi preventivo gratuito</button>
    </a>
  </div>

  <div id="header">
    <div class="limit-content">
      <img class="logo" src="./assets/img/family-happy-logo.svg">

      <nav>
        <ul id="standard-menu">
          <a href="https://babysitter.familyhappy.it/chi-siamo">
            <li>CHI SIAMO</li>
          </a>
          <a href="https://babysitter.familyhappy.it/come-funziona">
            <li>COME FUNZIONA</li>
          </a>
          <li class="dropdown">
            <button class="dropdown-button">SERVIZI GENITORE <img class="menu-icon"
                src="./assets/icons/down-arrow-icon.svg"></button>

            <div class="dropdown-content">
              <a href="https://babysitter.familyhappy.it/servizi-genitori/metti-regola-babysitter">Metti in regola la
                babysitter</a>
            </div>
          </li>
          <li class="dropdown">
            <a href="https://babysitter.familyhappy.it/welfare" (click)="redirectAfterDropdown($event)"><button
                class="dropdown-button">WELFARE <img class="menu-icon"
                  src="./assets/icons/down-arrow-icon.svg"></button></a>
            <div #dropdown class="dropdown-content">
              <a href="https://babysitter.familyhappy.it/welfare/toolbox">Toolbox</a>
              <a href="https://babysitter.familyhappy.it/welfare/talent-garden">Talent Garden</a>
            </div>
          </li>
          <a href="https://babysitter.familyhappy.it/recensioni">
            <li>RECENSIONI</li>
          </a>
          <a href="https://blog.familyhappy.it/">
            <li>BLOG</li>
          </a>
          <a href="https://babysitter.familyhappy.it/contatti">
            <li>CONTATTI</li>
          </a>
          <a href="https://babysitter.familyhappy.it/form-richiesta-preventivo">
            <li><button class="my-button">Richiedi preventivo gratuito</button></li>
          </a>
        </ul>

        <div id="mobile-menu-icon">
          <label for="hamburger-check">
            <img *ngIf="!showMobileMenu.value" id="open-icon" src="./assets/icons/hamburger-menu-icon.svg">
            <img *ngIf="showMobileMenu.value" id="close-icon" src="./assets/icons/close-menu-icon.svg">
          </label>
          <input type="checkbox" id="hamburger-check" style="display: none" [formControl]="showMobileMenu">
        </div>
      </nav>
    </div>
  </div>

  <div #mobileMenu id="mobile-menu" style="visibility: hidden;"
    [ngClass]="{'open': showMobileMenu.value, 'closed': !showMobileMenu.value}">
    <nav>
      <ul>
        <a href="https://babysitter.familyhappy.it/chi-siamo">
          <li>CHI SIAMO</li>
        </a>
        <a href="https://babysitter.familyhappy.it/come-funziona">
          <li>COME FUNZIONA</li>
        </a>
        <li class="dropdown">
          <button class="dropdown-button" (click)="toggleServicesDropdown()">SERVIZI GENITORE <img class="menu-icon"
              src="./assets/icons/down-arrow-icon.svg"></button>

          <div #parentServices style="max-height: 0; transition: max-height 0.2s ease-out; visibility: hidden;">
            <a href="https://babysitter.familyhappy.it/servizi-genitori/metti-regola-babysitter">Metti in regola la
              babysitter</a>
          </div>
        </li>
        <li class="dropdown">
          <a href="https://babysitter.familyhappy.it/welfare" (click)="redirectAfterDropdownMobile($event)"><button
              class="dropdown-button">WELFARE <img class="menu-icon"
                src="./assets/icons/down-arrow-icon.svg"></button></a>
          <div #dropdownMobile style="max-height: 0; transition: max-height 0.2s ease-out; visibility: hidden;">
            <a href="https://babysitter.familyhappy.it/welfare/toolbox">Toolbox</a>
            <a href="https://babysitter.familyhappy.it/welfare/talent-garden">Talent Garden</a>
          </div>
        </li>
        <a href="https://babysitter.familyhappy.it/recensioni">
          <li>RECENSIONI</li>
        </a>
        <a href="https://blog.familyhappy.it/">
          <li>BLOG</li>
        </a>
        <a href="https://babysitter.familyhappy.it/contatti">
          <li>CONTATTI</li>
        </a>
      </ul>
    </nav>
  </div>
</header>
