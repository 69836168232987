<div style="margin: 50px auto;" class="limit-content">
  <div class="title-container">
    <h1>WEBINAR CORSO SICUREZZA DELL'ANZIANO</h1>

    <p style="margin: 16px auto;max-width: 800px;text-align: center;">Rispondi correttamente almeno all'80% delle
      domande. Superato il test ti invieremo nel corso di 72 ore l'attestato al tuo indirizzo email.</p>
  </div>

  <form [formGroup]="personalInfo">
    <div class="personal-info">
      <div>
        <label for="email">EMAIL</label>
        <input id="email" type="text" formControlName="email" autocomplete="email" required />
        <p style="padding: 0; margin-top: 10px;" id="email-alert"
          *ngIf="personalInfo.value.email === '' || personalInfo.get('email')?.errors">Indica l'indirizzo email di
          iscrizione a Family+Happy</p>
        <p style="padding: 0" id="email-alert" *ngIf="personalInfo.get('email')?.errors">Fornisci un indirizzo email
          valido</p>
      </div>
    </div>
  </form>

  <form [formGroup]="form" class="main-form">
    <div class="blue-box"></div>
    <div class="grid-container">
      <div>
        <span>1. Tra le patologie che caratterizzano la fase dell'invecchiamento non è presente normalmente:</span>
        <div class="radio-list">
          <label for="1-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('1')?.value === 'A')}">
              <input style="display: none" type="radio" id="1-a" formControlName="1" name="1" value="A" />
              Osteoporosi
            </div>
          </label>

          <label for="1-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('1')?.value === 'B')}">
              <input style="display: none" type="radio" id="1-b" formControlName="1" name="1" value="B" />
              Diabete Mellito
            </div>
          </label>

          <label for="1-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('1')?.value === 'C')}">
              <input style="display: none" type="radio" id="1-c" formControlName="1" name="1" value="C" />
              Anoressia
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>2. Nel caso di paziente con Ipertensione Arteriosa la dieta deve essere prevalentemente:</span>
        <div class="radio-list">
          <label for="2-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('2')?.value === 'A')}">
              <input style="display: none" type="radio" id="2-a" formControlName="2" name="2" value="A" />
              Iposodica
            </div>
          </label>

          <label for="2-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('2')?.value === 'B')}">
              <input style="display: none" type="radio" id="2-b" formControlName="2" name="2" value="B" />
              Iperproteica
            </div>
          </label>

          <label for="2-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('2')?.value === 'C')}">
              <input style="display: none" type="radio" id="2-c" formControlName="2" name="2" value="C" />
              Ipocalorica
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>3. Se siamo di fronte ad una persona che non risponde e non respira:</span>
        <div class="radio-list">
          <label for="3-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('3')?.value === 'A')}">
              <input style="display: none" type="radio" id="3-a" formControlName="3" name="3" value="A" />
              Lo mettiamo in posizione laterale di sicurezza
            </div>
          </label>

          <label for="3-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('3')?.value === 'B')}">
              <input style="display: none" type="radio" id="3-b" formControlName="3" name="3" value="B" />
              Attivo 118 ed inizio BLS
            </div>
          </label>

          <label for="3-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('3')?.value === 'C')}">
              <input style="display: none" type="radio" id="3-c" formControlName="3" name="3" value="C" />
              Collego defibrillatore
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>4. La mobilizzazione precoce è fondamentale per prevenire:</span>
        <div class="radio-list">
          <label for="4-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('4')?.value === 'A')}">
              <input style="display: none" type="radio" id="4-a" formControlName="4" name="4" value="A" />
              Sindrome da allettamento
            </div>
          </label>

          <label for="4-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('4')?.value === 'B')}">
              <input style="display: none" type="radio" id="4-b" formControlName="4" name="4" value="B" />
              Dolori ossei
            </div>
          </label>

          <label for="4-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('4')?.value === 'C')}">
              <input style="display: none" type="radio" id="4-c" formControlName="4" name="4" value="C" />
              Obesità
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>5. Nel passaggio del paziente dalla posizione seduta alla carrozzina le gambe dell'operatore devono
          essere:</span>
        <div class="radio-list">
          <label for="5-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('5')?.value === 'A')}">
              <input style="display: none" type="radio" id="5-a" formControlName="5" name="5" value="A" />
              Tese
            </div>
          </label>

          <label for="5-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('5')?.value === 'B')}">
              <input style="display: none" type="radio" id="5-b" formControlName="5" name="5" value="B" />
              Flesse
            </div>
          </label>

          <label for="5-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('5')?.value === 'C')}">
              <input style="display: none" type="radio" id="5-c" formControlName="5" name="5" value="C" />
              A 90°
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>6. La definizione di Anziano Fragile é:</span>
        <div class="radio-list">
          <label for="6-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('6')?.value === 'A')}">
              <input style="display: none" type="radio" id="6-a" formControlName="6" name="6" value="A" />
              Soggetto di età avanzata, affetto da patologie multiple, con uno stato di salute instabile, spesso con
              disabilità
            </div>
          </label>

          <label for="6-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('6')?.value === 'B')}">
              <input style="display: none" type="radio" id="6-b" formControlName="6" name="6" value="B" />
              Soggetto di età avanzata, affetto da patologie multiple, con uno stato di salute stabile, spesso con
              disabilità
            </div>
          </label>

          <label for="6-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('6')?.value === 'C')}">
              <input style="display: none" type="radio" id="6-c" formControlName="6" name="6" value="C" />
              Soggetto di età 60/65anni, affetto da patologie multiple, con uno stato di salute instabile, spesso con
              disabilità
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>7. La Polmonite:</span>
        <div class="radio-list">
          <label for="7-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('7')?.value === 'A')}">
              <input style="display: none" type="radio" id="7-a" formControlName="7" name="7" value="A" />
              È virale
            </div>
          </label>

          <label for="7-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('7')?.value === 'B')}">
              <input style="display: none" type="radio" id="7-b" formControlName="7" name="7" value="B" />
              È batterica
            </div>
          </label>

          <label for="7-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('7')?.value === 'C')}">
              <input style="display: none" type="radio" id="7-c" formControlName="7" name="7" value="C" />
              È tra le più frequenti cause di morte
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>8. Le fratture da osteoporosi possono essere causate da:</span>
        <div class="radio-list">
          <label for="8-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('8')?.value === 'A')}">
              <input style="display: none" type="radio" id="8-a" formControlName="8" name="8" value="A" />
              Traumi maggiori
            </div>
          </label>

          <label for="8-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('8')?.value === 'B')}">
              <input style="display: none" type="radio" id="8-b" formControlName="8" name="8" value="B" />
              Traumi minori, fattori ambientali
            </div>
          </label>

          <label for="8-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('8')?.value === 'C')}">
              <input style="display: none" type="radio" id="8-c" formControlName="8" name="8" value="C" />
              Nessuna delle precedenti
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>9. Nel caso di paziente con Malattie respiratorie croniche:</span>
        <div class="radio-list">
          <label for="9-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('9')?.value === 'A')}">
              <input style="display: none" type="radio" id="9-a" formControlName="9" name="9" value="A" />
              Bisogna mantenere testata del letto almeno 30°
            </div>
          </label>

          <label for="9-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('9')?.value === 'B')}">
              <input style="display: none" type="radio" id="9-b" formControlName="9" name="9" value="B" />
              Bisogna evitare bevande calde
            </div>
          </label>

          <label for="9-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('9')?.value === 'C')}">
              <input style="display: none" type="radio" id="9-c" formControlName="9" name="9" value="C" />
              Entrambe le precedenti sono vere
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>10. Quando chiamo il 112 le informazioni primarie sono:</span>
        <div class="radio-list">
          <label for="10-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('10')?.value === 'A')}">
              <input style="display: none" type="radio" id="10-a" formControlName="10" name="10" value="A" />
              La prima cosa mi presento
            </div>
          </label>

          <label for="10-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('10')?.value === 'B')}">
              <input style="display: none" type="radio" id="10-b" formControlName="10" name="10" value="B" />
              Fornisco info sulla scena
            </div>
          </label>

          <label for="10-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('10')?.value === 'C')}">
              <input style="display: none" type="radio" id="10-c" formControlName="10" name="10" value="C" />
              Fornisco informazioni sulla vittima
            </div>
          </label>
        </div>
      </div>
    </div>

    <div class="submit-btn-container">
      <button type="submit" class="button" [disabled]="!form.valid || !personalInfo.valid"
        (click)="submitForm()">Invia</button>
    </div>
    <div class="pink-box"></div>
  </form>
</div>

<div class="position-absolute bg-white" style="height: 100vh; width: 100vw; z-index: -5; left: 0px;bottom: 0px;">

</div>
<div class="position-absolute" style="z-index: -10; left: 0px;bottom: 0px;">
  <div class="position-relative" id="nurse-certificate">
    <img src="/assets/img/attestato_nurse.jpg" class="w-100" alt="">
    <p id="certificate-name" class="position-absolute text-center w-100 fw-bold" style="top: 56%;font-size: 40px;">Ivan
      Colucci</p>
  </div>
</div>
<app-modal-loader [openModal]="modalLoader" *ngIf="modalLoader"></app-modal-loader>
