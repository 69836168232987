import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-open-chat',
  templateUrl: './open-chat.component.html',
  styleUrls: ['./open-chat.component.scss'],
  standalone:true,
  imports:[CommonModule]
})
export class OpenChatComponent implements OnInit {
  phone!: string;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      const phoneNumber = params.get('phone');
      if (phoneNumber) {
        this.phone = phoneNumber;
        location.href = `https://wa.me/${phoneNumber}`;
      }
    })
  }

}
