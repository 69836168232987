<div class="modal" id="modal-container" (click)="dismiss($event)">
  <div class="modal-content" [ngStyle]="{ 'background-color': exampleType === 'CV' ? 'rgb(230, 230, 230)' : 'white' }">
    <span class="close-modal" (click)="onClose()">x</span>
    <div class="cv-container" *ngIf="exampleType === 'CV'">
      <img src="../../../assets/img/bb-cv-example.png" />
      <img src="../../../assets/img/bb-cv-example-2.png" />
      <img src="../../../assets/img/bb-cv-example-3.png" />
    </div>
    <div class="cv-container" *ngIf="exampleType === 'NurseCV'">
      <img src="../../../assets/img/nurse-cv-example.jpg">
    </div>
    <div class="cv-container" *ngIf="exampleType === 'colfCV'">
      <img src="../../../assets/img/colf-cv-example.jpg">
    </div>

    <div class="photo-container" *ngIf="exampleType === 'Photo'">
      <img src="../../../assets/img/bb-photo-example.jpg">
    </div>
    <div class="photo-container" *ngIf="exampleType === 'PetPhoto'">
      <img src="../../../assets/img/pet-photo-example.png">
    </div>
    <div class="photo-container" *ngIf="exampleType === 'NursePhoto'">
      <img src="../../../assets/img/nurse-photo-example.jpeg">
    </div>
    <div class="photo-container" *ngIf="exampleType === 'ColfPhoto'">
      <img src="../../../assets/img/colf-photo-example.jpg">
    </div>
  </div>
</div>
