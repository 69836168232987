<div style="margin: 50px auto;" class="limit-content">
  <div class="title-container">
    <h1>Questionario per Matching su Misura</h1>

    <p>Questo questionario ha l’obiettivo di capire meglio qual è il tuo stile di genitorialità per aiutarci a trovare
      la
      babysitter più giusta per te.<br> <b>Ha una durata massima di 5 minuti</b></p>
    <p>Non ci sono risposte giuste o sbagliate, ma solo risposte che “vanno bene” per te.<br>
      È importante che tu risponda onestamente perché questo ci permetterà di fare il giusto match: essere in sintonia
      con
      la babysitter è il primo passo per costruire una relazione duratura e di reciproca soddisfazione.
    </p>
  </div>

  <form [formGroup]="form">
    <div class="blue-box"></div>
    <div class="grid-container">
      <div>
        <span>Tuo figlio di 4 anni scoppia a piangere perché vuole la mamma. Cosa fa la babysitter:</span>
        <div class="radio-list">
          <label for="1-h">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('1')?.value === 'H')}">
              <input style="display: none" type="radio" id="1-h" formControlName="1" name="1" value="H" />
              Lo coccola un po’ e poi aspetta che il pianto passi.
            </div>
          </label>

          <label for="1-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('1')?.value === 'C')}">
              <input style="display: none" type="radio" id="1-c" formControlName="1" name="1" value="C" />
              Lo conforta e prova a distrarlo proponendogli un’attività che potrebbe interessarlo.
            </div>
          </label>

          <label for="1-i">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('1')?.value === 'I')}">
              <input style="display: none" type="radio" id="1-i" formControlName="1" name="1" value="I" />
              Telefona a voi genitori per chiedere consiglio su come comportarsi.
            </div>
          </label>

          <label for="1-cc">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('1')?.value === 'C&C')}">
              <input style="display: none" type="radio" id="1-cc" formControlName="1" name="1" value="C&C" />
              Dice al bambino di calmarsi perché la mamma arriverà presto.
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>Il bambino urla e non vuole mangiare la pappa. Cosa fa la babysitter:</span>
        <div class="radio-list">
          <label for="2-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('2')?.value === 'C')}">
              <input style="display: none" type="radio" id="2-c" formControlName="2" name="2" value="C" />
              Trasforma il pranzo in un gioco per distrarlo e farlo mangiare.
            </div>
          </label>

          <label for="2-h">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('2')?.value === 'H')}">
              <input style="display: none" type="radio" id="2-h" formControlName="2" name="2" value="H" />
              Ritarda l’ora del pasto, quando sara’ affamato accetterà la pappa.
            </div>
          </label>

          <label for="2-cc">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('2')?.value === 'C&C')}">
              <input style="display: none" type="radio" id="2-cc" formControlName="2" name="2" value="C&C" />
              Lo fa stare seduto finché non mangia.
            </div>
          </label>

          <label for="2-i">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('2')?.value === 'I')}">
              <input style="display: none" type="radio" id="2-i" formControlName="2" name="2" value="I" />
              Chiama noi genitori per chiedere come comportarsi.
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>I due bambini che sono stati affidati alla babysitter iniziano a
          litigare alzando le mani. Cosa fa la babysitter:</span>
        <div class="radio-list">
          <label for="3-cc">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('3')?.value === 'C&C')}">
              <input style="display: none" type="radio" id="3-cc" formControlName="3" name="3" value="C&C" />
              Li separa e li fa in modo che giochino separatamente finché non sono in grado di giocare
              senza litigare.
            </div>
          </label>

          <label for="3-h">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('3')?.value === 'H')}">
              <input style="display: none" type="radio" id="3-h" formControlName="3" name="3" value="H" />
              Li lascia litigare: è normale che questo avvenga. Interverrà solo in caso di pericolo.
            </div>
          </label>

          <label for="3-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('3')?.value === 'C')}">
              <input style="display: none" type="radio" id="3-c" formControlName="3" name="3" value="C" />
              Li separa e spiega loro perché non va bene azzuffarsi. Successivamente li coinvolge in
              un’attività insieme con l’obiettivo di distrarli dal litigio.
            </div>
          </label>

          <label for="3-i">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('3')?.value === 'I')}">
              <input style="display: none" type="radio" id="3-i" formControlName="3" name="3" value="I" />
              E’ una cosa pericolosa: dovrebbe fare in modo di farli smettere immediatamente.
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>Senza che la babysitter se ne sia accorta, il bambino ha scarabocchiato la parete con i pennarelli. Cosa
          fa la babysitter:</span>
        <div class="radio-list">
          <label for="4-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('4')?.value === 'C')}">
              <input style="display: none" type="radio" id="4-c" formControlName="4" name="4" value="C" />
              Spiega al bambino perché disegnare sulla parete non va bene e si fa aiutare a pulirla.
            </div>
          </label>

          <label for="4-i">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('4')?.value === 'I')}">
              <input style="display: none" type="radio" id="4-i" formControlName="4" name="4" value="I" />
              Dice al bambino che riferirà la cosa ai suoi genitori, decideranno loro come sgridarlo al loro rientro.
            </div>
          </label>

          <label for="4-cc">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('4')?.value === 'C&C')}">
              <input style="display: none" type="radio" id="4-cc" formControlName="4" name="4" value="C&C" />
              Lo sgrida e lo mette in punizione.
            </div>
          </label>

          <label for="4-h">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('4')?.value === 'H')}">
              <input style="display: none" type="radio" id="4-h" formControlName="4" name="4" value="H" />
              E’ un bel disegno, decideranno i genitori al loro rientro cosa dire al bambino.
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>Il bambino ha qualche linea di febbre. Cosa fa la babysitter:</span>
        <div class="radio-list">
          <label for="5-i">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('5')?.value === 'I')}">
              <input style="display: none" type="radio" id="5-i" formControlName="5" name="5" value="I" />
              Chiama uno dei genitori per accordarsi sul da farsi.
            </div>
          </label>

          <label for="5-cc">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('5')?.value === 'C&C')}">
              <input style="display: none" type="radio" id="5-cc" formControlName="5" name="5" value="C&C" />
              Dà al bambino la Tachipirina così che la febbre si abbassi e appena tornano i genitori li informa della
              cosa.
            </div>
          </label>

          <label for="5-h">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('5')?.value === 'H')}">
              <input style="display: none" type="radio" id="5-h" formControlName="5" name="5" value="H" />
              Aspetta un po’, magari passa.
            </div>
          </label>

          <label for="5-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('5')?.value === 'C')}">
              <input style="display: none" type="radio" id="5-c" formControlName="5" name="5" value="C" />
              Gli sta vicino, monitora la febbre e se si alza chiama i genitori.
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>È tardi ed il bambino non ne vuole sapere di dormire. Cosa fa la babysitter:</span>
        <div class="radio-list">
          <label for="6-h">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('6')?.value === 'H')}">
              <input style="display: none" type="radio" id="6-h" formControlName="6" name="6" value="H" />
              Guardano insieme un po’ di TV: appena addormentato lo porterà a letto.
            </div>
          </label>

          <label for="6-i">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('6')?.value === 'I')}">
              <input style="display: none" type="radio" id="6-i" formControlName="6" name="6" value="I" />
              Fanno insieme ancora qualche gioco e quando sarà stanco lo porterà a letto.
            </div>
          </label>

          <label for="6-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('6')?.value === 'C')}">
              <input style="display: none" type="radio" id="6-c" formControlName="6" name="6" value="C" />
              Gli legge una favola così che, ascoltandola, si addormenterà.
            </div>
          </label>

          <label for="6-cc">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('6')?.value === 'C&C')}">
              <input style="display: none" type="radio" id="6-cc" formControlName="6" name="6" value="C&C" />
              Lo porta a letto, dicendo che è ora di andare a dormire.
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>La babysitter è andata ai giardinetti. Il bambino è caduto mentre correva. Cosa fa la babysitter:</span>
        <div class="radio-list">
          <label for="7-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('7')?.value === 'C')}">
              <input style="display: none" type="radio" id="7-c" formControlName="7" name="7" value="C" />
              Controlla quello che è successo e, se vede che non è nulla di grave, lo rassicura e cerca di distrarlo con
              un’altra attività.
            </div>
          </label>

          <label for="7-i">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('7')?.value === 'I')}">
              <input style="display: none" type="radio" id="7-i" formControlName="7" name="7" value="I" />
              Chiama i genitori per informarli dell’accaduto per chiedere cosa fare.
            </div>
          </label>

          <label for="7-cc">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('7')?.value === 'C&C')}">
              <input style="display: none" type="radio" id="7-cc" formControlName="7" name="7" value="C&C" />
              Tornano a casa subito, per evitare che succeda di nuovo.
            </div>
          </label>

          <label for="7-h">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('7')?.value === 'H')}">
              <input style="display: none" type="radio" id="7-h" formControlName="7" name="7" value="H" />
              Sicuramente non è nulla di grave, può continuare a giocare.
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>La babysitter è in un negozio con il bambino che le è stato affidato. Vedendo un giocattolo, inizia a fare
          i capricci per averlo. Cosa fa la babysitter:</span>
        <div class="radio-list">
          <label for="8-h">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('8')?.value === 'H')}">
              <input style="display: none" type="radio" id="8-h" formControlName="8" name="8" value="H" />
              Lo compra… tanto costa poco.
            </div>
          </label>

          <label for="8-cc">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('8')?.value === 'C&C')}">
              <input style="display: none" type="radio" id="8-cc" formControlName="8" name="8" value="C&C" />
              Dice al bimbo che non sono nel negozio per comprare giocattoli e non dà seguito alla richiesta del
              bambino.
            </div>
          </label>

          <label for="8-i">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('8')?.value === 'I')}">
              <input style="display: none" type="radio" id="8-i" formControlName="8" name="8" value="I" />
              Chiama i genitori per sapere se vogliono che prenda il giocattolo al bambino.
            </div>
          </label>

          <label for="8-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('8')?.value === 'C')}">
              <input style="display: none" type="radio" id="8-c" formControlName="8" name="8" value="C" />
              Spiega al bambino che sono nel negozio per fare la spesa e che quando torneranno a casa faranno un bel
              gioco insieme.
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>La babysitter è a casa con un bambino di 5 anni e deve anche sbrigare delle faccende domestiche. Cosa fa
          la babysitter?</span>
        <div class="radio-list">
          <label for="9-cc">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('9')?.value === 'C&C')}">
              <input style="display: none" type="radio" id="9-cc" formControlName="9" name="9" value="C&C" />
              Mette il bambino a giocare nella stanza dove fa le pulizie così da tenerlo d’occhio.
            </div>
          </label>

          <label for="9-h">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('9')?.value === 'H')}">
              <input style="display: none" type="radio" id="9-h" formControlName="9" name="9" value="H" />
              Gli fa vedere un po’ di TV mentre lavora così è sicura che starà tranquillo.
            </div>
          </label>

          <label for="9-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('9')?.value === 'C')}">
              <input style="display: none" type="radio" id="9-c" formControlName="9" name="9" value="C" />
              Si fa aiutare nelle pulizie con dei piccoli lavoretti così da averlo sotto
              controllo e fargli imparare qualcosa di nuovo.
            </div>
          </label>

          <label for="9-i">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('9')?.value === 'I')}">
              <input style="display: none" type="radio" id="9-i" formControlName="9" name="9" value="I" />
              Rimanda le faccende domestiche per evitare di perderlo d’occhio e che si faccia male.
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>La babysitter è andata a prendere il bambino di 5 anni a scuola e adesso deve portarlo a basket nella
          palestra li vicino. Cosa fa la babysitter?</span>
        <div class="radio-list">
          <label for="10-h">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('10')?.value === 'H')}">
              <input style="display: none" type="radio" id="10-h" formControlName="10" name="10" value="H" />
              Lo siede in macchina, davanti, vicino a lei così mentre guida lui fa merenda.
            </div>
          </label>

          <label for="10-cc">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('10')?.value === 'C&C')}">
              <input style="display: none" type="radio" id="10-cc" formControlName="10" name="10" value="C&C" />
              Lo fa salire e lo fa sedere al suo posto sul seggiolino. La merenda la farà dopo.
            </div>
          </label>

          <label for="10-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('10')?.value === 'C')}">
              <input style="display: none" type="radio" id="10-c" formControlName="10" name="10" value="C" />
              Gli da la merenda e poi salgono in macchina per andare in palestra.
            </div>
          </label>

          <label for="10-i">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('10')?.value === 'I')}">
              <input style="display: none" type="radio" id="10-i" formControlName="10" name="10" value="I" />
              Chiede al bimbo cosa vuol fare.
            </div>
          </label>
        </div>

      </div>
      <div>
        <span>La babysitter sta per andare a prendere i bambini a scuola ma si accorgi che manca un seggiolino perché è
          rimasto a casa. Cosa fa la babysitter?</span>
        <div class="radio-list">
          <label for="11-h">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('11')?.value === 'H')}">
              <input style="display: none" type="radio" id="11-h" formControlName="11" name="11" value="H" />
              Non importa, il tragitto è breve ed è già tardi.
            </div>
          </label>

          <label for="11-cc">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('11')?.value === 'C&C')}">
              <input style="display: none" type="radio" id="11-cc" formControlName="11" name="11" value="C&C" />
              Torna indietro a prendere il seggiolino. La aspetteranno un momento.
            </div>
          </label>

          <label for="11-i">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('11')?.value === 'I')}">
              <input style="display: none" type="radio" id="11-i" formControlName="11" name="11" value="I" />
              Chiama i genitori per chiedere se può portarlo in macchina senza seggiolino.
            </div>
          </label>

          <label for="11-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('11')?.value === 'C')}">
              <input style="display: none" type="radio" id="11-c" formControlName="11" name="11" value="C" />
              Va a prendere il seggiolino a casa e nel mentre avvisa i genitori che sarà 10 minuti in ritardo e di
              avvisare la scuola.
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>La babysitter deve prendersi cura del bambino negli orari di pasto. Cosa fa la babysitter?</span>
        <div class="radio-list">
          <label for="12-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('12')?.value === 'C')}">
              <input style="display: none" type="radio" id="12-c" formControlName="12" name="12" value="C" />
              Chiede al genitore se il bambino segue una particolare alimentazione, e poi cucina in base a quanto mi
              viene detto coinvolgendo il bambino.
            </div>
          </label>

          <label for="12-cc">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('12')?.value === 'C&C')}">
              <input style="display: none" type="radio" id="12-cc" formControlName="12" name="12" value="C&C" />
              Sa preparare un piatto che andrà bene per l’età del bambino.
            </div>
          </label>

          <label for="12-i">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('12')?.value === 'I')}">
              <input style="display: none" type="radio" id="12-i" formControlName="12" name="12" value="I" />
              Chiede ai genitori di preparare il pasto che preferiscono dare al loro bambino.
            </div>
          </label>

          <label for="12-h">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('12')?.value === 'H')}">
              <input style="display: none" type="radio" id="12-h" formControlName="12" name="12" value="H" />
              Apre il frigo e fa scegliere al bambino cosa vuole mangiare.
            </div>
          </label>
        </div>
      </div>
    </div>

    <div class="submit-btn-container">
      <button type="submit" class="button" [disabled]="!form.valid" (click)="submitForm()">Invia</button>
    </div>
    <div class="pink-box"></div>
  </form>
</div>