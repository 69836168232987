import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { CustomerType, FHService, Page, RequestCategory } from 'npx-family-happy-common';
import { ServiceOverview } from 'src/app/shared/models/service-overview.modet';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {
  private baseURL = `${environment.baseURL}/v1/services`;

  constructor(private http: HttpClient) { }

  getFHServiceOverview(serviceID: number, provinceCode: string | null) {
    let params = '';
    if (provinceCode) {
      params = `?province=${provinceCode}`;
    }
    return this.http.get<ServiceOverview>(`${this.baseURL}/${serviceID}/summary${params}`);
  }

  getFHServiceOverviewByBusiness(customer: CustomerType, businessLine: string) {
    const params = [
      `customerType=${customer}`,
      `businessLine=${businessLine}`
    ].join('&');

    return this.http.get<ServiceOverview>(`${this.baseURL}/summary?${params}`);
  }
}
