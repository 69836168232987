import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Loader } from '@googlemaps/js-api-loader';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import html2canvas from 'html2canvas';
import { CertificateService, CustomerType, CvNurseTemplateComponent, Mail, MailService, Nurse, RequestUtilitiesService } from 'npx-family-happy-common';
import { of, switchMap } from 'rxjs';
import { ExampleModalComponent } from 'src/app/modals/example-modal/example-modal.component';
import { GoogleMapsService } from 'src/app/services/maps-service/google-maps.service';
import { NurseService } from 'src/app/services/nurse-service/nurse.service';
import { ImageCropperComponent } from 'src/app/shared/components/image-cropper/image-cropper.component';
import { ModalLoaderComponent } from 'src/app/shared/components/modal-loader/modal-loader.component';
import { trimSpaces } from 'src/app/shared/utils/trim-spaces.util';
import { createAddressValidator } from 'src/app/shared/validators/address.directives';
import { createCheckboxesValidator } from 'src/app/shared/validators/checkboxes.directives';
import { createSpacesValidator } from 'src/app/shared/validators/spaces.directives';
import { environment } from 'src/environments/environment';
import { v4 as UUID } from 'uuid';

@Component({
  selector: 'app-nurse-form',
  templateUrl: './nurse-form.component.html',
  styleUrls: ['./nurse-form.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule, ImageCropperComponent, ExampleModalComponent, ModalLoaderComponent, CvNurseTemplateComponent]
})
export class NurseFormComponent implements OnInit, AfterViewInit {
  showModal = false;
  showExample = false;
  showSpinner = false;
  showSpinnerPhoto = false;
  showSpinnerExemple = false;
  modalLoader = false;
  exampleType!: string;
  nurseID!: string;
  nurse!: Nurse;
  finalPhoto!: string;
  hasPayed!: boolean;
  globalDocRef!: string
  private oldAddress!: string | undefined

  generatePages: boolean = false;
  cvPages!: number;
  currentPage: number = 0;
  canvases: HTMLCanvasElement[] = [];

  form = new UntypedFormGroup({
    step1: new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required, createSpacesValidator()]),
      surname: new UntypedFormControl('', [Validators.required, createSpacesValidator()]),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      phone: new UntypedFormControl(null, Validators.required),
      address: new UntypedFormControl('', Validators.required),
      city: new UntypedFormControl(''),
      cap: new UntypedFormControl(null),
      birthDate: new UntypedFormControl(undefined, Validators.required),
      provincia: new UntypedFormControl('TO', Validators.required)
    }),
    step2: new UntypedFormGroup({
      cluster1: new UntypedFormGroup({
        expYears: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(50)]),
        employer: new UntypedFormControl(''),
        tasks: new UntypedFormGroup({
          assistance: new UntypedFormControl(false),
          care: new UntypedFormControl(false),
          drugs: new UntypedFormControl(false),
          cooking: new UntypedFormControl(false),
          housing: new UntypedFormControl(false),
          other: new UntypedFormControl(false)
        }),
        help: new UntypedFormControl('')
      }),
      cluster2: new UntypedFormGroup({
        expYears: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(50)]),
        employer: new UntypedFormControl(''),
        tasks: new UntypedFormGroup({
          assistance: new UntypedFormControl(false),
          care: new UntypedFormControl(false),
          drugs: new UntypedFormControl(false),
          cooking: new UntypedFormControl(false),
          housing: new UntypedFormControl(false),
          other: new UntypedFormControl(false)
        }),
        help: new UntypedFormControl('')
      }),
      license: new UntypedFormControl(false, Validators.required),
      car: new UntypedFormControl(false, Validators.required),
      roles: new UntypedFormGroup({
        walking: new UntypedFormControl(false),
        taxiNoCar: new UntypedFormControl(false),
        taxi: new UntypedFormControl(false),
        cleaning: new UntypedFormControl(false),
        dayShift: new UntypedFormControl(false),
        nightShift: new UntypedFormControl(false),
        changing: new UntypedFormControl(false),
        hospital: new UntypedFormControl(false),
        relocation: new UntypedFormControl(false),
        coliving: new UntypedFormControl(false)
      }, createCheckboxesValidator(1))
    }),
    step3: new UntypedFormGroup({
      parentSurname: new UntypedFormControl(''),
      parentSurname2: new UntypedFormControl('')
    }),
    step4: new UntypedFormGroup({
      certificates: new UntypedFormArray([
        new UntypedFormControl('')
      ]),
      title: new UntypedFormControl('', Validators.required),
      graduationType: new UntypedFormControl(''),
      english: new UntypedFormControl('', Validators.required),
      italian: new UntypedFormControl('', Validators.required),
      spanish: new UntypedFormControl('', Validators.required),
      other1: new UntypedFormControl(''),
      other2: new UntypedFormControl('')
    }),
    step5: new UntypedFormGroup({
      otherExp: new UntypedFormGroup({
        injections: new UntypedFormControl(false),
        dressings: new UntypedFormControl(false),
        glycemia: new UntypedFormControl(false),
        technology: new UntypedFormControl(false)
      }),
      daysAvail: new UntypedFormGroup({
        mon: new UntypedFormControl(false),
        tue: new UntypedFormControl(false),
        wed: new UntypedFormControl(false),
        thu: new UntypedFormControl(false),
        fri: new UntypedFormControl(false),
        sat: new UntypedFormControl(false),
        sun: new UntypedFormControl(false)
      }, createCheckboxesValidator(1)),
      hoursAvail: new UntypedFormGroup({
        mor: new UntypedFormControl(false),
        aft: new UntypedFormControl(false),
        eve: new UntypedFormControl(false)
      }, createCheckboxesValidator(1)),
      description: new UntypedFormControl('', [Validators.required, Validators.minLength(170), Validators.maxLength(340)]),
      photo: new UntypedFormControl('', Validators.required)
    })
  });
  formStep = 1;
  descriptionLength: number = 0;
  chosenPhoto!: string;
  lastUpdateTimestamp!: Date;
  isNew: boolean = true;

  availQuestions = [
    {
      question: "Lei ha una patente in corso di validità?",
      form: "license"
    },
    {
      question: "Lei è automunita?",
      form: "car"
    }
  ];

  constructor(private nurseService: NurseService,
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private googleMapsService: GoogleMapsService,
    private utilities: RequestUtilitiesService,
    private mailService: MailService,
    private certificateService: CertificateService
  ) { }

  ngOnInit(): void {
    this.form.get('step5')?.get('description')?.valueChanges.subscribe(value => {
      this.descriptionLength = value.length;
    });
    /* this.form.valueChanges.subscribe((value) => {
      console.log('Form changed:', value);
    }) */

    this.route.queryParams.subscribe((params) => {
      const docRef = params['id'];
      this.globalDocRef = params['id'];
      if (docRef) {
        this.nurseService.getSingleNurse(docRef).subscribe((nurses) => {
          let nurse = nurses[0];
          this.nurse = nurse;
          this.oldAddress = nurse.info.address
          this.initForm(nurse);
          this.lastUpdateTimestamp = nurse.updateTimestamp;
          this.isNew = false;
        })
      }
    });

    //Conditional form validators

    for (let i = 1; i < 2; i++) {
      this.form.get(`step2.cluster${i}.expYears`)?.valueChanges.subscribe(value => {
        if (value) {
          this.form.get(`step2.cluster${i}.tasks`)?.setValidators(createCheckboxesValidator(1))
        } else {
          this.form.get(`step2.cluster${i}.tasks`)?.clearValidators();
          this.form.get(`step2.cluster${i}.tasks`)?.reset();
        }
      })
    }

    this.form.get('step2.cluster1.tasks.other')?.valueChanges.subscribe(value => {
      if (value) {
        this.form.get('step2.cluster1.help')?.setValidators([Validators.required, Validators.maxLength(40)]);
      } else {
        this.form.get('step2.cluster1.help')?.clearValidators();
        this.form.get('step2.cluster1.help')?.reset();
      }
    })

    this.form.get('step2.cluster2.tasks.other')?.valueChanges.subscribe(value => {
      if (value) {
        this.form.get('step2.cluster2.help')?.setValidators([Validators.required, Validators.maxLength(40)]);
      } else {
        this.form.get('step2.cluster2.help')?.clearValidators();
        this.form.get('step2.cluster2.help')?.reset();
      }
    })

    /* Disable Taxisitter with car when Car field is false */
    this.form.get('step2.roles.taxi')?.disable({ emitEvent: false });
    this.form.get('step2.roles.taxiNoCar')?.disable({ emitEvent: false });

    this.form.get('step2.car')?.valueChanges.subscribe(value => {
      let license = this.form.get('step2.license')?.value;
      console.log('License - car:', license, value);
      if (value === false) {
        this.form.get('step2.roles.taxi')?.disable({ emitEvent: false });
        this.form.get('step2.roles.taxi')?.setValue(false);
      } else {
        if (license === true) {
          this.form.get('step2.roles.taxi')?.enable({ emitEvent: false });
        }
      }
    });

    /* Disable Taxisitter fields (except walking) when License field is false */

    this.form.get('step2.license')?.valueChanges.subscribe(value => {
      let car = this.form.get('step2.car')?.value;
      console.log('License - car:', value, car);
      if (value === false) {
        this.form.get('step2.roles.taxiNoCar')?.disable({ emitEvent: false });
        this.form.get('step2.roles.taxiNoCar')?.setValue(false);
        this.form.get('step2.roles.taxi')?.disable({ emitEvent: false });
        this.form.get('step2.roles.taxi')?.setValue(false);
      } else {
        if (car === true) {
          this.form.get('step2.roles.taxi')?.enable({ emitEvent: false });
        }
        this.form.get('step2.roles.taxiNoCar')?.enable({ emitEvent: false });
      }
    })
  }

  ngAfterViewInit(): void {
    /* Google Maps */
    let inputAddress = document.getElementById('address') as HTMLInputElement;
    const loader = new Loader({ apiKey: environment.googleMapsAPIKey, version: "weekly", libraries: ["places"], language: 'it-IT' }).load().then((google) => {
      /* const mapOptions = {
        fields: ["formatted_address", "geometry", "name"],
        strictBounds: false,
        types: ["establishment"],
      }; */

      const autocomplete = new google.maps.places.Autocomplete(inputAddress);

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        let addressParts: Array<string> = [];
        let formAddress = this.form.get('step1.address');
        let formCity = this.form.get('step1.city');
        let formCap = this.form.get('step1.cap');

        place.address_components?.forEach(component => {
          component.types.forEach(type => {
            addressParts.push(type);
          });

          // Compile other address fields according to place object
          if (component.types.includes('locality')) {
            formCity?.setValue(component.long_name);
          } else if (component.types.includes('administrative_area_level_3')) {
            formCity?.setValue(component.long_name);
          }
          if (component.types.includes('postal_code')) {
            formCap?.setValue(component.long_name);
          }
        });

        formAddress?.setValidators([Validators.required, createAddressValidator(addressParts)]);

        formAddress?.setValue(place.formatted_address);
      })
    });
  }

  changeStep(next: boolean) {
    if (next === true) {
      this.formStep++
      this.submitFormStep();
    } else {
      this.formStep--
    }
  }

  async submitFormStep() {
    this.nurse = await this.processFormData();
    this.nurseService.updateNurseFromForm(this.nurse).subscribe((res: any) => {
      this.nurseID = res.message.match(/[\w-]+$/)[0];
      this.globalDocRef = this.nurseID;
    });
  }

  touchButton() {
    this.form.get('step5')?.get('photo')?.markAsTouched({ onlySelf: true });
    this.showSpinnerPhoto = true;
  }

  openModal(event: any) {
    this.showSpinnerPhoto = true;
    let image = event.target.files[0];
    this.chosenPhoto = URL.createObjectURL(image);
    this.showModal = true;
  }

  openExamplePhoto() {
    this.exampleType = 'NursePhoto';
    this.showExample = true;
    this.showSpinnerExemple = true;
  }

  closeExampleModal() {
    this.showExample = false;
    this.showSpinner = false;
    this.showSpinnerExemple = false;
  }

  getAge(date: string) {
    let today = new Date();
    let birthday = new Date(date);
    let age = today.getFullYear() - birthday.getFullYear();
    let month = today.getMonth() - birthday.getMonth();

    if (month < 0 || (month === 0 && today.getDate() < birthday.getDate())) {
      age--;
    }

    return age;
  }

  initForm(nurse: Nurse) {
    this.form.setValue({
      step1: {
        name: trimSpaces(nurse.info.name),
        surname: trimSpaces(nurse.info.surname),
        email: nurse.info.email,
        phone: nurse.info.phone,
        address: nurse.info.address,
        city: nurse.info.city,
        cap: nurse.info.cap,
        birthDate: nurse.info.birthDate,
        provincia: nurse.info.provincia
      },
      step2: {
        cluster1: {
          expYears: nurse.exp.cluster1.cluster1Age,
          employer: nurse.exp.cluster1.employer1,
          tasks: this.nurseService.fromStringToAvailObject(nurse.exp.cluster1.tasks1, 'tasks1'),
          help: nurse.exp.cluster1.help1 ?? ''
        },
        cluster2: {
          expYears: nurse.exp.cluster2.cluster2Age,
          employer: nurse.exp.cluster2.employer2,
          tasks: this.nurseService.fromStringToAvailObject(nurse.exp.cluster2.tasks2, 'tasks2'),
          help: nurse.exp.cluster2.help2 ?? ''
        },
        roles: {
          walking: nurse.avail.walking,
          taxiNoCar: nurse.avail.taxiNoCar,
          taxi: nurse.avail.taxi,
          cleaning: nurse.avail.cleaning,
          dayShift: nurse.avail.dayShift,
          nightShift: nurse.avail.nightShift,
          changing: nurse.avail.changing,
          hospital: nurse.avail.hospital,
          relocation: nurse.avail.relocation,
          coliving: nurse.avail.coliving
        },
        license: nurse.avail.license,
        car: nurse.avail.car
      },
      step3: {
        parentSurname: nurse.reference ? nurse.reference.f_surname : '',
        parentSurname2: nurse.reference ? nurse.reference.f_second_surname : ''
      },
      step4: {
        certificates: [''],
        title: nurse.lang.title,
        graduationType: nurse.lang.graduationType,
        english: nurse.lang.english,
        italian: nurse.lang.italian,
        spanish: nurse.lang.spanish,
        other1: nurse.lang.other1,
        other2: nurse.lang.other2
      },
      step5: {
        otherExp: {
          dressings: nurse.lang.specialities.dressings,
          glycemia: nurse.lang.specialities.glycemia,
          injections: nurse.lang.specialities.injections,
          technology: nurse.lang.specialities.technology,
        },
        daysAvail: this.nurseService.fromStringToAvailObject(nurse.avail.days, 'days'),
        hoursAvail: this.nurseService.fromStringToAvailObject(nurse.avail.hours, 'hours'),
        description: nurse.info.description,
        photo: nurse.info.photo
      }
    }, { emitEvent: false })
    this.setCertificatesLenght(nurse.certificates.nurseCertificateList);
  }

  async processFormData(updateTimestamp?: Date) {
    /* this.zapierData = {
      ...this.form.get('step1')?.value,
      ...this.form.get('step2')?.value,
      ...this.form.get('step2')?.get('roles')?.value,
      ...this.form.get('step3')?.value,
      ...this.form.get('step4')?.value,
      ...this.form.get('step4')?.value,
      ...this.form.get('step4')?.get('daysAvail')?.value,
      ...this.form.get('step4')?.get('hoursAvail')?.value,
      ...this.form.get('step4')?.get('taskType')?.value
    };

    delete this.zapierData.daysAvail;
    delete this.zapierData.hoursAvail;
    delete this.zapierData.taskType;
    delete this.zapierData.roles; */

    /* this.nurseService.checkBabysitterCertification(this.form.get('step1.email')?.value).subscribe((res: boolean) => this.hasPayed = res); */

    let cluster1Tasks: string = '';
    Object.entries(this.form.get("step2.cluster1.tasks")?.value).forEach(([key, value]) => {
      if (value) {
        cluster1Tasks += `${key} `;
      } else {
        return;
      }
    });

    let cluster2Tasks: string = '';
    Object.entries(this.form.get("step2.cluster2.tasks")?.value).forEach(([key, value]) => {
      if (value) {
        cluster2Tasks += `${key} `;
      } else {
        return;
      }
    });

    let clustersExp = [];
    clustersExp.push(this.form.get('step2.cluster1.expYears')?.value);
    clustersExp.push(this.form.get('step2.cluster2.expYears')?.value);
    let age = this.getAge(this.form.get('step1.birthDate')!.value);
    let expSum = clustersExp[0] + clustersExp[1];
    let totalExp = Math.max(...clustersExp, Math.min(expSum, (age - 16)));

    let nurse: Nurse = {
      // docRef: this.nurse ? this.nurse.docRef : this.globalDocRef,
      docRef: this.globalDocRef,
      certificate: '',
      info: {
        name: this.form.get("step1")?.get("name")?.value,
        surname: this.form.get("step1")?.get("surname")?.value,
        email: this.form.get("step1")?.get("email")?.value,
        phone: this.form.get("step1")?.get("phone")?.value,
        address: this.form.get("step1.address")?.value,
        city: this.form.get("step1.city")?.value,
        zone: '',
        cap: this.form.get("step1.cap")?.value,
        age,
        birthDate: this.form.get("step1")?.get("birthDate")?.value,
        provincia: this.form.get("step1")?.get("provincia")?.value,
        fiscalcode: '',
        resume: '',
        resumeHidden: '',
        video: '',
        notes: '',
        iban: '',
        description: this.form.get("step5")?.get("description")?.value,
        photo: this.form.get("step5")?.get("photo")?.value,
        subscription: this.hasPayed ? 'ABBONATO' : '',
        manualSubscription: this.nurse ? this.nurse.info.manualSubscription : '',
        expDate: this.nurse ? this.nurse.info.expDate : null,
        expDateManual: this.nurse ? this.nurse.info.expDateManual : null,
        stopWhatsapp: this.nurse ? this.nurse.info.stopWhatsapp : false,
        latitude: this.nurse ? this.nurse.info.latitude : 0,
        longitude: this.nurse ? this.nurse.info.longitude : 0,
      },
      exp: {
        total: totalExp,
        cluster1: {
          cluster1Age: this.form.get("step2.cluster1.expYears")?.value,
          employer1: this.form.get("step2.cluster1.employer")?.value,
          tasks1: this.nurseService.fromAvailObjectToString(this.form.get("step2.cluster1.tasks")?.value, 'tasks1'),
          help1: this.form.get("step2.cluster1.help")?.value ?? null
        },
        cluster2: {
          cluster2Age: this.form.get("step2.cluster2.expYears")?.value,
          employer2: this.form.get("step2.cluster2.employer")?.value,
          tasks2: this.nurseService.fromAvailObjectToString(this.form.get("step2.cluster2.tasks")?.value, 'tasks2'),
          help2: this.form.get("step2.cluster2.help")?.value ?? null
        },
      },
      reference: {
        f_surname: this.form.get("step3")?.get("parentSurname")?.value ?? '',
        f_second_surname: this.form.get("step3.parentSurname2")?.value ?? ''
      },
      lang: {
        title: this.form.get("step4")?.get("title")?.value,
        graduationType: this.form.get("step4.graduationType")?.value,
        specialities: {
          injections: this.form.get("step5.otherExp")?.value.injections,
          dressings: this.form.get("step5.otherExp")?.value.dressings,
          glycemia: this.form.get("step5.otherExp")?.value.glycemia,
          technology: this.form.get("step5.otherExp")?.value.technology,
        },
        english: this.form.get("step4")?.get("english")?.value,
        italian: this.form.get("step4")?.get("italian")?.value,
        french: this.nurse ? this.nurse.lang.french : 'Non lo parlo',
        german: this.nurse ? this.nurse.lang.german : 'Non lo parlo',
        spanish: this.form.get("step4.spanish")?.value,
        other1: this.form.get("step4.other1")?.value,
        other2: this.form.get("step4.other2")?.value
      },
      avail: {
        changing: this.form.get("step2.roles.changing")?.value,
        hospital: this.form.get("step2.roles.hospital")?.value,
        taxi: this.form.get("step2.roles.taxi")?.value,
        taxiNoCar: this.form.get("step2.roles.taxiNoCar")?.value,
        walking: this.form.get("step2.roles.walking")?.value,
        cleaning: this.form.get("step2.roles.cleaning")?.value,
        dayShift: this.form.get("step2.roles.dayShift")?.value,
        nightShift: this.form.get("step2.roles.nightShift")?.value,
        license: this.form.get("step2.license")?.value,
        coliving: this.form.get("step2.roles.coliving")?.value,
        relocation: this.form.get("step2.roles.relocation")?.value,
        car: this.form.get("step2.car")?.value,
        smoker: this.nurse ? this.nurse.avail.smoker : false,
        days: this.nurseService.fromAvailObjectToString(this.form.get("step5")?.get("daysAvail")?.value, 'days'),
        hours: this.nurseService.fromAvailObjectToString(this.form.get("step5")?.get("hoursAvail")?.value, 'hours'),
        contract: this.nurse ? this.nurse.avail.contract : '',
      },
      rating: {
        overall: this.nurse ? this.nurse.rating.overall : 0,
        punctuality: this.nurse ? this.nurse.rating.punctuality : 0,
        empathy: this.nurse ? this.nurse.rating.empathy : 0,
        behave: this.nurse ? this.nurse.rating.behave : 0,
        communication: this.nurse ? this.nurse.rating.communication : 0,
        dangerous: this.nurse ? this.nurse.rating.dangerous : false,
        attitude: this.nurse ? this.nurse.rating.attitude : '',
        notRated: this.nurse ? this.nurse.rating.notRated : false,
      },
      engagement: {
        selected: this.nurse ? this.nurse.engagement.selected : 0,
        chosen: this.nurse ? this.nurse.engagement.chosen : 0,
        discarded: this.nurse ? this.nurse.engagement.discarded : 0,
        ignored: this.nurse ? this.nurse.engagement.ignored : 0,
        substituted: this.nurse ? this.nurse.engagement.substituted : 0,
        applications: this.nurse ? this.nurse.engagement.applications : 0,
        chosenDate: this.nurse ? this.nurse.engagement.chosenDate : null,
        latestApplication: this.nurse ? this.nurse.engagement.latestApplication : null
      },
      certificates: {
        nurseCertificateList: this.form.get('step4.certificates')?.value[0] ? this.form.get('step4.certificates')?.value : []
      },
      updateTimestamp: updateTimestamp ?? this.lastUpdateTimestamp
    };

    //calculate coordinates from address
    if (this.oldAddress !== nurse.info.address) {
      let coordinates = await this.getCoordiantes(nurse)
      nurse = {
        ...nurse,
        info: {
          ...nurse.info,
          latitude: coordinates.latitude,
          longitude: coordinates.longitude
        }
      }

    } else {
      nurse = {
        ...nurse,
        info: {
          ...nurse.info,
          latitude: nurse.info.latitude,
          longitude: nurse.info.longitude
        }
      }

      this.nurse = nurse;
    }
    return nurse;
  }

  async submitForm() {
    this.modalLoader = true
    this.nurse = await this.processFormData(new Date());
    this.nurseService.createNurse(this.nurse).subscribe((res) => {
      console.log('Submit completion: ', res);
      /* if (environment.production) {
        console.log('Form data sent to Zapier');
      } */
      if (this.hasPayed || this.nurse.info.manualSubscription === 'ABBONATO') {
        this.generateCV();
      } else {
        this.router.navigate(['thank-you/' + this.nurse.info.email], { queryParams: { fromCV: true, name: this.nurse.info.name, category: 'NURSE' } });
        this.modalLoader = false
        this.modalService.dismissAll()
        /* if(this.nurse.rating.attitude === '') {
          this.router.navigate(['/test-psico-attitudinale'], { queryParams: { id: this.nurseID } });
        } else {
          this.router.navigate(['thank-you-nurse/' + this.nurse.info.email], {queryParams: {fromCV: true, name: this.nurse.info.name}});
        } */
      }
      this.modalLoader = false
      // this.modalService.dismissAll()
    });
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  getCroppedPhoto(image: string) {
    this.finalPhoto = image;
  }

  cropperAbort() {
    let imageInput = <HTMLInputElement>document.getElementById("image-selection");
    imageInput.value = "";
    this.showModal = false;
    this.showSpinnerPhoto = false;
  }
  abortSelection(event: any) {
    this.showSpinnerPhoto = false;
  }

  base64ToBlob(base64Data: string, contentType: string) {
    contentType = contentType || '';
    let sliceSize = 1024;
    let byteCharacters = atob(base64Data);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);

      let bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  uploadImage(image: string) {
    const file = this.base64ToBlob(image.replace('data:image/png;base64,', ''), "image/png");
    const path = 'pictures/' + UUID() + '.png';
    let storageRef = ref(getStorage(), path);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on('state_changed', () => { }, () => { }, () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL: any) => {
        console.log('File available at', downloadURL);
        this.form.get("step5")?.get("photo")?.setValue(downloadURL);
        alert('Foto caricata con successo!');
        this.showSpinnerPhoto = false;
      });
    })
    this.showModal = false;
  }

  //GENERATE CV
  async generateCV() {
    // this.modalLoader = true
    this.generatePages = true;
    setTimeout(() => { this.htmlToCanvas() }, 1000);
  }

  htmlToCanvas() {
    if (this.currentPage >= this.cvPages) {
      this.currentPage = 0;
      this.generatePages = false;
      this.nurseService.getSingleNurse(this.nurseID).subscribe(babysitters => {
        let babysitter = babysitters[0];
        let babysitterEmail = environment.production ? babysitter.info.email : 'drugle91xbox@gmail.com'; // Cambiamento temporaneo indirizzo email di test
        let senderEmail = this.utilities.getMailService(CustomerType.B2C, environment.production);
        let message = `<p>Car* ${babysitter.info.name},<br>
        hai completato con successo il tuo CV che trovi in allegato e che puoi scaricare.<br>
        Ti consigliamo di tenerlo sempre aggiornato per aver più successo nella ricerca del lavoro.<br>
        Puoi sempre aggiornarlo o modificarlo cliccando <a href="${environment.formBaseURL}/nurse-form?id=${babysitter.docRef}">QUI</a>.</p>
        <p>Ti ricordiamo che riceverai aggiornamenti ad ogni tua candidatura.</p>
        <p>A presto e in bocca al lupo,<br>
        Il Team Di Family+Happy</p>`;
        let cv = {
          path: babysitter.info.resumeHidden,
          filename: `${babysitter.info.name} ${babysitter.info.surname} - Family+Happy CV.pdf`
        };

        let mail = new Mail(senderEmail, babysitterEmail, 'Il tuo CV Family+Happy è pronto', message, [cv]);

        this.mailService.sendCustom(environment.mailServiceURL, mail).subscribe({
          next: () => {
            console.log('Email sent.');
            this.modalLoader = false
            this.modalService.dismissAll()
            this.router.navigate(['thank-you/' + this.nurse.info.email], { queryParams: { fromCV: true, name: this.nurse.info.name, category: 'NURSE' } });
            // if (this.nurse.rating.attitude === '') {
            //   this.router.navigate(['/test-psico-attitudinale'], { queryParams: { id: this.nurseID } });
            // } else {
            //   this.router.navigate(['thank-you/' + this.nurse.info.email], { queryParams: { fromCV: true, name: this.nurse.info.name } });
            // }
          },
          error: () => {
            console.error('Mail service error');
            // if (this.nurse.rating.attitude === '') {
            //   this.router.navigate(['/test-psico-attitudinale'], { queryParams: { id: this.nurseID } });
            // } else {
            //   this.router.navigate(['thank-you/' + this.nurse.info.email], { queryParams: { fromCV: true, name: this.nurse.info.name } });
            // }
            this.modalLoader = false
            this.modalService.dismissAll()
            this.router.navigate(['thank-you/' + this.nurse.info.email], { queryParams: { fromCV: true, name: this.nurse.info.name, category: 'NURSE' } });
          }
        })
      });
      return;
    }

    const element = document.getElementById('cv-page-container') as HTMLElement;
    html2canvas(element, { allowTaint: true, useCORS: true }).then(async canvas => {
      this.canvases.push(canvas);
      if (this.currentPage === this.cvPages - 1) {
        await this.certificateService.createNursePDFCVFromHTML(this.canvases, this.nurse, this.isNew, false,
          null, environment.nurseServiceURL, environment.requestServiceURL);
        this.canvases = [];
      }
      this.currentPage++;
      setTimeout(() => { this.htmlToCanvas() }, 1000);
    })
    // this.modalLoader = false;
    // this.modalService.dismissAll()
  }

  /* Certificates form arrays methods */

  changeCertificatesLength(more: boolean) {
    let array: UntypedFormArray = this.form.get('step4.certificates') as UntypedFormArray;

    if (more) {
      array.push(new UntypedFormControl(''));
    } else {
      array.removeAt(array.length - 1);
    }
  }

  setCertificatesLenght(data?: string[]) {
    let array: UntypedFormArray = this.form.get('step4.certificates') as UntypedFormArray;

    if (data && data.length > 0) {
      array.clear();
      data!.forEach(data => array.push(new UntypedFormControl(data)));
    }
  }

  resetCertificates() {
    let array: UntypedFormArray = this.form.get('step4.certificates') as UntypedFormArray;

    array.clear();
  }

  getCertificatesFormControls() {
    let array: UntypedFormArray = this.form.get('step4.certificates') as UntypedFormArray;

    return array.controls;
  }

  openExampleCV() {
    this.showSpinner = true;
    this.exampleType = 'NurseCV';
    this.showExample = true;
  }


  //SCRIPT --- CONVERT ADDRESSES INTO COORDINATES
  private async getCoordiantes(bb: Nurse) {
    let res = await this.googleMapsService.getCoordinates(bb.info.address).then((res) => {
      const lat = res[0].geometry.location.lat();
      const lng = res[0].geometry.location.lng();
      const coordinates = {
        latitude: lat,
        longitude: lng
      }
      return coordinates
    })
    return res
  }
}
