import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UnicreditDecodedToken, UnicreditFormBody } from 'src/app/shared/models/unicredit-form.model';
import { environment } from 'src/environments/environment';
import * as jwt_decode from 'jwt-decode';


@Injectable({
  providedIn: 'root'
})
export class UnicreditService {

  baseURL = environment.unicreditURL;
  tokenURL = environment.unicreditToken

  constructor(private http: HttpClient) { }

  getDecodedToken(token: string) {
    let body = {
      token: token
    }
    return this.http.post<any>(this.tokenURL, body)
  }

  checkTokenExpiration(decodedToken: UnicreditDecodedToken): string {
    console.log('Decoded token:', decodedToken);
    if (decodedToken) {
      if (decodedToken.aud !== 'unicredit.app.familyhappybenefit.com') {
        return `Il campo aud fornito non è corretto`;
      }
      if (decodedToken.iss !== 'Unicredit') {
        return `Il campo iss fornito non è corretto`;
      }
      if (decodedToken.sub !== 'Partner CTA') {
        return `Il campo sub fornito non è corretto`;
      }
      if (decodedToken.serviceID !== 'FamilyHappy') {
        return `Il campo serviceID fornito non è corretto`;
      }
      if (decodedToken.exp) {
        const currentTime = Date.now(); // Ottieni il timestamp corrente in secondi

        if (decodedToken.exp > currentTime) {
          return '';
        } else {
          return 'Token expired';
        }
      } else {
        return 'Il token non contiene il campo "exp" e non è possibile verificarne la validità.';
      }
    } else {
      return 'Errore durante la decodifica del token.';
    }
  }

  sendForm(data: UnicreditFormBody) {
    return this.http.post<UnicreditFormBody>(this.baseURL, data)
  }
}
