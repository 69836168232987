<div class="header"></div>

<div style="margin: 50px auto;" class="limit-content">
  <div *ngIf="formStep === 1" style="margin-bottom: 50px">
    <p style="font-size: 14pt;margin-bottom: 10px">
      <b>Il seguente form è fondamentale per trovare lavoro.</b><br>
      L’80% dei cv che in passato abbiamo ricevuto dalle babysitter non era presentabile ai genitori.<br>
      Compilando questo form Family+Happy genererà per te un cv professionale che verrà condiviso con il genitore e avrà
      più probabilità di essere selezionato.<br>
      <b>La compilazione dura solo 5 minuti del tuo tempo.</b>
    </p>
    <div style="display: flex">
    <button type="button" class="button d-inline" (click)="openExampleCV()">
      <span class="spinner-border text-light" style="width: 16px;height: 16px;" *ngIf="showSpinner"></span>
      Vedi un esempio del
      risultato</button>
    </div>
  </div>

  <p class="back-btn" *ngIf="formStep !== 1" (click)="changeStep(false)">&larr; Indietro</p>
  <h2 *ngIf="formStep === 2">Esperienza & disponibilità</h2>
  <h2 *ngIf="formStep === 3">Referenze</h2>
  <h2 *ngIf="formStep === 4">Lingue parlate</h2>
  <h2 *ngIf="formStep === 5">Incarichi aggiuntivi</h2>

  <form [formGroup]="form">
    <div class="grid-container" formGroupName="step1" *ngIf="formStep === 1">
      <div>
        <label class="label" for="name"><span class="required">*</span>Nome</label>
        <input class="form-input"
          [ngClass]="{'red-border': (form.get('step1.name')?.errors && form.get('step1.name')?.touched)}" type="text"
          id="name" formControlName="name" required>
        <p class="form-error" *ngIf="form.get('step1.name')?.errors?.['required'] && form.get('step1.name')?.touched">Il
          campo è
          obbligatorio.</p>
        <p class="form-error" *ngIf="form.get('step1.name')?.errors?.['spaces'] && form.get('step1.name')?.touched">Il
          nome non può iniziare o finire con uno spazio.</p>
      </div>
      <div>
        <label class="label" for="surname"><span class="required">*</span>Cognome</label>
        <input class="form-input"
          [ngClass]="{'red-border': (form.get('step1.surname')?.errors && form.get('step1.surname')?.touched)}"
          type="text" id="surname" formControlName="surname" required>
        <p class="form-error"
          *ngIf="form.get('step1.surname')?.errors?.['required'] && form.get('step1.surname')?.touched">Il campo è
          obbligatorio.</p>
        <p class="form-error"
          *ngIf="form.get('step1.surname')?.errors?.['spaces'] && form.get('step1.surname')?.touched">Il cognome non può
          iniziare o finire con uno spazio.</p>
      </div>
      <div>
        <label class="label" for="email"><span class="required">*</span>Email</label>
        <input class="form-input"
          [ngClass]="{'red-border': (form.get('step1.email')?.errors && form.get('step1.email')?.touched)}" type="text"
          id="email" formControlName="email" required>
        <p class="form-error" *ngIf="form.get('step1.email')?.getError('required') && form.get('step1.email')?.touched">
          Il campo è obbligatorio.</p>
        <p class="form-error" *ngIf="form.get('step1.email')?.getError('email') && form.get('step1.email')?.touched">
          Inserisci un indirizzo email valido o controlla che non ci siano spazi all'inizio o alla fine.</p>
      </div>
      <div>
        <label class="label" for="phone"><span class="required">*</span>Cellulare</label>
        <input class="form-input"
          [ngClass]="{'red-border': (form.get('step1.phone')?.errors && form.get('step1.phone')?.touched)}" type="text"
          id="phone" formControlName="phone" required>
        <p class="form-error" *ngIf="form.get('step1.phone')?.errors && form.get('step1.phone')?.touched">Il campo è
          obbligatorio.</p>
      </div>
      <div>
        <label class="label" for="address"><span class="required">*</span>Indirizzo</label>
        <input class="form-input"
          [ngClass]="{'red-border': (form.get('step1.address')?.errors && form.get('step1.address')?.touched)}"
          type="text" id="address" formControlName="address" required>
        <p class="form-error"
          *ngIf="form.get('step1.address')?.errors && form.get('step1.address')?.hasError('required') && form.get('step1.address')?.touched">
          Il campo è obbligatorio.
        </p>
        <p class="form-error"
          *ngIf="form.get('step1.address')?.errors && form.get('step1.address')?.hasError('address') && form.get('step1.address')?.touched">
          Assicurati che l'indirizzo contenga tutti i seguenti elementi: Numero civico, CAP, Città.
        </p>
      </div>
      <!-- <div>
        <label class="label" for="age">Quanti anni hai?<div class="required"><span>*</span></div></label>
        <input class="form-input"
          [ngClass]="{'red-border': (form.get('step1.age')?.errors && form.get('step1.age')?.touched)}" type="number"
          id="age" formControlName="age" min="18" max="99" required>
        <p class="form-error" *ngIf="form.get('step1.age')?.errors && form.get('step1.age')?.touched">Il numero inserito
          non è valido.</p>
      </div> -->
      <div>
        <label class="label" for="birthDate"><span class="required">*</span>Data di nascita</label>
        <input class="form-input"
          [ngClass]="{'red-border': (form.get('step1.birthDate')?.errors && form.get('step1.birthDate')?.touched)}"
          type="date" id="birthDate" formControlName="birthDate" required>
      </div>
      <div>
        <label class="label" for="provincia"><span class="required">*</span>Provincia dove vuoi lavorare</label>
        <select
          [ngClass]="{'red-border': (form.get('step1.provincia')?.errors && form.get('step1.provincia')?.touched)}"
          class="form-select" id="provincia" formControlName="provincia" required>
          <option value="TO">Torino</option>
          <option value="MI">Milano</option>
          <option value="RM">Roma</option>
          <option value="BO">Bologna</option>
          <option value="PD">Padova</option>
          <option value="GE">Genova</option>
          <option value="FI">Firenze</option>
          <option value="BS">Brescia</option>
          <option value="BG">Bergamo</option>
          <option value="MB">Monza</option>
          <option value="VR">Altre province</option>
        </select>
        <p class="form-error" *ngIf="form.get('step1.provincia')?.errors && form.get('step1.provincia')?.touched">Il
          campo è obbligatorio.</p>
      </div>
    </div>

    <div class="grid-container" formGroupName="step2" *ngIf="formStep === 2">
      <div formGroupName="cluster1">
        <label class="label" for="years1"><span class="required">*</span><b>Quanti ANNI di esperienza hai con bambini da
            0 a 6 mesi di età?</b>
        </label>
        <input class="form-input"
          [ngClass]="{'red-border': (form.get('step2.cluster1.expYears')?.errors && form.get('step2.cluster1.expYears')?.touched)}"
          type="number" id="years1" formControlName="expYears" min="0" max="50" required>
        <p class="form-error"
          *ngIf="form.get('step2.cluster1.expYears')?.errors && form.get('step2.cluster1.expYears')?.touched">Il
          numero inserito non è valido.</p>

        <div *ngIf="form.get('step2.cluster1.expYears')?.value" class="form-field-group" style="gap: 20px;">
          <label class="label"><span class="required">*</span>Presso chi lavorava?</label>

          <div class="radio-list">
            <div style="width: fit-content" class="radio-btn"
              [ngClass]="{'selected': (form.get('step2.cluster1.employer')?.value === 'Famiglia privata')}">
              <input style="display: none" type="radio" id="privato1" formControlName="employer" name="employer"
                value="Famiglia privata">
              <label for="privato1">
                <div style="font-size: 0.8em;">A</div>Famiglia privata
              </label>
            </div>
            <div style="width: fit-content" class="radio-btn"
              [ngClass]="{'selected': (form.get('step2.cluster1.employer')?.value === 'Asilo')}">
              <input style="display: none" type="radio" id="asilo1" formControlName="employer" name="employer"
                value="Asilo">
              <label for="asilo1">
                <div style="font-size: 0.8em;">B</div>Asilo
              </label>
            </div>
          </div>

          <div formGroupName="tasks" class="form-field-group">
            <span class="label"><span class="required">*</span>Quali delle seguenti attività sa fare per la fascia di
              <b>età 0-6 mesi</b> del
              bambino?<br>
              <b>Importante: indichi soltanto cose vere, avremo modo di verificarle.</b>
            </span>

            <div class="checkbox-list">
              <div>
                <input type="checkbox" id="hygiene" formControlName="hygiene" name="hygiene">
                <label for="hygiene">Cambio pannolini e igiene del bambino</label>
              </div>
              <div>
                <input type="checkbox" id="sleep-1" formControlName="sleep" name="sleep-1">
                <label for="sleep-1">Accompagnamento alla nanna</label>
              </div>
              <div>
                <input type="checkbox" id="food" formControlName="food" name="food">
                <label for="food">Preparazione pappa</label>
              </div>
              <div>
                <input type="checkbox" id="weaning" formControlName="weaning" name="weaning">
                <label for="weaning">Svezzamento</label>
              </div>
              <div>
                <input type="checkbox" id="food-safety-1" formControlName="foodSafety" name="food-safety-1">
                <label for="food-safety-1">Conoscenza tagli sicuri degli alimenti e autosvezzamento</label>
              </div>
              <div>
                <input type="checkbox" id="other" formControlName="other" name="other">
                <label for="other">Altro (specifica attività che sai fare per questa fascia di età e che ti rende
                  speciale)</label>
              </div>
            </div>
          </div>

          <div style="margin-top: 15px;" *ngIf="form.get('step2.cluster1.tasks.other')?.value"
            formArrayName="otherTasks">
            <label class="label"><span class="required">*</span>Specifica altra attività</label>
            <div class="dynamic-field">
              <div class="form-field-group">
                <div *ngFor="let formControl of getFormArrayControls(1, 'otherTasks'), index as i">
                  <input class="form-input" [ngClass]="{'red-border': formControl.errors && formControl.touched}"
                    type="text" [formControl]="$any(formControl)" placeholder="Attività {{ i + 1 }}" />
                </div>
              </div>
              <div class="quantity-btn-container">
                <button class="form-input" (click)="changeFormArrayLength(true, 1, 'otherTasks')">+</button>
                <button *ngIf="form.get('step2.cluster1.otherTasks')?.value.length > 1" class="form-input"
                  (click)="changeFormArrayLength(false, 1, 'otherTasks')">-</button>
              </div>
            </div>
            <p class="form-error" style="margin-top: 0"
              *ngIf="!form.get('step2.cluster1.otherTasks')?.valid && form.get('step2.cluster1.otherTasks')?.touched">
              Il campo è obbligatorio</p>
          </div>
        </div>
      </div>

      <div formGroupName="cluster2">
        <label class="label" for="years2"><span class="required">*</span><b>Quanti ANNI di esperienza hai con bambini da
            7 a 24 mesi di età?</b>
        </label>
        <input class="form-input"
          [ngClass]="{'red-border': (form.get('step2.cluster2.expYears')?.errors && form.get('step2.cluster2.expYears')?.touched)}"
          type="number" id="years2" formControlName="expYears" min="0" max="50" required>
        <p class="form-error"
          *ngIf="form.get('step2.cluster2.expYears')?.errors && form.get('step2.cluster2.expYears')?.touched">Il
          numero inserito non è valido.</p>

        <div *ngIf="form.get('step2.cluster2.expYears')?.value" class="form-field-group" style="gap: 20px;">
          <label class="label"><span class="required">*</span>Presso chi lavorava?</label>

          <div class="radio-list">
            <div style="width: fit-content" class="radio-btn"
              [ngClass]="{'selected': (form.get('step2.cluster2.employer')?.value === 'Famiglia privata')}">
              <input style="display: none" type="radio" id="privato2" formControlName="employer" name="employer"
                value="Famiglia privata">
              <label for="privato2">
                <div style="font-size: 0.8em;">A</div>Famiglia privata
              </label>
            </div>
            <div style="width: fit-content" class="radio-btn"
              [ngClass]="{'selected': (form.get('step2.cluster2.employer')?.value === 'Asilo')}">
              <input style="display: none" type="radio" id="asilo2" formControlName="employer" name="employer"
                value="Asilo">
              <label for="asilo2">
                <div style="font-size: 0.8em;">B</div>Asilo
              </label>
            </div>
          </div>

          <div formGroupName="tasks" class="form-field-group">
            <span class="label"><span class="required">*</span>Quali delle seguenti attività sa fare per la fascia di
              <b>età 7-24 mesi</b> del
              bambino?<br>
              <b>Importante: indichi soltanto cose vere, avremo modo di verificarle.</b>
            </span>

            <div class="checkbox-list">
              <div>
                <input type="checkbox" id="cry" formControlName="cry" name="cry">
                <label for="cry">Gestione del pianto</label>
              </div>
              <div>
                <input type="checkbox" id="crawling" formControlName="crawling" name="crawling">
                <label for="crawling">Stimolo al gattonamento</label>
              </div>
              <div>
                <input type="checkbox" id="montessori" formControlName="montessori" name="montessori">
                <label for="montessori">Approccio al gioco con metodo Montessori</label>
              </div>
              <div>
                <input type="checkbox" id="language" formControlName="language" name="language">
                <label for="language">Attestato di sviluppo del linguaggio</label>
              </div>
              <div>
                <input type="checkbox" id="food-safety-2" formControlName="foodSafety" name="food-safety-2">
                <label for="food-safety-2">Conoscenza tagli sicuri degli alimenti e autosvezzamento</label>
              </div>
              <div>
                <input type="checkbox" id="motory" formControlName="motory" name="motory">
                <label for="motory">Stimolo allo sviluppo motorio</label>
              </div>
              <div>
                <input type="checkbox" id="other2" formControlName="other" name="other2">
                <label for="other2">Altro (specifica attività che sai fare per questa fascia di età e che ti rende
                  speciale)</label>
              </div>
            </div>
          </div>

          <div style="margin-top: 15px;" *ngIf="form.get('step2.cluster2.tasks.other')?.value"
            formArrayName="otherTasks">
            <label class="label"><span class="required">*</span>Specifica altra attività</label>
            <div class="dynamic-field">
              <div class="form-field-group">
                <div *ngFor="let formControl of getFormArrayControls(2, 'otherTasks'), index as i">
                  <input class="form-input" [ngClass]="{'red-border': formControl.errors && formControl.touched}"
                    type="text" [formControl]="$any(formControl)" placeholder="Attività {{ i + 1 }}" />
                </div>
              </div>
              <div class="quantity-btn-container">
                <button class="form-input" (click)="changeFormArrayLength(true, 2, 'otherTasks')">+</button>
                <button *ngIf="form.get('step2.cluster2.otherTasks')?.value.length > 1" class="form-input"
                  (click)="changeFormArrayLength(false, 2, 'otherTasks')">-</button>
              </div>
            </div>
            <p class="form-error" style="margin-top: 0"
              *ngIf="!form.get('step2.cluster2.otherTasks')?.valid && form.get('step2.cluster2.otherTasks')?.touched">
              Il campo è obbligatorio</p>
          </div>
        </div>
      </div>

      <div formGroupName="cluster3">
        <label class="label" for="years3"><span class="required">*</span><b>Quanti ANNI di esperienza hai con bambini da
            2 a 5 anni di età?</b></label>
        <input class="form-input"
          [ngClass]="{'red-border': (form.get('step2.cluster3.expYears')?.errors && form.get('step2.cluster3.expYears')?.touched)}"
          type="number" id="years3" formControlName="expYears" min="0" max="50" required>
        <p class="form-error"
          *ngIf="form.get('step2.cluster3.expYears')?.errors && form.get('step2.cluster3.expYears')?.touched">Il
          numero inserito non è valido.</p>

        <div *ngIf="form.get('step2.cluster3.expYears')?.value" class="form-field-group" style="gap: 20px;">
          <label class="label"><span class="required">*</span>Presso chi lavorava?</label>

          <div class="radio-list">
            <div style="width: fit-content" class="radio-btn"
              [ngClass]="{'selected': (form.get('step2.cluster3.employer')?.value === 'Famiglia privata')}">
              <input style="display: none" type="radio" id="privato3" formControlName="employer" name="employer"
                value="Famiglia privata">
              <label for="privato3">
                <div style="font-size: 0.8em;">A</div>Famiglia privata
              </label>
            </div>
            <div style="width: fit-content" class="radio-btn"
              [ngClass]="{'selected': (form.get('step2.cluster3.employer')?.value === 'Asilo')}">
              <input style="display: none" type="radio" id="asilo3" formControlName="employer" name="employer"
                value="Asilo">
              <label for="asilo3">
                <div style="font-size: 0.8em;">B</div>Asilo
              </label>
            </div>
          </div>

          <div formGroupName="tasks" class="form-field-group">
            <span class="label"><span class="required">*</span>Quali delle seguenti attività sa fare per la fascia di
              <b>età 2-5 anni</b> del
              bambino?<br>
              <b>Importante: indichi soltanto cose vere, avremo modo di verificarle.</b></span>

            <div class="checkbox-list">
              <div>
                <input type="checkbox" id="sleep-2" formControlName="sleep" name="sleep-2">
                <label for="sleep-2">Accompagnamento al sonno</label>
              </div>
              <div>
                <input type="checkbox" id="illness" formControlName="illness" name="illness">
                <label for="illness">Gestione della malattia</label>
              </div>
              <div>
                <input type="checkbox" id="motory-activities" formControlName="motoryActivities"
                  name="motory-activities">
                <label for="motory-activities">Attività motoria all’aperto</label>
              </div>
              <div>
                <input type="checkbox" id="emotion-management" formControlName="emotionManagement"
                  name="emotion-management">
                <label for="emotion-management">Gestione emotiva degli incontri con altri bambini</label>
              </div>
              <div>
                <input type="checkbox" id="spannolinamento" formControlName="spannolinamento" name="spannolinamento">
                <label for="spannolinamento">Avvio dello spannolinamento</label>
              </div>
              <div>
                <input type="checkbox" id="creative-games" formControlName="creativeGames" name="creative-games">
                <label for="creative-games">Gioco creativo (specificare, es: pasta di sale…)</label>
              </div>
              <div>
                <input type="checkbox" id="other3" formControlName="other" name="other3">
                <label for="other3">Altro (specifica attività che sai fare per questa fascia di età e che ti rende
                  speciale)</label>
              </div>
            </div>
          </div>

          <div style="margin-top: 15px;" *ngIf="form.get('step2.cluster3.tasks.creativeGames')?.value"
            formArrayName="gamesDesc">
            <label class="label"><span class="required">*</span>Specifica gioco creativo</label>
            <div class="dynamic-field">
              <div class="form-field-group">
                <div *ngFor="let formControl of getFormArrayControls(3, 'gamesDesc'), index as i">
                  <input class="form-input" [ngClass]="{'red-border': formControl.errors && formControl.touched}"
                    type="text" [formControl]="$any(formControl)" placeholder="Gioco {{ i + 1 }}" />
                </div>
              </div>
              <div class="quantity-btn-container">
                <button class="form-input" (click)="changeFormArrayLength(true, 3, 'gamesDesc')">+</button>
                <button *ngIf="form.get('step2.cluster3.gamesDesc')?.value.length > 1" class="form-input"
                  (click)="changeFormArrayLength(false, 3, 'gamesDesc')">-</button>
              </div>
            </div>
            <p class="form-error" style="margin-top: 0"
              *ngIf="!form.get('step2.cluster3.gamesDesc')?.valid && form.get('step2.cluster3.gamesDesc')?.touched">
              Il campo è obbligatorio</p>
          </div>

          <div style="margin-top: 15px;" *ngIf="form.get('step2.cluster3.tasks.other')?.value"
            formArrayName="otherTasks">
            <label class="label"><span class="required">*</span>Specifica altra attività</label>
            <div class="dynamic-field">
              <div class="form-field-group">
                <div *ngFor="let formControl of getFormArrayControls(3, 'otherTasks'), index as i">
                  <input class="form-input" [ngClass]="{'red-border': formControl.errors && formControl.touched}"
                    type="text" [formControl]="$any(formControl)" placeholder="Attività {{ i + 1 }}" />
                </div>
              </div>
              <div class="quantity-btn-container">
                <button class="form-input" (click)="changeFormArrayLength(true, 3, 'otherTasks')">+</button>
                <button *ngIf="form.get('step2.cluster3.otherTasks')?.value.length > 1" class="form-input"
                  (click)="changeFormArrayLength(false, 3, 'otherTasks')">-</button>
              </div>
            </div>
            <p class="form-error" style="margin-top: 0"
              *ngIf="!form.get('step2.cluster3.otherTasks')?.valid && form.get('step2.cluster3.otherTasks')?.touched">
              Il campo è obbligatorio</p>
          </div>
        </div>
      </div>

      <div formGroupName="cluster4">
        <label class="label" for="years4"><span class="required">*</span><b>Quanti ANNI di esperienza hai con bambini di
            età superiore ai 5 anni?</b></label>
        <input class="form-input"
          [ngClass]="{'red-border': (form.get('step2.cluster4.expYears')?.errors && form.get('step2.cluster4.expYears')?.touched)}"
          type="number" id="years4" formControlName="expYears" min="0" max="50" required>
        <p class="form-error"
          *ngIf="form.get('step2.cluster4.expYears')?.errors && form.get('step2.cluster4.expYears')?.touched">Il
          numero inserito non è valido.</p>

        <div *ngIf="form.get('step2.cluster4.expYears')?.value" class="form-field-group" style="gap: 20px;">
          <label class="label"><span class="required">*</span>Presso chi lavorava?</label>

          <div class="radio-list">
            <div style="width: fit-content" class="radio-btn"
              [ngClass]="{'selected': (form.get('step2.cluster4.employer')?.value === 'Famiglia privata')}">
              <input style="display: none" type="radio" id="privato4" formControlName="employer" name="employer"
                value="Famiglia privata">
              <label for="privato4">
                <div style="font-size: 0.8em;">A</div>Famiglia privata
              </label>
            </div>
            <div style="width: fit-content" class="radio-btn"
              [ngClass]="{'selected': (form.get('step2.cluster4.employer')?.value === 'Asilo')}">
              <input style="display: none" type="radio" id="asilo4" formControlName="employer" name="employer"
                value="Asilo">
              <label for="asilo4">
                <div style="font-size: 0.8em;">B</div>Asilo
              </label>
            </div>
            <div style="width: fit-content" class="radio-btn"
              [ngClass]="{'selected': (form.get('step2.cluster4.employer')?.value === 'Scuola primaria')}">
              <input style="display: none" type="radio" id="primaria" formControlName="employer" name="employer"
                value="Scuola primaria">
              <label for="primaria">
                <div style="font-size: 0.8em;">C</div>Scuola primaria
              </label>
            </div>
            <div style="width: fit-content" class="radio-btn"
              [ngClass]="{'selected': (form.get('step2.cluster4.employer')?.value === 'Scuola secondaria')}">
              <input style="display: none" type="radio" id="secondaria" formControlName="employer" name="employer"
                value="Scuola secondaria">
              <label for="secondaria">
                <div style="font-size: 0.8em;">D</div>Scuola secondaria
              </label>
            </div>
          </div>

          <div formGroupName="tasks" class="form-field-group">
            <label class="label"><span class="required">*</span>Quali delle seguenti attività sa fare per la fascia di
              <b>età superiore ai 5 anni</b>
              del
              bambino?<br>
              <b>Importante: indichi soltanto cose vere, avremo modo di verificarle.</b></label>

            <div class="checkbox-list">
              <div>
                <input type="checkbox" id="homeworks" formControlName="homeworks" name="homeworks">
                <label for="homeworks">Portare a termine i compiti</label>
              </div>
              <div>
                <input type="checkbox" id="free-time" formControlName="freeTime" name="free-time">
                <label for="free-time">Gestione del tempo libero (specificare)</label>
              </div>
              <div>
                <input type="checkbox" id="extra-activities" formControlName="extra" name="extra-activities">
                <label for="extra-activities">Accompagnamento alle attività extra scolastiche</label>
              </div>
              <div>
                <input type="checkbox" id="other4" formControlName="other" name="other4">
                <label for="other4">Altro (specifica attività che sai fare per questa fascia di età e che ti rende
                  speciale)</label>
              </div>
            </div>
          </div>

          <div style="margin-top: 15px;" *ngIf="form.get('step2.cluster4.tasks.freeTime')?.value"
            formArrayName="freeTimeDesc">
            <span class="label"><span class="required">*</span>Specifica gestione tempo libero</span>
            <div class="dynamic-field">
              <div class="form-field-group">
                <div *ngFor="let formControl of getFormArrayControls(4, 'freeTimeDesc'), index as i">
                  <input class="form-input" [ngClass]="{'red-border': formControl.errors && formControl.touched}"
                    type="text" [formControl]="$any(formControl)" placeholder="Attività {{ i + 1 }}" />
                </div>
              </div>
              <div class="quantity-btn-container">
                <button class="form-input" (click)="changeFormArrayLength(true, 4, 'freeTimeDesc')">+</button>
                <button *ngIf="form.get('step2.cluster4.freeTimeDesc')?.value.length > 1" class="form-input"
                  (click)="changeFormArrayLength(false, 4, 'freeTimeDesc')">-</button>
              </div>
            </div>
            <p class="form-error" style="margin-top: 0"
              *ngIf="!form.get('step2.cluster4.freeTimeDesc')?.valid && form.get('step2.cluster4.freeTimeDesc')?.touched">
              Il campo è obbligatorio</p>
          </div>

          <div style="margin-top: 15px;" *ngIf="form.get('step2.cluster4.tasks.other')?.value"
            formArrayName="otherTasks">
            <span class="label"><span class="required">*</span>Specifica altra attività</span>
            <div class="dynamic-field">
              <div class="form-field-group">
                <div *ngFor="let formControl of getFormArrayControls(4, 'otherTasks'), index as i">
                  <input class="form-input" [ngClass]="{'red-border': formControl.errors && formControl.touched}"
                    type="text" [formControl]="$any(formControl)" placeholder="Attività {{ i + 1 }}" />
                </div>
              </div>
              <div class="quantity-btn-container">
                <button class="form-input" (click)="changeFormArrayLength(true, 4, 'otherTasks')">+</button>
                <button *ngIf="form.get('step2.cluster4.otherTasks')?.value.length > 1" class="form-input"
                  (click)="changeFormArrayLength(false, 4, 'otherTasks')">-</button>
              </div>
            </div>
            <p class="form-error" style="margin-top: 0"
              *ngIf="!form.get('step2.cluster4.otherTasks')?.valid && form.get('step2.cluster4.otherTasks')?.touched">
              Il campo è obbligatorio</p>
          </div>
        </div>
      </div>

      <div *ngFor="let elem of availQuestions, index as i">
        <label class="label"><span class="required">*</span><b>{{ elem.question }}</b></label>
        <div class="radio-list">
          <div class="radio-btn" [ngClass]="{'selected': (form.get('step2')?.get(elem.form)?.value === 'true')}">
            <input style="display: none" type="radio" id="{{ elem.form }}-yes" formControlName="{{ elem.form }}"
              name="{{ elem.form }}" value="true">
            <label for="{{ elem.form }}-yes">
              <div style="font-size: 0.8em;">A</div>Sì
            </label>
          </div>
          <div class="radio-btn" [ngClass]="{'selected': (form.get('step2')?.get(elem.form)?.value === 'false')}">
            <input style="display: none" type="radio" id="{{ elem.form }}-no" formControlName="{{ elem.form }}"
              name="{{ elem.form }}" value="false">
            <label for="{{ elem.form }}-no">
              <div style="font-size: 0.8em;">B</div>No
            </label>
          </div>
        </div>
      </div>

      <div formGroupName="roles" style="grid-column: 1/3">
        <label class="label"><span class="required">*</span><b>Per quali dei seguenti ruoli da disponibilità a lavorare?
            Scegli <u>più ruoli</u>:</b>
        </label>

        <div class="checkbox-list">
          <div>
            <input type="checkbox" id="babysitter" formControlName="babysitter" name="babysitter">
            <label for="babysitter">Babysitter</label>
          </div>
          <div>
            <input type="checkbox" id="taxisitter" formControlName="taxisitter" name="taxisitter">
            <label for="taxisitter">Taxi sitter (con propria auto)</label>
          </div>
          <div>
            <input type="checkbox" id="taxisitter-no-car" formControlName="taxisitterNoCar" name="taxisitter-no-car">
            <label for="taxisitter-no-car">Taxi sitter (senza auto, solo se i genitori mi danno la loro auto da
              guidare)</label>
          </div>
          <div>
            <input type="checkbox" id="walking" formControlName="walking" name="walking">
            <label for="walking">Taxi sitter a piedi</label>
          </div>
          <div>
            <input type="checkbox" id="cleaning" formControlName="cleaning" name="cleaning">
            <label for="cleaning">Aiuto pulizie (aggiuntivo a babysitter)</label>
          </div>
          <div>
            <input type="checkbox" id="petsitter" formControlName="petsitter" name="petsitter">
            <label for="petsitter">Pet sitter (cani e gatti)</label>
          </div>
          <div>
            <input type="checkbox" id="cooking" formControlName="cooking" name="cooking">
            <label for="cooking">Disponibile a cucinare (aggiuntivo a babysitter)</label>
          </div>
          <div>
            <input type="checkbox" id="homework" formControlName="homework" name="homework">
            <label for="homework">Aiuto compiti</label>
          </div>
          <div>
            <input type="checkbox" id="coliving" formControlName="coliving" name="coliving">
            <label for="coliving">Disponibile alla convivenza</label>
          </div>
          <div>
            <input type="checkbox" id="relocation" formControlName="relocation" name="relocation">
            <label for="relocation">Disponibile alla trasferta</label>
          </div>
        </div>
        <p class="form-error" *ngIf="form.get('step2.roles')?.errors && form.get('step2.roles')?.touched">Seleziona
          almeno uno</p>
      </div>

      <div>
        <label class="label"><span class="required">*</span><b>Quanti ANNI di esperienza complessiva ha come
            AIUTOCOMPITI?</b>
        </label>
        <input class="form-input"
          [ngClass]="{'red-border': (form.get('step2.homeworkExp')?.errors && form.get('step2.homeworkExp')?.touched)}"
          type="number" id="homework-exp" formControlName="homeworkExp" min="0" max="50" required>
        <p class="form-error" *ngIf="form.get('step2.homeworkExp')?.errors && form.get('step2.homeworkExp')?.touched">
          Il
          numero inserito non è valido.</p>
      </div>

      <div style="grid-row: 6/7" *ngIf="form.get('step2.homeworkExp')?.value">
        <span class="label"><b>Che età avevano i bambini:</b></span>
        <div class="form-input-container">
          <span class="label"><span class="required">*</span><b>Primo bambino:</b></span>
          <div class="child-age-flex">
            <input class="form-input"
              [ngClass]="{'red-border': (form.get('step2.childAge1')?.errors && form.get('step2.childAge1')?.touched)}"
              type="number" id="child-age-1" formControlName="childAge1" min="1" max="15" required>
            <p style="font-size: 14pt;">anni</p>
          </div>
        </div>
        <p class="form-error"
          *ngIf="form.get('step2.childAge1')?.errors && form.get('step2.childAge1')?.touched">Il
          numero inserito non è valido.</p>

        <div class="form-input-container">
          <span class="label"><b>Secondo bambino:</b>
          </span>
          <div class="child-age-flex">
            <input class="form-input"
              [ngClass]="{'red-border': (form.get('step2.childAge2')?.errors && form.get('step2.childAge2')?.touched)}"
              type="number" id="child-age-2" formControlName="childAge2" min="0" max="15">
            <p style="font-size: 14pt;">anni</p>
          </div>
        </div>
        <p class="form-error"
          *ngIf="form.get('step2.childAge2')?.errors && form.get('step2.childAge2')?.touched">Il
          numero inserito non è valido.</p>

        <div class="form-input-container">
          <span class="label"><b>Terzo bambino:</b>
          </span>
          <div class="child-age-flex">
            <input class="form-input"
              [ngClass]="{'red-border': (form.get('step2.childAge3')?.errors && form.get('step2.childAge3')?.touched)}"
              type="number" id="child-age-3" formControlName="childAge3" min="0" max="15">
            <p style="font-size: 14pt;">anni</p>
          </div>
        </div>
        <p class="form-error"
          *ngIf="form.get('step2.childAge3')?.errors && form.get('step2.childAge3')?.touched">Il
          numero inserito non è valido.</p>
      </div>

      <div *ngIf="form.get('step2.homeworkExp')?.value" style="grid-column: 2/3; grid-row: 6/7;"
        formGroupName="subjects">
        <span class="label"><span class="required">*</span><b>In che materie faceva ripetizione?</b></span>
        <div class="checkbox-list" style="gap: 15px">
          <div>
            <input type="checkbox" id="math" formControlName="math" name="math">
            <label for="math">Matematica</label>
          </div>
          <div>
            <input type="checkbox" id="italian" formControlName="italian" name="italian">
            <label for="italian">Italiano</label>
          </div>
          <div>
            <input type="checkbox" id="english" formControlName="english" name="english">
            <label for="english">Inglese</label>
          </div>
          <div>
            <input type="checkbox" id="french" formControlName="french" name="french">
            <label for="french">Francese</label>
          </div>
          <div>
            <input type="checkbox" id="all" formControlName="all" name="all">
            <label for="all">Un po' di tutto</label>
          </div>
          <div>
            <div>
              <input type="checkbox" id="other" formControlName="other" name="other">
              <label for="other">Altro (specificare)</label>
            </div>

            <div style="margin-top: 15px;" *ngIf="form.get('step2.subjects.other')?.value">
              <input class="form-input" type="text" id="other-subject" formControlName="otherDesc" placeholder="Materia"
                [ngClass]="{'red-border': (form.get('step2.subjects.otherDesc')?.errors && form.get('step2.subjects.otherDesc')?.touched)}" />
              <p class="form-error" style="margin-top: 0"
                *ngIf="form.get('step2.subjects.otherDesc')?.errors && form.get('step2.subjects.otherDesc')?.touched">
                Il
                campo è
                obbligatorio</p>
            </div>
          </div>
        </div>
        <p class="form-error" *ngIf="form.get('step2.subjects')?.errors && form.get('step2.subjects')?.touched">
          Seleziona almeno uno</p>
      </div>
    </div>

    <div class="grid-container" formGroupName="step3" *ngIf="formStep === 3">
      <div style="grid-column: 1/3">
        <div><span class="label"><b>La seguente domanda la aiuterà davvero molto a trovare
              velocemente lavoro:<br>
              Indichi il cognome della famiglia o delle famiglie che sarebbero disponibili a scrivere una referenza su
              di lei:</b>
          </span></div>

        <div class="form-input-container">
          <label class="label" for="parentSurname">COGNOME PRIMA FAMIGLIA</label>
          <input class="form-input" type="text" id="parentSurname" formControlName="parentSurname">
        </div>

        <div class="form-input-container">
          <label class="label" for="parentSurname2">COGNOME SECONDA FAMIGLIA</label>
          <input class="form-input" type="text" id="parentSurname2" formControlName="parentSurname2">
        </div>
      </div>
    </div>

    <div class="grid-container" formGroupName="step4" *ngIf="formStep === 4">
      <div formGroupName="certificates">
        <span class="label"><b>Quali certificati possiede? (Verrà verificato)</b></span>

        <div class="dynamic-field">
          <div class="form-field-group">
            <div *ngFor="let formControl of getCertificatesFormControls(), index as i">
              <input class="form-input" [ngClass]="{'red-border': formControl.errors && formControl.touched}"
                type="text" [formControl]="$any(formControl)" placeholder="Certificato {{ i + 1 }}" />
            </div>
          </div>

          <div class="quantity-btn-container">
            <button class="form-input" (click)="changeCertificatesLength(true)">+</button>
            <button *ngIf="form.get('step4.certificates')?.value.length > 1" class="form-input"
              (click)="changeCertificatesLength(false)">-</button>
          </div>
        </div>
      </div>

      <div>
        <span class="label"><span class="required">*</span>Qual è il suo titolo di studio?</span>
        <div class="radio-list">
          <div style="width: fit-content" class="radio-btn"
            [ngClass]="{'selected': (form.get('step4')?.get('title')?.value === 'Medie inferiori')}">
            <input style="display: none" type="radio" id="medie" formControlName="title" name="title"
              value="Medie inferiori">
            <label for="medie">
              <div style="font-size: 0.8em;">A</div>Medie inferiori
            </label>
          </div>
          <div style="width: fit-content" class="radio-btn"
            [ngClass]="{'selected': (form.get('step4')?.get('title')?.value === 'Medie superiori')}">
            <input style="display: none" type="radio" id="superiori" formControlName="title" name="title"
              value="Medie superiori">
            <label for="superiori">
              <div style="font-size: 0.8em;">B</div>Medie superiori
            </label>
          </div>
          <div class="form-graduation">
            <div style="width: fit-content" class="radio-btn"
              [ngClass]="{'selected': (form.get('step4')?.get('title')?.value === 'Laurea')}">
              <input style="display: none" type="radio" id="laurea" formControlName="title" name="title" value="Laurea">
              <label for="laurea">
                <div style="font-size: 0.8em;">C</div>Laurea (specificare quale)
              </label>
            </div>
            <input *ngIf="form.get('step4.title')?.value === 'Laurea'" class="form-input" style="max-width: 400px"
              type="text" id="graduation-type" formControlName="graduationType" placeholder="Laurea"
              [ngClass]="{'red-border': (form.get('step4.graduationType')?.errors && form.get('step4.graduationType')?.touched)}" />
            <p class="form-error"
              *ngIf="form.get('step4.graduationType')?.errors && form.get('step4.graduationType')?.touched">Il campo è
              obbligatorio</p>
          </div>
        </div>
      </div>

      <div>
        <label class="label" for="english"><span class="required">*</span>Parli Inglese?</label>
        <select class="form-select"
          [ngClass]="{'red-border': (form.get('step4.english')?.errors && form.get('step4.english')?.touched)}"
          id="english" formControlName="english" required>
          <option value="Non lo parlo">Non lo parlo</option>
          <option value="Sufficiente">Sufficiente</option>
          <option value="Ottimo">Ottimo</option>
          <option value="Madrelingua">Madrelingua</option>
        </select>
        <p class="form-error" *ngIf="form.get('step4.english')?.errors && form.get('step4.english')?.touched">Il campo
          è
          obbligatorio.</p>
      </div>

      <div>
        <label class="label" for="french"><span class="required">*</span>Parli Francese?</label>
        <select class="form-select"
          [ngClass]="{'red-border': (form.get('step4.french')?.errors && form.get('step4.french')?.touched)}"
          id="french" formControlName="french" required>
          <option value="Non lo parlo">Non lo parlo</option>
          <option value="Sufficiente">Sufficiente</option>
          <option value="Ottimo">Ottimo</option>
          <option value="Madrelingua">Madrelingua</option>
        </select>
        <p class="form-error" *ngIf="form.get('step4.french')?.errors && form.get('step4.french')?.touched">Il campo è
          obbligatorio.</p>
      </div>

      <div>
        <label class="label" for="italian"><span class="required">*</span>Parli Italiano?</label>
        <select class="form-select"
          [ngClass]="{'red-border': (form.get('step4.italian')?.errors && form.get('step4.italian')?.touched)}"
          id="italian" formControlName="italian" required>
          <option value="Non lo parlo">Non lo parlo</option>
          <option value="Sufficiente">Sufficiente</option>
          <option value="Ottimo">Ottimo</option>
          <option value="Madrelingua">Madrelingua</option>
        </select>
        <p class="form-error" *ngIf="form.get('step4.italian')?.errors && form.get('step4.italian')?.touched">Il campo
          è
          obbligatorio.</p>
      </div>

      <div>
        <label class="label" for="german"><span class="required">*</span>Parli Tedesco?</label>
        <select class="form-select"
          [ngClass]="{'red-border': (form.get('step4.german')?.errors && form.get('step4.german')?.touched)}"
          id="german" formControlName="german" required>
          <option value="Non lo parlo">Non lo parlo</option>
          <option value="Sufficiente">Sufficiente</option>
          <option value="Ottimo">Ottimo</option>
          <option value="Madrelingua">Madrelingua</option>
        </select>
        <p class="form-error" *ngIf="form.get('step4.german')?.errors && form.get('step4.german')?.touched">Il campo è
          obbligatorio.</p>
      </div>

      <div>
        <label class="label" for="spanish"><span class="required">*</span>Parli Spagnolo?</label>
        <select class="form-select"
          [ngClass]="{'red-border': (form.get('step4.spanish')?.errors && form.get('step4.spanish')?.touched)}"
          id="spanish" formControlName="spanish" required>
          <option value="Non lo parlo">Non lo parlo</option>
          <option value="Sufficiente">Sufficiente</option>
          <option value="Ottimo">Ottimo</option>
          <option value="Madrelingua">Madrelingua</option>
        </select>
        <p class="form-error" *ngIf="form.get('step4.spanish')?.errors && form.get('step4.spanish')?.touched">Il
          campo è
          obbligatorio.</p>
      </div>
    </div>

    <div class="grid-container" formGroupName="step5" *ngIf="formStep === 5">
      <div *ngFor="let elem of otherQuestions">
        <span class="label"><span class="required">*</span><b>{{ elem.question }}</b></span>
        <div class="radio-list">
          <div class="radio-btn" [ngClass]="{'selected': (form.get('step5')?.get(elem.form)?.value === 'true')}">
            <input style="display: none" type="radio" id="{{ elem.form }}-yes" formControlName="{{ elem.form }}"
              name="{{ elem.form }}" value="true">
            <label for="{{ elem.form }}-yes">
              <div style="font-size: 0.8em;">A</div>Sì
            </label>
          </div>
          <div class="radio-btn" [ngClass]="{'selected': (form.get('step5')?.get(elem.form)?.value === 'false')}">
            <input style="display: none" type="radio" id="{{ elem.form }}-no" formControlName="{{ elem.form }}"
              name="{{ elem.form }}" value="false">
            <label for="{{ elem.form }}-no">
              <div style="font-size: 0.8em;">B</div>No
            </label>
          </div>
        </div>
      </div>

      <div formGroupName="daysAvail">
        <span class="label"><span class="required">*</span>IN CHE GIORNI SEI DISPONIBILE?</span>
        <div class="checkbox-list">
          <div>
            <input type="checkbox" id="monday" formControlName="mon" name="mon">
            <label for="monday">Lunedì</label>
          </div>
          <div>
            <input type="checkbox" id="tuesday" formControlName="tue" name="tue">
            <label for="tuesday">Martedì</label>
          </div>
          <div>
            <input type="checkbox" id="wednesday" formControlName="wed" name="wed">
            <label for="wednesday">Mercoledì</label>
          </div>
          <div>
            <input type="checkbox" id="thursday" formControlName="thu" name="thu">
            <label for="thursday">Giovedì</label>
          </div>
          <div>
            <input type="checkbox" id="friday" formControlName="fri" name="fri">
            <label for="friday">Venerdì</label>
          </div>
          <div>
            <input type="checkbox" id="saturday" formControlName="sat" name="sat">
            <label for="saturday">Sabato</label>
          </div>
          <div>
            <input type="checkbox" id="sunday" formControlName="sun" name="sun">
            <label for="sunday">Domenica</label>
          </div>
        </div>
        <p class="form-error" *ngIf="form.get('step5.daysAvail')?.errors && form.get('step5.daysAvail')?.touched">Il
          campo è obbligatorio.</p>
      </div>

      <div>
        <div formGroupName="hoursAvail">
          <span class="label"><span class="required">*</span>IN CHE ORARI SEI DISPONIBILE?</span>

          <div class="checkbox-list">
            <div>
              <input type="checkbox" id="morning" formControlName="mor" name="mor">
              <label for="morning">Mattina</label>
            </div>
            <div>
              <input type="checkbox" id="afternoon" formControlName="aft" name="aft">
              <label for="afternoon">Pomeriggio</label>
            </div>
            <div>
              <input type="checkbox" id="evening" formControlName="eve" name="eve">
              <label for="evening">Sera</label>
            </div>
          </div>
          <p class="form-error" style="margin-top: 10px"
            *ngIf="form.get('step5.hoursAvail')?.errors && form.get('step5.hoursAvail')?.touched">Il
            campo è obbligatorio.</p>
        </div>

        <div formGroupName="taskType">
          <span class="label"><span class="required">*</span>PER QUALI INCARICHI SEI INTERESSAT*?</span>

          <div class="checkbox-list">
            <div>
              <input type="checkbox" id="occasional" formControlName="occ" name="occ">
              <label for="occasional">Occasionali a ore</label>
            </div>
            <div>
              <input type="checkbox" id="part-time" formControlName="part" name="part">
              <label for="part-time">Continuativi part time</label>
            </div>
            <div>
              <input type="checkbox" id="full-time" formControlName="full" name="full">
              <label for="full-time">Continuativi full time</label>
            </div>
          </div>
          <p class="form-error" style="margin-top: 10px"
            *ngIf="form.get('step5.taskType')?.errors && form.get('step5.taskType')?.touched">Il
            campo è obbligatorio.</p>
        </div>
      </div>
      <div style="grid-column: 1/3;" class="input-field two-columns">
        <label class="label" for="description"><span class="required">*</span><b>Descriviti pensando al perchè la
            famiglia dovrebbe sceglierti.<br>
            In questo spazio NON mettere le esperienze di lavoro</b>
          <p style="font-size: 14px; font-weight: 300;">Elenca i tuoi punti di forza che desideri che la famiglia
            sappia
            di te.<br>
            Descrivi chi sei, le tue passioni e cosa ti caratterizza (es: volontariato, sport…)
          </p>
        </label>
        <textarea rows="6" id="description" minlength="310" maxlength="426" formControlName="description"
          required></textarea>
        <p class="textarea-tip" [ngClass]="form.get('step5')?.get('description')?.valid ? 'green-text' : 'red-text'">
          Il
          valore deve essere compreso fra 310 e 426 caratteri ({{ descriptionLength }})</p>
      </div>
      <div class="two-columns">
        <span class="label" style="font-size: 14pt;"><span class="required">*</span>Carica una foto simile a
          <b>fototessera, sorridente e con sfondo
            neutro.</b>
        </span>
        <div class="image-upload">
          <label class="button d-flex align-items-center gap-1" (click)="touchButton()">
            <input id="image-selection" type="file" (change)="openModal($event)" (cancel)="abortSelection($event)">
            <span class="spinner-border text-light" style="width: 16px;height: 16px;" *ngIf="showSpinnerPhoto"></span>
            Carica
          </label>
          <img *ngIf="form.get('step5')?.get('photo')?.valid" src="../../../assets/icons/success-icon.svg" />
          <div style="display: flex">
          <button type="button" class="button d-inline" style="margin-left: 25px;" (click)="openExamplePhoto()">
            <span class="spinner-border text-light" style="width: 16px;height: 16px;" *ngIf="showSpinnerExemple"></span>
            Vedi
            esempio</button>
          </div>
        </div>
      </div>
    </div>

    <div style="margin-top: 50px" *ngIf="formStep !== 5">
      <button type="submit" class="button" id="next-button" style="width: 175px"
        [disabled]="!form.get('step' + formStep.toString())?.valid" (click)="changeStep(true)">Successivo
        &rarr;</button>
      <p *ngIf="form.get('step2')?.errors?.['experience']" style="color: red; margin: 5px 0;">Gli anni di esperienza in
        una fascia di età non possono essere superiori agli anni di esperienza totali.</p>
      <p *ngIf="!form.get('step' + formStep.toString())?.valid && (form.get('step' + formStep.toString())?.touched || form.get('step' + formStep.toString())?.dirty)"
        style="color: red; margin: 5px 0;">Assicurati di aver compilato correttamente tutti i campi richiesti.</p>
      <p *ngIf="!form.get('step5')?.get('photo')?.value && form.get('step5')?.touched"
        style="color: red; margin: 5px 0;">
        Carica la foto prima di proseguire.</p>
    </div>

    <div class="submit-btn-container" *ngIf="formStep === 5">
      <div style="display: flex">
        <button type="submit" class="button" id="submit-button" [disabled]="!form.get('step5')?.valid"
          (click)="submitForm()">Invia</button>
        <div class="lds-ring" *ngIf="showSpinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>

      <p *ngIf="!form.get('step5')?.valid" style="color: red; margin: 5px 0;">Assicurati di aver compilato correttamente
        tutti i campi richiesti.</p>
      <p *ngIf="!form.get('step5')?.get('photo')?.value && form.get('step5')?.touched"
        style="color: red; margin: 5px 0;">
        Carica la foto prima di proseguire.</p>
    </div>
  </form>
</div>

<div class="modal" id="modal-container" *ngIf="showModal">
  <div class="modal-content">
    <app-image-cropper [imageSource]="sanitize(chosenPhoto)" (outputEvent)="getCroppedPhoto($event)">
    </app-image-cropper>
    <div class="modal-button-container">
      <button style="width: 120px" class="button" (click)="cropperAbort()">Annulla</button>
      <button style="width: 120px" class="button" (click)="uploadImage(finalPhoto)">Conferma</button>
    </div>
  </div>
</div>

<app-example-modal *ngIf="showExample" [exampleType]="exampleType" (closeModal)="closeExampleModal()">
</app-example-modal>

<app-cv-template [babysitter]="babysitter" [generatePages]="generatePages" [currentPage]="currentPage"
  (sendPages)="cvPages = $event" style="position:absolute; z-index: -5; left: -1000px;"></app-cv-template>

<app-modal-loader [openModal]="modalLoader" *ngIf="modalLoader"></app-modal-loader>
