<app-header-bar></app-header-bar>
<div class="limit-content mt-3" *ngIf="ad && !notCandidable">
  <h1>{{ ad?.title }}</h1>

  <ul *ngIf="ad">
    <li *ngIf="ad.startDate"><b>Data inizio</b>: {{ ad.startDate ?? '' | fhdate }}</li>
    <li *ngIf="!ad.startDate"><b>Data inizio</b>: Non disponibile</li>
    <li><b>Città</b>: {{ ad?.citta }} ({{ ad?.provincia }})</li>
    <li *ngIf="ad.zona"><b>Zona</b>: {{ ad.zona }}</li>
    <li *ngIf="!ad.zona"><b>Zona</b>: Non disponibile</li>
  </ul>

  <p [innerHTML]="ad?.descrizioneImpegno"></p>

  <form class="mt-5" [formGroup]="applyForm">
    <div class="row">
      <div class="col-md-6 mb-3">
        <label class="form-label" for="name">Nome *</label>
        <input id="name" class="form-control" type="text" placeholder="Il tuo nome*" maxlength="25"
          autocomplete="given-name" required formControlName="name" />
        <p class="form-error" *ngIf="applyForm.get('name')?.errors?.['required'] && applyForm.get('name')?.touched">Il
          campo è
          obbligatorio.</p>
      </div>

      <div class="col-md-6 mb-3">
        <label class="form-label" for="surname">Cognome *</label>
        <input id="surname" class="form-control" type="text" placeholder="Il tuo cognome*" maxlength="25"
          autocomplete="family-name" required formControlName="surname" />
        <p class="form-error"
          *ngIf="applyForm.get('surname')?.errors?.['required'] && applyForm.get('surname')?.touched">Il
          campo è
          obbligatorio.</p>
      </div>
      <div class="col-md-6 mb-3">
        <label class="form-label" for="email">Email *</label>
        <input id="email" class="form-control" type="email" placeholder="La tua e-mail*" required autocomplete="email"
          formControlName="email" />
        <p class="form-error" *ngIf="applyForm.get('email')?.errors?.['required'] && applyForm.get('email')?.touched">Il
          campo è
          obbligatorio.</p>
        <p class="form-error" *ngIf="applyForm.get('email')?.errors?.['email'] && applyForm.get('email')?.touched">
          L'indirizzo email non è nel formato corretto.</p>
      </div>

      <div class="col-md-6 mb-3">
        <label class="form-label" for="phone">Cellulare *</label>
        <input id="phone" class="form-control" type="tel" placeholder="Il tuo cellulare*" maxlength="15" required
          autocomplete="phone" formControlName="phone" />
        <p class="form-error" *ngIf="applyForm.get('phone')?.errors?.['required'] && applyForm.get('phone')?.touched">Il
          campo è
          obbligatorio.</p>
        <p class="form-error" *ngIf="applyForm.get('phone')?.errors?.['pattern'] && applyForm.get('phone')?.touched">
          Il numero di cellulare inserito non è valido.</p>
      </div>
      <div class="col-md-6 mb-3">
        <label class="form-label" style="text-transform: uppercase; font-size: 1.1em;">Sei certificata
          Family+Happy?</label>

        <div class="d-flex gap-3">
          <label class="form-check-label" for="certification-yes">Sì</label>
          <div class="form-check">
            <input id="certification-yes" class="form-check-input" type="radio" formControlName="certification"
              value="true" />
          </div>
          <label class="form-check-label" for="certification-no">No</label>
          <div class="form-check">
            <input id="certification-no" class="form-check-input" type="radio" formControlName="certification"
              value="false" />
          </div>
        </div>
        <p class="form-error"
          *ngIf="applyForm.get('certification')?.errors?.['required'] && applyForm.get('certification')?.touched">Il
          campo è
          obbligatorio.</p>
      </div>

      <div class="col-md-6 mb-3">
        <label class="form-label" for="provincia">Indica la tua provincia</label>
        <select class="form-select" id="provincia" required formControlName="provincia">
          <option value="TO">Torino</option>
          <option value="MI">Milano</option>
          <option value="RM">Roma</option>
          <option value="BO">Bologna</option>
          <option value="PD">Padova</option>
          <option value="GE">Genova</option>
          <option value="FI">Firenze</option>
          <option value="BS">Brescia</option>
          <option value="BG">Bergamo</option>
          <option value="MB">Monza</option>
          <option value="VR">Altre province</option>
        </select>
        <p class="form-error"
          *ngIf="applyForm.get('provincia')?.errors?.['required'] && applyForm.get('provincia')?.touched">Il
          campo è
          obbligatorio.</p>
      </div>

      <div class="col form-check">
        <p style="text-transform: uppercase; font-size: 1.1em;">Privacy</p>

        <div>
          <input class="form-check-input" type="checkbox" id="privacy" formControlName="privacy" required />
          <label class="form-check-label" for="privacy" style="text-transform: none;">Acconsento al trattamento dei dati
            in base alla <a href="https://familyhappybenefit.com/informativa-privacy-caregivers">Privacy Policy</a> per
            comunicazioni
            commerciali
            e informative su prodotti e servizi di Family+Happy.
          </label>
          <p class="form-error"
            *ngIf="applyForm.get('privacy')?.errors?.['required'] && applyForm.get('privacy')?.touched">Il
            campo è
            obbligatorio.</p>
        </div>
      </div>
    </div>

    <div class="d-grid">
      <button class="btn my-3 m-auto px-3 py-2" type="submit" (click)="submitForm()">Invia candidatura</button>
    </div>
  </form>
</div>

<div class="limit-content not-available" style="padding: 10%;" *ngIf="!ad">
  <h1>La selezione per questa proposta è stata completata.<br>
    Non mollare, ogni giorno pubblichiamo nuovi annunci interessanti.</h1>

  <div class="divider"></div>

  <div class="btn-container">
    <a routerLink="/bacheca">
      <button class="my-button" type="button">Torna agli annunci</button>
    </a>
  </div>
</div>

<div class="limit-content not-available" style="padding: 10%;" *ngIf="notCandidable">
  <h1>Ti sei già candidata per questa offerta di lavoro, ti comunicheremo l'esito non appena la selezione sarà chiusa
  </h1>

  <div class="divider"></div>

  <div class="btn-container">
    <a routerLink="/bacheca">
      <button class="my-button" type="button">Torna agli annunci</button>
    </a>
  </div>
</div>
<app-modal-loader [openModal]="modalLoader" *ngIf="modalLoader"></app-modal-loader>
<app-cookie-banner></app-cookie-banner>
