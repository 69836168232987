import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(private http: HttpClient) { }
  zapierURL = environment.zapierURL;

  sendSubData(data: Object) {
    return this.http.post(this.zapierURL+'/hooks/catch/6766214/bwp8w3g/', this.fromObjectToFormData(data));
  }

  sendBabysitterData(data: Object) {
    return this.http.post(this.zapierURL+'/hooks/catch/6766214/bwp8xb0/', this.fromObjectToFormData(data));
  }

  sendParentData(data: Object) {
    return this.http.post(this.zapierURL+'/hooks/catch/6766214/bwp8009/', this.fromObjectToFormData(data));
  }

  sendDisostruzioneTestData(data: Object) {
    return this.http.post(this.zapierURL+'/hooks/catch/6766214/bvs01js/', this.fromObjectToFormData(data));
  }

  private fromObjectToFormData(data: any) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    })
    return formData;
  }
}
