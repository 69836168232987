// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  baseURL: 'https://test.api.familyhappy.xyz',
  firebaseConfig: {
    apiKey: "AIzaSyAMZJjnDsr7szkJGeJShMljVYRLtCAnov0",
    authDomain: "family-happy-bs-test.firebaseapp.com",
    databaseURL: "https://family-happy-bs-test-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "family-happy-bs-test",
    storageBucket: "family-happy-bs-test.appspot.com",
    messagingSenderId: "402734596258",
    appId: "1:402734596258:web:375c7f8be67952bb89d7fb",
    measurementId: "G-DYL8EZ3QHF",
  },
  formBaseURL: 'https://babysitter-familyhappy-test.firebaseapp.com',
  zapierURL: '',
  googleMapsAPIKey: 'AIzaSyDj_xYGv-KHPfFiEP2FpLRRW3UoUCxQRUc',
  BACHECA_KEY: 'sk_test_bacheca_7fMGhIclohySFVpzdrvJ59600FH0ZSKcIwGfMCh1Bj9kRqDBPDvnwiAECwBFfQfwaL1zUu5FGtzHq3jhwIErW7awkFIvfQRYU0d1T83KPyo7NvraqSD09MYXmx4EQnuSqyyN8OayPhyyD0dRopbuLHMluiLkRLGB79K8JNQRcMUuXWyi8edglTjxyZear1VyZgS02WwNC9UPIdJURnLT3C7YS1mmeRPFASpzeswTvO3t3L4zIjEQwE0mdu2qGVctBB61JeDyKPP1cBsEcYZEleON2cPDq1abP03tnud1UF36qLTF1vVOw7V90xhdhCh6OgamL7fSga6PwVotmoEERQsyiayMLg7kzhIGk8BcHxSAwMv0Ybvdco2KqNBGp1XOBlUidOGwkDjl4004RyyvoDr43rnGv0kTnZbHn3yTPyyAQ18YbVhHTBcVwGp1wcGQBMd89EcqWxoSH2bGgkUg4D1E8A4BJsYDwc5uPmRlu1HC1jX5cDab0',
  STRIPE_KEY: 'pk_test_51I7kvnDvaLCRZq1qJusp6jUtyS0KKVRGzkI4E3Vs8ilF6YS8z7bkPHem0PKEFu1q35T645a8nAR2kVXNM3ovTzS300Z4iGA1qu',
  SECONDARY_STRIPE_KEY: 'pk_test_51Q5V1ZAcycus9CUGkdhT30PMQH6uNfyHszPrLBWVBsB1VdyYnGS7cMcn4Ysr8gVMGFE79kVOSXMCiAPHNUo37dGj00gg6ZasNS',
  bachecaURL: 'https://babysitter-familyhappy-test.web.app/',
  gaTrackingID: '',
  unicreditToken: 'https://a37nnszlq2awc4bqpsoizxd5ja0xidqt.lambda-url.eu-west-1.on.aws/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.


