import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CaregiverScore, CaregiverScoreType } from 'src/app/shared/models/caregiver-score.model';

@Injectable({
  providedIn: 'root'
})
export class CaregiverScoreService {
  private baseURL = environment.caregiverScoreURL;

  constructor(private http: HttpClient) { }

  getScore(docRef: string): Observable<CaregiverScore> {
    return this.http.get<CaregiverScore>(`${this.baseURL}/${docRef}/current-score`);
  }

  updateScore(docRef: string, score: number, type: CaregiverScoreType) {
    return this.http.patch(`${this.baseURL}/${docRef}/update-score?score=${score}&type=${type}`, {}, {
      headers: {
        'Accept': 'text/plain'
      },
      responseType: 'text'
    });
  }
}
