<div class="flex-container">
  <p class="fw-bold fs-18">{{ req.title }}</p>
  <ul>
    <li><b>Città</b>: {{ req.citta }} {{ req.provincia !== 'VR' ? '(' + req.provincia + ')' : '' }}</li>
    <li *ngIf="req.zona"><b>Zona</b>: {{ req.zona }}</li>
    <li *ngIf="!req.zona"><b>Zona</b>: Non disponibile</li>
    <li><b>Data inizio</b>: {{ req.startDate ?? '' | fhdate }}</li>
  </ul>

  <a routerLink="/richiesta/{{req.richiestaId}}"><button>CANDIDATI</button></a>
</div>
