import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BulletinBoardService } from 'src/app/services/bulletin-board-service/bulletin-board.service';
import { Ad } from 'npx-family-happy-common';
import { CommonModule, Location } from '@angular/common';
import { HeaderBarComponent } from 'src/app/shared/components/header-bar/header-bar.component';
import { RequestCardComponent } from 'src/app/shared/components/request-card/request-card.component';
import { CookieBannerComponent } from 'src/app/shared/components/cookie-banner/cookie-banner.component';

@Component({
  selector: 'app-bulletin-board',
  templateUrl: './bulletin-board.component.html',
  styleUrls: ['./bulletin-board.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule, HeaderBarComponent, RequestCardComponent, CookieBannerComponent]
})
export class BulletinBoardComponent implements OnInit {
  ads: Ad[] = [];
  filters = new UntypedFormGroup({
    provincia: new UntypedFormControl('TO'),
    role: new UntypedFormControl('BABYSITTER'),
  });
  page: number = 0;
  pageSize = 10;
  totalAds!: number;

  constructor(
    private boardService: BulletinBoardService,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.filters.valueChanges.subscribe((value) => {
      // Store filters value
      localStorage.setItem('bacheca_filters', JSON.stringify(value));

      // Reload Ads
      this.loadAds(value.provincia, value.role);
      const urlAttuale = this.location.path().split('?')[0];
      const nuovoUrl = `${urlAttuale}?province=${value.provincia}&role=${value.role}`;
      this.location.replaceState(nuovoUrl);
    });
    this.activatedRoute.queryParamMap.subscribe((param) => {
      const provincia = param.get('province');
      const ruolo = param.get('role');
      const storedFilters = localStorage.getItem('bacheca_filters');
      if (provincia && ruolo) {
        this.filters.setValue({ provincia: provincia, role: ruolo });
      } else if (storedFilters) {
        this.filters.setValue(JSON.parse(storedFilters));
      } else {
        this.filters.setValue({ provincia: 'TO', role: 'BABYSITTER' });
      }
    });
  }

  changePage(next: boolean) {
    if (next) {
      if (
        (this.page + 1) * 10 !== this.totalAds &&
        (this.page + 1) * 10 < this.totalAds
      ) {
        this.page++;
      }
    } else {
      if (this.page > 0) {
        this.page--;
      }
    }
    const filters = this.filters.value;
    this.loadAds(filters.provincia, filters.role);
  }

  private loadAds(province: string, category: string) {
    this.boardService.getAds(this.page, this.pageSize, province, category).subscribe((res) => {
      this.ads = res.content;
      //this.adPage = res;
      this.totalAds = res.totalElements;
    });
  }
}
