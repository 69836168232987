import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ActiveCampaignService {
  private baseURL = environment.requestServiceURL + '/v1/active-campaign';

  constructor(private http: HttpClient) { }

  addCaregiverToACList(email: string, listID: number) {
    const params = [
      `email=${email}`,
      `list=${listID}`
    ].join('&');

    return this.http.patch(`${this.baseURL}/contacts/addToList?${params}`, {});
  }
}
