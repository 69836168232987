import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { Babysitter, Colf, Nurse, Petsitter } from 'npx-family-happy-common';
import { combineLatest } from 'rxjs';
import { BabysitterService } from 'src/app/services/babysitter-service/babysitter.service';
import { ColfService } from 'src/app/services/colf-service/colf-service.service';
import { NurseService } from 'src/app/services/nurse-service/nurse.service';
import { PetsitterService } from 'src/app/services/petsitter-service/petsitter.service';
import { RequestService } from 'src/app/services/request-service/request.service';
import { HeaderBarComponent } from 'src/app/shared/components/header-bar/header-bar.component';

@Component({
  selector: 'app-thank-you-bacheca',
  templateUrl: './thank-you-bacheca.component.html',
  styleUrls: ['./thank-you-bacheca.component.scss'],
  standalone:true,
  imports:[CommonModule, HeaderBarComponent, RouterModule]
})
export class ThankYouBachecaComponent implements OnInit {
  hasCV: boolean = false;
  hasPayed: boolean = false;
  fromCV: boolean = false;
  needCVUpdate: boolean = false;
  caregiverName!: string;
  caregiverId!: string;
  requestID!: string;
  requestCategory!: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private babysitterService: BabysitterService,
    private petsitterService: PetsitterService,
    private nurseService: NurseService,
    private colfService: ColfService,
    private requestService: RequestService
  ) { }

  ngOnInit(): void {
    combineLatest({
      paramMap: this.activatedRoute.paramMap,
      queryParamMap: this.activatedRoute.queryParamMap
    }).subscribe((routeParams) => {
      console.log(routeParams);
      let caregiverEmail = routeParams.paramMap.get('email');
      this.requestID = routeParams.queryParamMap.get('req') ?? '';
      this.caregiverName = routeParams.queryParamMap.get('name') ?? '';
      let fromCV = routeParams.queryParamMap.get('fromCV') ?? '';
      this.requestCategory = routeParams.queryParamMap.get('category') ?? 'BABYSITTER';

      if (fromCV === 'true') {
        this.fromCV = true;
      }

      if (this.requestID) {
        this.requestService.getRequest(this.requestID).subscribe((req) => {
          if (req && req.length === 1) {
            this.requestCategory = req[0].requestPersonalInfo.requestCategory;
            if (caregiverEmail) {
              this.initFromCategory(caregiverEmail);
            }
          }
        })
      } else if (this.requestCategory) {
        if (caregiverEmail) {
          this.initFromCategory(caregiverEmail);
        }
      }
    });
  }

  getCategory(plural = false) {
    switch (this.requestCategory) {
      case 'PETSITTER':
        return 'petsitter';
      case 'COLF':
        return 'colf';
      case 'NURSE':
        if (plural)
          return 'badanti';
        else
          return 'badante';
      case 'BABYSITTER':
      default:
        return 'babysitter';
    }
  }

  private initFromCategory(caregiverEmail: string) {
    switch (this.requestCategory) {
      case 'PETSITTER':
        this.petsitterService.findPetsitterByEmail(caregiverEmail)
          .subscribe(res => this.checkCaregiverProfile(res))
        break;
      case 'NURSE':
        this.nurseService.findNurseByEmail(caregiverEmail)
          .subscribe(res => this.checkCaregiverProfile(res))
        break;
      case 'COLF':
        this.colfService.findColfByEmail(caregiverEmail)
          .subscribe(res => this.checkCaregiverProfile(res))
        break;
      case 'BABYSITTER':
      default:
        this.babysitterService.findBabysitterByEmail(caregiverEmail)
          .subscribe(res => this.checkCaregiverProfile(res))
        break;
    }
  }

  private checkCaregiverProfile(res: Petsitter[] | Babysitter[] | Nurse[] | Colf[]) {
    {
      if (res.length > 0) {
        if (res[0].info.description && res[0].info.description !== '') {
          const lastUpdate = new Date(res[0].updateTimestamp);
          /* if (res[0].updateTimestamp === null || lastUpdate.getTime() < 1671098822000) { // Timestamp da cui partire per le modifiche col CV dinamico
            this.needCVUpdate = true;
          } */
          this.hasCV = true;
        }
        this.caregiverId = res[0].docRef;
        this.caregiverName = res[0].info.name;
        this.hasPayed = this.requestCategory === 'COLF' || res[0].info.subscription === 'ABBONATO' || res[0].info.manualSubscription === 'ABBONATO';
      }
    }
  }
}
