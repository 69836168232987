import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Ad, Page, RequestCategory } from 'npx-family-happy-common';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BulletinBoardService {
  baseURL = environment.bulletinBoardServiceURL;
  zapierURL = environment.zapierURL;

  constructor(private http: HttpClient) {
    //this.getAdsFromOldDB();
  }

  getAdsFromOldDB() {
    const reqObject = {
      filters: [
        {
          field: 'string',
          conditionType: 'EQ',
          value: {},
        },
      ],
      sortData: [
        {
          field: 'startDate',
          sort: 'desc',
        },
      ],
      page: 0,
      nrElement: 10000,
    };
    this.http.post(`https://test.annunci.familyhappy.xyz/advancedSearch`, reqObject).subscribe((res: any) => {
      console.log('Result: ', res.data);
      const ads = res.data.map((ad: any) => ({
        richiestaId: ad.richiestaId,
        title: ad.title,
        provincia: ad.provincia,
        citta: ad.citta,
        zona: ad.zona,
        startDate: ad.startDate,
        endDate: ad.endDate,
        descrizioneImpegno: ad.descrizioneImpegno,
        createdAt: ad.createdAt,
        disabled: false,
        requestCategory: ad.requestCategory as RequestCategory
      }))
      console.log('New ads prepared:', ads);
      ads.forEach((ad: any) => {
        this.http.post(this.baseURL + '/create', ad).subscribe((res) => console.log('Ad created:', res));
      })
    });
  }

  getAds(page: number, pageSize: number, province: string, category: string) {
    const params = [
      `pageNumber=${page}`,
      `pageSize=${pageSize}`,
      `province=${province}`,
      `businessLine=${category}`
    ].join('&');
    return this.http.get<Page<Ad>>(`${this.baseURL}/advancedSearch?${params}`);
  }

  getAdById(id: number) {
    return this.http.get(this.baseURL + '/retrieve-by-id', { params: { idRichiesta: id } });
  }

  sendApplicationData(data: Object) {
    return this.http.post(this.zapierURL + '/hooks/catch/6766214/blz9ncx/', this.fromObjectToFormData(data));
  }

  private fromObjectToFormData(data: any) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    })
    return formData;
  }
}
