import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BabysitterService } from 'src/app/services/babysitter-service/babysitter.service';
import { BulletinBoardService } from 'src/app/services/bulletin-board-service/bulletin-board.service';
import { RequestService } from 'src/app/services/request-service/request.service';
import { Ad, Request } from 'npx-family-happy-common';
import { PetsitterService } from 'src/app/services/petsitter-service/petsitter.service';
import { NurseService } from 'src/app/services/nurse-service/nurse.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { HeaderBarComponent } from 'src/app/shared/components/header-bar/header-bar.component';
import { MyDatePipe } from 'src/app/date.pipe';
import { ModalLoaderComponent } from 'src/app/shared/components/modal-loader/modal-loader.component';
import { CookieBannerComponent } from 'src/app/shared/components/cookie-banner/cookie-banner.component';

@Component({
  selector: 'app-request-detail',
  templateUrl: './request-detail.component.html',
  styleUrls: ['./request-detail.component.scss'],
  standalone:true,
  imports:[CommonModule, HeaderBarComponent, MyDatePipe, ReactiveFormsModule, ModalLoaderComponent, CookieBannerComponent]
})
export class RequestDetailComponent implements OnInit {
  applyForm = new UntypedFormGroup({
    name: new UntypedFormControl(null, [Validators.required, Validators.maxLength(25)]),
    surname: new UntypedFormControl(null, [Validators.required, Validators.maxLength(25)]),
    email: new UntypedFormControl(null, [Validators.email, Validators.required]),
    phone: new UntypedFormControl(null, [Validators.required, Validators.maxLength(15)]),
    certification: new UntypedFormControl(null, Validators.required),
    provincia: new UntypedFormControl(null, Validators.required),
    privacy: new UntypedFormControl(false, Validators.requiredTrue)
  });

  ad!: Ad;
  requestID!: string;
  request!: Request;
  notCandidable!: boolean;
  isDangerous!: boolean;
  modalLoader = false

  constructor(
    private activatedRoute: ActivatedRoute,
    private boardService: BulletinBoardService,
    private requestService: RequestService,
    private router: Router,
    private babysitterService: BabysitterService,
    private petsitterService: PetsitterService,
    private nurseService: NurseService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.requestID = params.get('id') ?? '';

      if (this.requestID) {
        this.boardService.getAdById(+this.requestID).subscribe((req: any) => {
          this.ad = req;
        });
      }
    });

    this.requestService.getRequest(this.requestID).subscribe((req: any) => {
      this.request = req[0];
    })
  }

  submitForm() {
    this.modalLoader = true
    if (this.applyForm.valid) {
      const value = this.applyForm.value;
      let requestURL = window.location.href;

      let zapierData = {
        name: value.name,
        surname: value.surname,
        email: value.email,
        phone: value.phone,
        province: value.provincia,
        requestID: this.requestID,
        date: new Date()
      };

      switch (this.ad.requestCategory) {
        case 'PETSITTER':
          this.submitPetsitter(value, requestURL, zapierData);

          break;
        case 'NURSE':
          this.submitNurse(value, requestURL, zapierData);

          break;
        case 'BABYSITTER':
        default:
          this.submitBabysitter(value, requestURL, zapierData);
          break;
      }
    } else {
      this.applyForm.markAllAsTouched();
      this.modalLoader = false
      this.modalService.dismissAll()
    }
    // this.modalLoader = false
    // this.modalService.dismissAll()
  }

  private submitBabysitter(formData: any, requestURL: string, zapierData: any) {
    this.babysitterService.findBabysitterByEmail(formData.email).subscribe(data => {
      if (data.length > 0) {
        this.isDangerous = data[0].rating.dangerous ? true : false;
      }

      this.requestService.sendApplication(
        {
          name: formData.name,
          surname: formData.surname,
          email: formData.email,
          phone: formData.phone,
          provincia: formData.provincia,
          requestURL: requestURL.includes('?') ? requestURL.substring(0, requestURL.indexOf('?')) : requestURL
        }
      ).subscribe((res: any) => {
        this.router.navigate(['thank-you/' + formData.email], { queryParams: { name: formData.name, req: this.requestID } });
        this.modalLoader = false;
        this.modalService.dismissAll();
      });
    });
  }

  private submitPetsitter(formData: any, requestURL: string, zapierData: any) {
    this.petsitterService.findPetsitterByEmail(formData.email).subscribe(data => {
      if (data.length > 0) {
        this.isDangerous = data[0].rating.dangerous ? true : false;
      }

      this.requestService.sendApplication(
        {
          name: formData.name,
          surname: formData.surname,
          email: formData.email,
          phone: formData.phone,
          provincia: formData.provincia,
          requestURL: requestURL.includes('?') ? requestURL.substring(0, requestURL.indexOf('?')) : requestURL
        }
      ).subscribe((res: any) => {
        this.router.navigate(['thank-you/' + formData.email], { queryParams: { name: formData.name, req: this.requestID } });
        this.modalLoader = false;
        this.modalService.dismissAll();
      });
    });
  }

  private submitNurse(formData: any, requestURL: string, zapierData: any) {
    this.nurseService.findNurseByEmail(formData.email).subscribe(data => {
      if (data.length > 0) {
        this.isDangerous = data[0].rating.dangerous ? true : false;
      }

      this.requestService.sendApplication(
        {
          name: formData.name,
          surname: formData.surname,
          email: formData.email,
          phone: formData.phone,
          provincia: formData.provincia,
          requestURL: requestURL.includes('?') ? requestURL.substring(0, requestURL.indexOf('?')) : requestURL
        }
      ).subscribe((res: any) => {
        this.router.navigate(['thank-you/' + formData.email], { queryParams: { name: formData.name, req: this.requestID } });
        this.modalLoader = false;
        this.modalService.dismissAll();
      });
    });
  }
}
