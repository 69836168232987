import { trimSpaces } from './../../shared/utils/trim-spaces.util';
import { Babysitter, CertificateService, Mail, MailService, RequestUtilitiesService, CustomerType, CvTemplateComponent } from 'npx-family-happy-common';
import { Router, ActivatedRoute } from '@angular/router';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UntypedFormGroup, UntypedFormControl, Validators, UntypedFormArray, ReactiveFormsModule } from '@angular/forms';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from "firebase/storage";
import { BabysitterService } from 'src/app/services/babysitter-service/babysitter.service';
import { FormService } from 'src/app/services/form-service/form.service';
import { v4 as UUID } from 'uuid';
import { environment } from 'src/environments/environment';
import { createCheckboxesValidator } from 'src/app/shared/validators/checkboxes.directives';
import { Loader } from '@googlemaps/js-api-loader';
import { createAddressValidator } from 'src/app/shared/validators/address.directives';
import html2canvas from 'html2canvas';
import { createSpacesValidator } from 'src/app/shared/validators/spaces.directives';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleMapsService } from 'src/app/services/maps-service/google-maps.service';
import { CommonModule } from '@angular/common';
import { ImageCropperComponent } from 'src/app/shared/components/image-cropper/image-cropper.component';
import { ModalLoaderComponent } from 'src/app/shared/components/modal-loader/modal-loader.component';
import { ExampleModalComponent } from 'src/app/modals/example-modal/example-modal.component';

@Component({
  selector: 'app-babysitter-form',
  templateUrl: './babysitter-form.component.html',
  styleUrls: ['./babysitter-form.component.scss'],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ImageCropperComponent, ModalLoaderComponent, ExampleModalComponent, CvTemplateComponent]
})
export class BabysitterFormComponent implements OnInit, AfterViewInit {
  showModal = false;
  showExample = false;
  showSpinner = false;
  showSpinnerPhoto = false;
  showSpinnerExemple = false;
  modalLoader = false;
  exampleType!: string;
  chosenPhoto!: string;
  finalPhoto!: string;
  private oldAddress!: string | undefined

  form = new UntypedFormGroup({
    step1: new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required, createSpacesValidator()]),
      surname: new UntypedFormControl('', [Validators.required, createSpacesValidator()]),
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      phone: new UntypedFormControl(null, Validators.required),
      address: new UntypedFormControl('', Validators.required),
      city: new UntypedFormControl(''),
      cap: new UntypedFormControl(null),
      birthDate: new UntypedFormControl(undefined, Validators.required),
      provincia: new UntypedFormControl('TO', Validators.required)
    }),
    step2: new UntypedFormGroup({
      cluster1: new UntypedFormGroup({
        expYears: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(50)]),
        employer: new UntypedFormControl(''),
        tasks: new UntypedFormGroup({
          hygiene: new UntypedFormControl(false),
          sleep: new UntypedFormControl(false),
          food: new UntypedFormControl(false),
          weaning: new UntypedFormControl(false),
          foodSafety: new UntypedFormControl(false),
          other: new UntypedFormControl(false)
        }),
        otherTasks: new UntypedFormArray([
          new UntypedFormControl('')
        ])
      }),
      cluster2: new UntypedFormGroup({
        expYears: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(50)]),
        employer: new UntypedFormControl(''),
        tasks: new UntypedFormGroup({
          cry: new UntypedFormControl(false),
          crawling: new UntypedFormControl(false),
          montessori: new UntypedFormControl(false),
          language: new UntypedFormControl(false),
          foodSafety: new UntypedFormControl(false),
          motory: new UntypedFormControl(false),
          other: new UntypedFormControl(false)
        }),
        otherTasks: new UntypedFormArray([
          new UntypedFormControl('')
        ])
      }),
      cluster3: new UntypedFormGroup({
        expYears: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(50)]),
        employer: new UntypedFormControl(''),
        tasks: new UntypedFormGroup({
          sleep: new UntypedFormControl(false),
          illness: new UntypedFormControl(false),
          motoryActivities: new UntypedFormControl(false),
          emotionManagement: new UntypedFormControl(false),
          spannolinamento: new UntypedFormControl(false),
          creativeGames: new UntypedFormControl(false),
          other: new UntypedFormControl(false)
        }),
        gamesDesc: new UntypedFormArray([
          new UntypedFormControl('')
        ]),
        otherTasks: new UntypedFormArray([
          new UntypedFormControl('')
        ])
      }),
      cluster4: new UntypedFormGroup({
        expYears: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(50)]),
        employer: new UntypedFormControl(''),
        tasks: new UntypedFormGroup({
          homeworks: new UntypedFormControl(false),
          freeTime: new UntypedFormControl(false),
          extra: new UntypedFormControl(false),
          other: new UntypedFormControl(false)
        }),
        freeTimeDesc: new UntypedFormArray([
          new UntypedFormControl('')
        ]),
        otherTasks: new UntypedFormArray([
          new UntypedFormControl('')
        ])
      }),
      license: new UntypedFormControl(null, Validators.required),
      car: new UntypedFormControl(null, Validators.required),
      roles: new UntypedFormGroup({
        babysitter: new UntypedFormControl(false),
        taxisitter: new UntypedFormControl({ value: false, disabled: true }),
        taxisitterNoCar: new UntypedFormControl({ value: false, disabled: true }),
        walking: new UntypedFormControl(false),
        cleaning: new UntypedFormControl({ value: false, disabled: true }),
        petsitter: new UntypedFormControl(false),
        cooking: new UntypedFormControl({ value: false, disabled: true }),
        homework: new UntypedFormControl(false),
        relocation: new UntypedFormControl(false),
        coliving: new UntypedFormControl(false)
      }, createCheckboxesValidator(1)),
      homeworkExp: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(50)]),
      childAge1: new UntypedFormControl(0, [Validators.min(0), Validators.max(15)]),
      childAge2: new UntypedFormControl(0, [Validators.min(0), Validators.max(15)]),
      childAge3: new UntypedFormControl(0, [Validators.min(0), Validators.max(15)]),
      subjects: new UntypedFormGroup({
        math: new UntypedFormControl(false),
        italian: new UntypedFormControl(false),
        english: new UntypedFormControl(false),
        french: new UntypedFormControl(false),
        all: new UntypedFormControl(false),
        other: new UntypedFormControl(false),
        otherDesc: new UntypedFormControl('')
      }),
    }),
    step3: new UntypedFormGroup({
      parentSurname: new UntypedFormControl(''),
      parentSurname2: new UntypedFormControl('')
    }),
    step4: new UntypedFormGroup({
      certificates: new UntypedFormArray([
        new UntypedFormControl('')
      ]),
      title: new UntypedFormControl('', Validators.required),
      graduationType: new UntypedFormControl(''),
      english: new UntypedFormControl('', Validators.required),
      french: new UntypedFormControl('', Validators.required),
      italian: new UntypedFormControl('', Validators.required),
      german: new UntypedFormControl('', Validators.required),
      spanish: new UntypedFormControl('', Validators.required)
    }),
    step5: new UntypedFormGroup({
      smoker: new UntypedFormControl(null, Validators.required),
      daysAvail: new UntypedFormGroup({
        mon: new UntypedFormControl(false),
        tue: new UntypedFormControl(false),
        wed: new UntypedFormControl(false),
        thu: new UntypedFormControl(false),
        fri: new UntypedFormControl(false),
        sat: new UntypedFormControl(false),
        sun: new UntypedFormControl(false)
      }, createCheckboxesValidator(1)),
      hoursAvail: new UntypedFormGroup({
        mor: new UntypedFormControl(false),
        aft: new UntypedFormControl(false),
        eve: new UntypedFormControl(false)
      }, createCheckboxesValidator(1)),
      taskType: new UntypedFormGroup({
        occ: new UntypedFormControl(false),
        part: new UntypedFormControl(false),
        full: new UntypedFormControl(false)
      }, createCheckboxesValidator(1)),
      description: new UntypedFormControl('', [Validators.required, Validators.minLength(310), Validators.maxLength(426)]),
      photo: new UntypedFormControl('', Validators.required)
    })
  });
  formStep = 1;
  zapierData!: any;
  babysitterID!: string;
  babysitter!: Babysitter;
  generatePages: boolean = false;
  cvPages!: number;
  currentPage: number = 0;
  canvases: HTMLCanvasElement[] = [];
  isNew: boolean = true;
  descriptionLength: number = 0;
  hasPayed!: boolean;
  addressIsValid!: boolean;
  globalDocRef!: string

  availQuestions = [
    {
      question: "Lei ha una patente in corso di validità?",
      form: "license"
    },
    {
      question: "Lei è automunita?",
      form: "car"
    }
  ];

  otherQuestions = [
    {
      question: "Lei è fumatrice?",
      form: "smoker"
    },
  ];

  lastUpdateTimestamp!: Date;
  disostruzioneURL: string = '';

  constructor(private sanitizer: DomSanitizer, private babysitterService: BabysitterService, private formService: FormService,
    private router: Router, private route: ActivatedRoute, private certificateService: CertificateService, private mailService: MailService,
    private utilities: RequestUtilitiesService, private modalService: NgbModal, private googleMapsService: GoogleMapsService) {
    this.finalPhoto = '';
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.globalDocRef = params['id'];
      const docRef = params['id'];
      if (docRef) {
        this.babysitterService.getBabySitter(docRef).subscribe((bbs) => {
          let bb = bbs[0];
          this.babysitter = bb;
          this.oldAddress = bb.info.address
          this.initForm(bb);
          this.lastUpdateTimestamp = bb.updateTimestamp;
          this.isNew = false;
        })
      }
    });

    this.form.get('step5')?.get('description')?.valueChanges.subscribe(value => {
      this.descriptionLength = value.length;
    });

    //Conditionals form validators

    this.form.get('step2.totalExp')?.valueChanges.subscribe(value => {
      if (value) {
        this.form.get('step2.cluster1.expYears')?.setValidators([Validators.required, Validators.min(0), Validators.max(50)]);
        this.form.get('step2.cluster2.expYears')?.setValidators([Validators.required, Validators.min(0), Validators.max(50)]);
        this.form.get('step2.cluster3.expYears')?.setValidators([Validators.required, Validators.min(0), Validators.max(50)]);
        this.form.get('step2.cluster4.expYears')?.setValidators([Validators.required, Validators.min(0), Validators.max(50)]);
      } else {
        this.form.get('step2.cluster1.expYears')?.clearValidators();
        this.form.get('step2.cluster1.expYears')?.setValue(0);
        this.form.get('step2.cluster2.expYears')?.clearValidators();
        this.form.get('step2.cluster2.expYears')?.setValue(0);
        this.form.get('step2.cluster3.expYears')?.clearValidators();
        this.form.get('step2.cluster3.expYears')?.setValue(0);
        this.form.get('step2.cluster4.expYears')?.clearValidators();
        this.form.get('step2.cluster4.expYears')?.setValue(0);
      }
    });

    this.form.get('step2.cluster1.expYears')?.valueChanges.subscribe(value => {
      if (value) {
        this.form.get('step2.cluster1.employer')?.setValidators(Validators.required);
      } else {
        this.form.get('step2.cluster1.employer')?.clearValidators();
        this.form.get('step2.cluster1.employer')?.setValue('');
      }
    });

    this.form.get('step2.cluster1.tasks.other')?.valueChanges.subscribe(value => {
      if (!value) {
        this.resetFormArray(1, 'otherTasks');
      }
    });

    this.form.get('step2.cluster2.expYears')?.valueChanges.subscribe(value => {
      if (value) {
        this.form.get('step2.cluster2.employer')?.setValidators(Validators.required);
      } else {
        this.form.get('step2.cluster2.employer')?.clearValidators();
        this.form.get('step2.cluster2.employer')?.setValue('');
      }
    });

    this.form.get('step2.cluster2.tasks.other')?.valueChanges.subscribe(value => {
      if (!value) {
        this.resetFormArray(2, 'otherTasks');
      }
    });

    this.form.get('step2.cluster3.expYears')?.valueChanges.subscribe(value => {
      if (value) {
        this.form.get('step2.cluster3.employer')?.setValidators(Validators.required);
      } else {
        this.form.get('step2.cluster3.employer')?.clearValidators();
        this.form.get('step2.cluster3.employer')?.setValue('');
      }
    });

    this.form.get('step2.cluster3.tasks.creativeGames')?.valueChanges.subscribe(value => {
      if (!value) {
        this.resetFormArray(3, 'gamesDesc');
      }
    });

    this.form.get('step2.cluster3.tasks.other')?.valueChanges.subscribe(value => {
      if (!value) {
        this.resetFormArray(3, 'otherTasks');
      }
    });

    this.form.get('step2.cluster4.expYears')?.valueChanges.subscribe(value => {
      if (value) {
        this.form.get('step2.cluster4.employer')?.setValidators(Validators.required);
      } else {
        this.form.get('step2.cluster4.employer')?.clearValidators();
        this.form.get('step2.cluster4.employer')?.setValue('');
      }
    });

    this.form.get('step2.cluster4.tasks.freeTime')?.valueChanges.subscribe(value => {
      if (!value) {
        this.resetFormArray(4, 'freeTimeDesc');
      }
    });

    this.form.get('step2.cluster4.tasks.other')?.valueChanges.subscribe(value => {
      if (!value) {
        this.resetFormArray(4, 'otherTasks');
      }
    });

    this.form.get('step2.homeworkExp')?.valueChanges.subscribe(value => {
      if (value) {
        this.form.get('step2.childAge1')?.setValidators([Validators.required, Validators.min(1), Validators.max(15)]);
        this.form.get('step2.subjects')?.setValidators(createCheckboxesValidator(1));
      } else {
        this.form.get('step2.childAge1')?.clearValidators();
        this.form.get('step2.childAge1')?.setValue(0);
        this.form.get('step2.subjects')?.clearValidators();
        this.form.get('step2.subjects')?.reset({ emitEvent: false });
      }
    });

    this.form.get('step2.subjects.other')?.valueChanges.subscribe(value => {
      if (value) {
        this.form.get('step2.subjects.otherDesc')?.setValidators(Validators.required);
      } else {
        this.form.get('step2.subjects.otherDesc')?.clearValidators();
        this.form.get('step2.subjects.otherDesc')?.setValue('');
      }
    });

    this.form.get('step4')?.get('title')?.valueChanges.subscribe(value => {
      if (value === 'Laurea') {
        this.form.get('step4')?.get('graduationType')?.setValidators(Validators.required);
      } else {
        this.form.get('step4')?.get('graduationType')?.clearValidators();
        this.form.get('step4')?.get('graduationType')?.setValue('');
      }
    });

    /* Enable cooking and cleaning only when babysitter is checked */

    this.form.get('step2.roles.babysitter')?.valueChanges.subscribe(value => {
      if (!value) {
        this.form.get('step2.roles.cleaning')?.disable();
        this.form.get('step2.roles.cooking')?.disable();
        this.form.get('step2.roles.cleaning')?.setValue(false);
        this.form.get('step2.roles.cooking')?.setValue(false);
      } else {
        this.form.get('step2.roles.cleaning')?.enable();
        this.form.get('step2.roles.cooking')?.enable();
      }
    });

    /* Disable Taxisitter with car when Car field is false */

    this.form.get('step2.car')?.valueChanges.subscribe(value => {
      let license = this.form.get('step2.license')?.value;
      if (value === 'false') {
        this.form.get('step2.roles.taxisitter')?.disable({ emitEvent: false });
        this.form.get('step2.roles.taxisitter')?.setValue(false);
      } else {
        if (license === 'true') {
          this.form.get('step2.roles.taxisitter')?.enable({ emitEvent: false });
        }
      }
    });

    /* Disable Taxisitter fields (except walking) when License field is false */

    this.form.get('step2.license')?.valueChanges.subscribe(value => {
      let car = this.form.get('step2.car')?.value;
      if (value === 'false') {
        this.form.get('step2.roles.taxisitter')?.disable({ emitEvent: false });
        this.form.get('step2.roles.taxisitter')?.setValue(false);
        this.form.get('step2.roles.taxisitterNoCar')?.disable({ emitEvent: false });
        this.form.get('step2.roles.taxisitterNoCar')?.setValue(false);
      } else {
        if (car === 'true') {
          this.form.get('step2.roles.taxisitter')?.enable({ emitEvent: false });
        }
        this.form.get('step2.roles.taxisitterNoCar')?.enable({ emitEvent: false });
      }
    })
  }

  ngAfterViewInit(): void {
    /* Google Maps */
    let inputAddress = document.getElementById('address') as HTMLInputElement;
    const loader = new Loader({ apiKey: environment.googleMapsAPIKey, version: "weekly", libraries: ["places"], language: 'it-IT' }).load().then((google) => {
      /* const mapOptions = {
        fields: ["formatted_address", "geometry", "name"],
        strictBounds: false,
        types: ["establishment"],
      }; */

      const autocomplete = new google.maps.places.Autocomplete(inputAddress);

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        let addressParts: Array<string> = [];
        let formAddress = this.form.get('step1.address');
        let formCity = this.form.get('step1.city');
        let formCap = this.form.get('step1.cap');

        place.address_components?.forEach(component => {
          component.types.forEach(type => {
            addressParts.push(type);
          });

          // Compile other address fields according to place object
          if (component.types.includes('locality')) {
            formCity?.setValue(component.long_name);
          } else if (component.types.includes('administrative_area_level_3')) {
            formCity?.setValue(component.long_name);
          }
          if (component.types.includes('postal_code')) {
            formCap?.setValue(component.long_name);
          }
        });

        formAddress?.setValidators([Validators.required, createAddressValidator(addressParts)]);

        formAddress?.setValue(place.formatted_address);
      })
    });
  }

  uploadImage(image: string) {
    const file = this.base64ToBlob(image.replace('data:image/png;base64,', ''), "image/png");
    const path = 'pictures/' + UUID() + '.png';
    let storageRef = ref(getStorage(), path);
    const uploadTask = uploadBytesResumable(storageRef, file);
    uploadTask.on('state_changed', () => { }, () => { }, () => {
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL: any) => {
        console.log('File available at', downloadURL);
        this.form.get("step5")?.get("photo")?.setValue(downloadURL);
        alert('Foto caricata con successo!');
        this.showSpinnerPhoto = false;
      });
    })
    this.showModal = false;
  }

  base64ToBlob(base64Data: string, contentType: string) {
    contentType = contentType || '';
    let sliceSize = 1024;
    let byteCharacters = atob(base64Data);
    let bytesLength = byteCharacters.length;
    let slicesCount = Math.ceil(bytesLength / sliceSize);
    let byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
      let begin = sliceIndex * sliceSize;
      let end = Math.min(begin + sliceSize, bytesLength);

      let bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  getAge(date: string) {
    let today = new Date();
    let birthday = new Date(date);
    let age = today.getFullYear() - birthday.getFullYear();
    let month = today.getMonth() - birthday.getMonth();

    if (month < 0 || (month === 0 && today.getDate() < birthday.getDate())) {
      age--;
    }

    return age;
  }

  async processFormData(updateTimestamp?: Date) {
    this.zapierData = {
      ...this.form.get('step1')?.value,
      ...this.form.get('step2')?.value,
      ...this.form.get('step2')?.get('roles')?.value,
      ...this.form.get('step3')?.value,
      ...this.form.get('step4')?.value,
      ...this.form.get('step5')?.value,
      ...this.form.get('step5')?.get('daysAvail')?.value,
      ...this.form.get('step5')?.get('hoursAvail')?.value,
      ...this.form.get('step5')?.get('taskType')?.value
    };

    delete this.zapierData.daysAvail;
    delete this.zapierData.hoursAvail;
    delete this.zapierData.taskType;
    delete this.zapierData.roles;

    this.babysitterService.checkBabysitterCertification(this.form.get('step1.email')?.value).subscribe((res: boolean) => this.hasPayed = res);

    let cluster1Tasks: string[] = [];
    Object.entries(this.form.get("step2.cluster1.tasks")?.value).forEach(([key, value]) => {
      if (value) {
        cluster1Tasks.push(key);
      } else {
        return;
      }
    });

    let cluster2Tasks: string[] = [];
    Object.entries(this.form.get("step2.cluster2.tasks")?.value).forEach(([key, value]) => {
      if (value) {
        cluster2Tasks.push(key);
      } else {
        return;
      }
    });

    let cluster3Tasks: string[] = [];
    Object.entries(this.form.get("step2.cluster3.tasks")?.value).forEach(([key, value]) => {
      if (value) {
        cluster3Tasks.push(key);
      } else {
        return;
      }
    });

    let cluster4Tasks: string[] = [];
    Object.entries(this.form.get("step2.cluster4.tasks")?.value).forEach(([key, value]) => {
      if (value) {
        cluster4Tasks.push(key);
      } else {
        return;
      }
    });

    let clustersExp = [];
    clustersExp.push(this.form.get('step2.cluster1.expYears')?.value);
    clustersExp.push(this.form.get('step2.cluster2.expYears')?.value);
    clustersExp.push(this.form.get('step2.cluster3.expYears')?.value);
    clustersExp.push(this.form.get('step2.cluster4.expYears')?.value);
    let age = this.getAge(this.form.get('step1.birthDate')!.value);
    let expSum = clustersExp[0] + clustersExp[1] + clustersExp[2] + clustersExp[3];
    let totalExp = Math.max(...clustersExp, Math.min(expSum, (age - 16)));

    let babysitter: any = {
      docRef: this.globalDocRef,
      certificate: '',
      info: {
        name: this.form.get("step1")?.get("name")?.value,
        surname: this.form.get("step1")?.get("surname")?.value,
        email: this.form.get("step1")?.get("email")?.value,
        phone: this.form.get("step1")?.get("phone")?.value,
        address: this.form.get("step1.address")?.value,
        city: this.form.get("step1.city")?.value,
        zone: '',
        cap: this.form.get("step1.cap")?.value,
        age: this.form.get("step1")?.get("age")?.value,
        birthDate: this.form.get("step1")?.get("birthDate")?.value,
        provincia: this.form.get("step1")?.get("provincia")?.value,
        fiscalcode: '',
        resume: '',
        resumeHidden: '',
        video: '',
        notes: '',
        iban: '',
        description: this.form.get("step5")?.get("description")?.value,
        photo: this.form.get("step5")?.get("photo")?.value,
        subscription: this.hasPayed ? 'ABBONATO' : '',
        manualSubscription: this.babysitter ? this.babysitter.info.manualSubscription : '',
        expDate: this.babysitter ? this.babysitter.info.expDate : null,
        expDateManual: this.babysitter ? this.babysitter.info.expDateManual : null,
        stopWhatsapp: this.babysitter ? this.babysitter.info.stopWhatsapp : false,
        latitude: this.babysitter ? this.babysitter.info.latitude : 0,
        longitude: this.babysitter ? this.babysitter.info.longitude : 0
      },
      exp: {
        total: totalExp,
        cluster1: {
          cluster1Age: this.form.get("step2.cluster1.expYears")?.value,
          employer1: this.form.get("step2.cluster1.employer")?.value,
          tasks1: cluster1Tasks,
          extraTasks1: this.form.get("step2.cluster1.otherTasks")?.value ?? null
        },
        cluster2: {
          cluster2Age: this.form.get("step2.cluster2.expYears")?.value,
          employer2: this.form.get("step2.cluster2.employer")?.value,
          tasks2: cluster2Tasks,
          extraTasks2: this.form.get("step2.cluster2.otherTasks")?.value ?? null
        },
        cluster3: {
          cluster3Age: this.form.get("step2.cluster3.expYears")?.value,
          employer3: this.form.get("step2.cluster3.employer")?.value,
          tasks3: cluster3Tasks,
          extraTasks3: this.form.get("step2.cluster3.otherTasks")?.value ?? null,
          plays3: this.form.get("step2.cluster3.gamesDesc")?.value ?? null
        },
        cluster4: {
          cluster4Age: this.form.get("step2.cluster4.expYears")?.value,
          employer4: this.form.get("step2.cluster4.employer")?.value,
          tasks4: cluster4Tasks,
          extraTasks4: this.form.get("step2.cluster4.otherTasks")?.value ?? null,
          freeTime4: this.form.get("step2.cluster4.freeTimeDesc")?.value ?? null
        },
        experienceHomework: {
          expYears: this.form.get('step2.homeworkExp')?.value,
          firstChildAge: this.form.get('step2.childAge1')?.value,
          secondChildAge: this.form.get('step2.childAge2')?.value,
          thirdChildAge: this.form.get('step2.childAge3')?.value,
          subjects: {
            math: this.form.get('step2.subjects.math')?.value,
            ita: this.form.get('step2.subjects.italian')?.value,
            eng: this.form.get('step2.subjects.english')?.value,
            fr: this.form.get('step2.subjects.french')?.value,
            homeworks_all: this.form.get('step2.subjects.all')?.value,
            homeworks_other: this.form.get('step2.subjects.other')?.value,
            otherDesc: this.form.get('step2.subjects.otherDesc')?.value
          }
        }
      },
      reference: {
        f_surname: this.form.get("step3")?.get("parentSurname")?.value ?? '',
        f_second_surname: this.form.get("step3.parentSurname2")?.value ?? ''
      },
      lang: {
        title: this.form.get("step4")?.get("title")?.value,
        graduationType: this.form.get("step4.graduationType")?.value,
        specialities: {
          autistic: false,
          dislessia: false,
          doula: false,
        },
        english: this.form.get("step4")?.get("english")?.value,
        french: this.form.get("step4")?.get("french")?.value,
        italian: this.form.get("step4")?.get("italian")?.value,
        german: this.form.get("step4")?.get("german")?.value,
        spanish: this.form.get("step4.spanish")?.value
      },
      avail: {
        babysitter: this.form.get('step2.roles.babysitter')?.value,
        homework: this.form.get("step2.roles.homework")?.value,
        cleaning: this.form.get("step2.roles.cleaning")?.value,
        cooking: this.form.get("step2.roles.cooking")?.value,
        taxi: this.form.get("step2.roles.taxisitter")?.value,
        taxiNoCar: this.form.get("step2.roles.taxisitterNoCar")?.value,
        walking: this.form.get("step2.roles.walking")?.value,
        petSitter: this.form.get("step2.roles.petsitter")?.value,
        coliving: this.form.get("step2.roles.coliving")?.value,
        relocation: this.form.get("step2.roles.relocation")?.value,
        licence: this.form.get("step2")?.get("license")?.value === 'true' ? true : false,
        car: this.form.get("step2")?.get("car")?.value === 'true' ? true : false,
        smoker: this.form.get("step5")?.get("smoker")?.value === 'true' ? true : false,
        days: this.babysitterService.fromAvailObjectToString(this.form.get("step5")?.get("daysAvail")?.value, 'days'),
        hours: this.babysitterService.fromAvailObjectToString(this.form.get("step5")?.get("hoursAvail")?.value, 'hours'),
        contract: this.babysitterService.fromAvailObjectToString(this.form.get("step5")?.get("taskType")?.value, 'contract')
      },
      rating: {
        overall: this.babysitter ? this.babysitter.rating.overall : 0,
        punctuality: this.babysitter ? this.babysitter.rating.punctuality : 0,
        empathy: this.babysitter ? this.babysitter.rating.empathy : 0,
        behave: this.babysitter ? this.babysitter.rating.behave : 0,
        communication: this.babysitter ? this.babysitter.rating.communication : 0,
        dangerous: this.babysitter ? this.babysitter.rating.dangerous : false,
        attitude: this.babysitter ? this.babysitter.rating.attitude : '',
        notRated: this.babysitter ? this.babysitter.rating.notRated : false,
      },
      engagement: {
        selected: this.babysitter ? this.babysitter.engagement.selected : 0,
        chosen: this.babysitter ? this.babysitter.engagement.chosen : 0,
        discarded: this.babysitter ? this.babysitter.engagement.discarded : 0,
        ignored: this.babysitter ? this.babysitter.engagement.ignored : 0,
        substituted: this.babysitter ? this.babysitter.engagement.substituted : 0,
        applications: this.babysitter ? this.babysitter.engagement.applications : 0,
        chosenDate: this.babysitter ? this.babysitter.engagement.chosenDate : null,
        latestApplication: this.babysitter ? this.babysitter.engagement.latestApplication : null
      },
      // certificates: {
      //   disostruzione: false,
      //   disostruzioneCertURL: this.disostruzioneURL,
      //   emotiva: true,
      //   others: this.form.get('step4.certificates')?.value[0] ? this.form.get('step4.certificates')?.value : []
      // },
      certificates: null,
      updateTimestamp: updateTimestamp ?? this.lastUpdateTimestamp
    };

    //calculate coordinates from address
    if (this.oldAddress !== babysitter.info.address) {
      let coordinates = await this.getCoordiantes(babysitter)
      babysitter = {
        ...babysitter,
        info: {
          ...babysitter.info,
          latitude: coordinates.latitude,
          longitude: coordinates.longitude
        }
      }

    } else {
      babysitter = {
        ...babysitter,
        info: {
          ...babysitter.info,
          latitude: babysitter.info.latitude,
          longitude: babysitter.info.longitude
        }
      }
    }

    this.babysitter = babysitter;

    return babysitter;
  }

  async submitForm() {
    this.modalLoader = true
    this.babysitter = await this.processFormData(new Date());
    this.babysitterService.createBabySitter(this.babysitter).subscribe((res) => {
      console.log('Submit completion: ', res);
      if (this.hasPayed || this.babysitter.info.manualSubscription === 'ABBONATO') {
        this.generateCV();
        this.modalLoader = false
        this.modalService.dismissAll()
      } else {
        if (this.babysitter.rating.attitude === '') {
          this.router.navigate(['/test-psico-attitudinale'], { queryParams: { id: this.babysitterID } });
        } else {
          this.router.navigate(['thank-you/' + this.babysitter.info.email], { queryParams: { fromCV: true, name: this.babysitter.info.name } });
        }
        this.modalLoader = false
        this.modalService.dismissAll()
      }
    });
  }

  async submitFormStep() {
    this.babysitter = await this.processFormData();
    this.babysitterService.createBabySitter(this.babysitter).subscribe((res: any) => {
      this.babysitterID = res.message.match(/[\w-]+$/)[0];
      this.globalDocRef = this.babysitterID
    });
  }

  openModal(event: any) {
    this.showSpinnerPhoto = true;
    let image = event.target.files[0];
    this.chosenPhoto = URL.createObjectURL(image);
    this.showModal = true;
  }

  abortSelection(event: any) {
    this.showSpinnerPhoto = false;
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  dismiss(event: Event) {
    const elem = event.target as HTMLElement;
    if (elem === document.getElementById('modal-container')) {
      this.showModal = false;
    }
  }

  getCroppedPhoto(image: string) {
    this.finalPhoto = image;
  }

  cropperAbort() {
    let imageInput = <HTMLInputElement>document.getElementById("image-selection");
    imageInput.value = "";
    this.showModal = false;
    this.showSpinnerPhoto = false
  }

  changeStep(next: boolean) {
    if (next === true) {
      this.formStep++
      this.submitFormStep();
    } else {
      this.formStep--
    }
  }

  touchButton() {
    this.form.get('step5')?.get('photo')?.markAsTouched({ onlySelf: true });
    this.showSpinnerPhoto = true
  }

  sendZapierData() {
    return this.formService.sendSubData(this.zapierData);
  }

  initForm(bb: Babysitter) {
    this.form.setValue({
      step1: {
        name: trimSpaces(bb.info.name),
        surname: trimSpaces(bb.info.surname),
        email: bb.info.email,
        phone: bb.info.phone,
        address: bb.info.address,
        city: bb.info.city,
        cap: bb.info.cap,
        birthDate: bb.info.birthDate,
        provincia: bb.info.provincia
      },
      step2: {
        cluster1: {
          expYears: bb.exp.cluster1.cluster1Age,
          employer: bb.exp.cluster1.employer1,
          tasks: this.processTasks(bb, 1),
          otherTasks: ['']
        },
        cluster2: {
          expYears: bb.exp.cluster2.cluster2Age,
          employer: bb.exp.cluster2.employer2,
          tasks: this.processTasks(bb, 2),
          otherTasks: ['']
        },
        cluster3: {
          expYears: bb.exp.cluster3.cluster3Age,
          employer: bb.exp.cluster3.employer3,
          tasks: this.processTasks(bb, 3),
          gamesDesc: [''],
          otherTasks: ['']
        },
        cluster4: {
          expYears: bb.exp.cluster4.cluster4Age,
          employer: bb.exp.cluster4.employer4,
          tasks: this.processTasks(bb, 4),
          freeTimeDesc: [''],
          otherTasks: ['']
        },
        roles: {
          babysitter: bb.avail.babysitter,
          taxisitter: bb.avail.taxi,
          taxisitterNoCar: bb.avail.taxiNoCar,
          walking: bb.avail.walking,
          cleaning: bb.avail.cleaning,
          petsitter: bb.avail.petSitter,
          cooking: bb.avail.cooking,
          homework: bb.avail.homework,
          relocation: bb.avail.relocation,
          coliving: bb.avail.coliving
        },
        homeworkExp: bb.exp.experienceHomework ? bb.exp.experienceHomework.expYears : 0,
        childAge1: bb.exp.experienceHomework ? bb.exp.experienceHomework.firstChildAge : 0,
        childAge2: bb.exp.experienceHomework ? bb.exp.experienceHomework.secondChildAge : 0,
        childAge3: bb.exp.experienceHomework ? bb.exp.experienceHomework.thirdChildAge : 0,
        subjects: {
          math: bb.exp.experienceHomework && bb.exp.experienceHomework.subjects ? bb.exp.experienceHomework.subjects.math : false,
          italian: bb.exp.experienceHomework && bb.exp.experienceHomework.subjects ? bb.exp.experienceHomework.subjects.ita : false,
          english: bb.exp.experienceHomework && bb.exp.experienceHomework.subjects ? bb.exp.experienceHomework.subjects.eng : false,
          french: bb.exp.experienceHomework && bb.exp.experienceHomework.subjects ? bb.exp.experienceHomework.subjects.fr : false,
          all: bb.exp.experienceHomework && bb.exp.experienceHomework.subjects ? bb.exp.experienceHomework.subjects.homeworks_all : false,
          other: bb.exp.experienceHomework && bb.exp.experienceHomework.subjects ? bb.exp.experienceHomework.subjects.homeworks_other : '',
          otherDesc: bb.exp.experienceHomework && bb.exp.experienceHomework.subjects ? bb.exp.experienceHomework.subjects.otherDesc : ''
        },
        license: bb.avail.licence ? 'true' : 'false',
        car: bb.avail.car ? 'true' : 'false'
      },
      step3: {
        parentSurname: bb.reference ? bb.reference.f_surname : '',
        parentSurname2: bb.reference ? bb.reference.f_second_surname : ''
      },
      step4: {
        certificates: [''],
        title: bb.lang.title,
        graduationType: bb.lang.graduationType,
        english: bb.lang.english,
        french: bb.lang.french,
        italian: bb.lang.italian,
        german: bb.lang.german,
        spanish: bb.lang.spanish
      },
      step5: {
        smoker: bb.avail.smoker ? 'true' : 'false',
        daysAvail: this.babysitterService.fromStringToAvailObject(bb.avail.days, 'days'),
        hoursAvail: this.babysitterService.fromStringToAvailObject(bb.avail.hours, 'hours'),
        taskType: this.babysitterService.fromStringToAvailObject(bb.avail.contract, 'contract'),
        description: bb.info.description,
        photo: bb.info.photo
      }
    }, { emitEvent: false })
    this.setFormArrayValue(1, 'otherTasks', bb.exp.cluster1.extraTasks1);
    this.setFormArrayValue(2, 'otherTasks', bb.exp.cluster2.extraTasks2);
    this.setFormArrayValue(3, 'gamesDesc', bb.exp.cluster3.plays3);
    this.setFormArrayValue(3, 'otherTasks', bb.exp.cluster3.extraTasks3);
    this.setFormArrayValue(4, 'freeTimeDesc', bb.exp.cluster4.freeTime4);
    this.setFormArrayValue(4, 'otherTasks', bb.exp.cluster4.extraTasks4);
    this.setCertificatesLenght(bb.certificates.others);

    this.disostruzioneURL = bb.certificates.disostruzioneCertURL ?? '';
  }

  processTasks(babysitter: Babysitter, cluster: number) {
    switch (cluster) {
      case 1:
        let cluster1 = babysitter.exp.cluster1.tasks1;

        return {
          hygiene: cluster1.includes('hygiene'),
          sleep: cluster1.includes('sleep'),
          food: cluster1.includes('food'),
          weaning: cluster1.includes('weaning'),
          foodSafety: cluster1.includes('foodSafety'),
          other: cluster1.includes('other')
        }

      case 2:
        let cluster2 = babysitter.exp.cluster2.tasks2;

        return {
          cry: cluster2.includes('cry'),
          crawling: cluster2.includes('crawling'),
          montessori: cluster2.includes('montessori'),
          language: cluster2.includes('language'),
          foodSafety: cluster2.includes('foodSafety'),
          motory: cluster2.includes('motory'),
          other: cluster2.includes('other')
        }

      case 3:
        let cluster3 = babysitter.exp.cluster3.tasks3;

        return {
          sleep: cluster3.includes('sleep'),
          illness: cluster3.includes('illness'),
          motoryActivities: cluster3.includes('motoryActivities'),
          emotionManagement: cluster3.includes('emotionManagement'),
          spannolinamento: cluster3.includes('spannolinamento'),
          creativeGames: cluster3.includes('creativeGames'),
          other: cluster3.includes('other')
        }

      case 4:
        let cluster4 = babysitter.exp.cluster4.tasks4;

        return {
          homeworks: cluster4.includes('homeworks'),
          freeTime: cluster4.includes('freeTime'),
          extra: cluster4.includes('extra'),
          other: cluster4.includes('other')
        }

      default:
        return;
    }
  }

  openExampleCV() {
    this.showSpinner = true;
    this.exampleType = 'CV';
    this.showExample = true;
  }

  openExamplePhoto() {
    this.exampleType = 'Photo';
    this.showExample = true;
    this.showSpinnerExemple = true;
  }

  closeExampleModal() {
    this.showExample = false;
    this.showSpinner = false;
    this.showSpinnerExemple = false;
  }

  /* Experience form arrays methods */

  changeFormArrayLength(more: boolean, cluster: number, field: string) {
    let array: UntypedFormArray = this.form.get(`step2.cluster${cluster}.${field}`) as UntypedFormArray;
    if (more) {
      array.push(new UntypedFormControl('', Validators.required));
    } else {
      array.removeAt(array.length - 1);
    }
  }

  setFormArrayValue(cluster: number, field: string, data?: string[]) {
    let array: UntypedFormArray = this.form.get(`step2.cluster${cluster}.${field}`) as UntypedFormArray;

    if (data && data.length > 0) {
      array.clear();
      data!.forEach(data => array.push(new UntypedFormControl(data)));
    }
  }

  resetFormArray(cluster: number, field: string) {
    let array: UntypedFormArray = this.form.get(`step2.cluster${cluster}.${field}`) as UntypedFormArray;

    array.clear();
  }

  getFormArrayControls(cluster: number, field: string) {
    let array: UntypedFormArray = this.form.get(`step2.cluster${cluster}.${field}`) as UntypedFormArray;

    return array.controls;
  }

  /* Certificates form arrays methods */

  changeCertificatesLength(more: boolean) {
    let array: UntypedFormArray = this.form.get('step4.certificates') as UntypedFormArray;

    if (more) {
      array.push(new UntypedFormControl(''));
    } else {
      array.removeAt(array.length - 1);
    }
  }

  setCertificatesLenght(data?: string[]) {
    let array: UntypedFormArray = this.form.get('step4.certificates') as UntypedFormArray;

    if (data && data.length > 0) {
      array.clear();
      data!.forEach(data => array.push(new UntypedFormControl(data)));
    }
  }

  resetCertificates() {
    let array: UntypedFormArray = this.form.get('step4.certificates') as UntypedFormArray;

    array.clear();
  }

  getCertificatesFormControls() {
    let array: UntypedFormArray = this.form.get('step4.certificates') as UntypedFormArray;

    return array.controls;
  }

  /* CV generation methods */

  async generateCV() {
    // this.modalLoader = true
    this.generatePages = true;
    setTimeout(() => { this.htmlToCanvas() }, 1000);
  }

  htmlToCanvas() {
    if (this.currentPage >= this.cvPages) {
      this.currentPage = 0;
      this.generatePages = false;

      this.babysitterService.getBabySitter(this.babysitterID).subscribe(babysitters => {
        let babysitter = babysitters[0];
        let babysitterEmail = environment.production ? babysitter.info.email : 'drugle91xbox@gmail.com'; // Cambiamento temporaneo indirizzo email di test
        let senderEmail = this.utilities.getMailService(CustomerType.B2C, environment.production);
        let message = `<p>Car* ${babysitter.info.name},<br>
        hai completato con successo il tuo CV che trovi in allegato e che puoi scaricare.<br>
        Ti consigliamo di tenerlo sempre aggiornato per aver più successo nella ricerca del lavoro.<br>
        Puoi sempre aggiornarlo o modificarlo cliccando <a href="${environment.formBaseURL}/babysitter-form?id=${babysitter.docRef}">QUI</a>.</p>
        <p>Ti ricordiamo che riceverai aggiornamenti ad ogni tua candidatura.</p>
        <p>A presto e in bocca al lupo,<br>
        Il Team Di Family+Happy</p>`;
        let cv = {
          path: babysitter.info.resumeHidden,
          filename: `${babysitter.info.name} ${babysitter.info.surname} - Family+Happy CV.pdf`
        };

        let mail = new Mail(senderEmail, babysitterEmail, 'Il tuo CV Family+Happy è pronto', message, [cv]);

        this.mailService.sendCustom(environment.mailServiceURL, mail).subscribe({
          next: () => {
            console.log('Email sent.');
            if (this.babysitter.rating.attitude === '') {
              this.router.navigate(['/test-psico-attitudinale'], { queryParams: { id: this.babysitterID } });
            } else {
              this.router.navigate(['thank-you/' + this.babysitter.info.email], { queryParams: { fromCV: true, name: this.babysitter.info.name } });
            }
          },
          error: () => {
            console.error('Mail service error');
            if (this.babysitter.rating.attitude === '') {
              this.router.navigate(['/test-psico-attitudinale'], { queryParams: { id: this.babysitterID } });
            } else {
              this.router.navigate(['thank-you/' + this.babysitter.info.email], { queryParams: { fromCV: true, name: this.babysitter.info.name } });
            }
          }
        })
      });
      return;
    }

    const element = document.getElementById('cv-page-container') as HTMLElement;
    html2canvas(element, { allowTaint: true, useCORS: true }).then(async canvas => {
      this.canvases.push(canvas);
      if (this.currentPage === this.cvPages - 1) {
        await this.certificateService.createPDFCVFromHTML(this.canvases, this.babysitter, this.isNew, false,
          null, environment.babysitterServiceURL + '/v1/babysitters', environment.requestServiceURL + '/v1/requests');
        this.canvases = [];
      }
      this.currentPage++;
      setTimeout(() => { this.htmlToCanvas() }, 1000);
    })
    this.modalLoader = false;
    this.modalService.dismissAll()
  }

  //SCRIPT --- CONVERT ADDRESSES INTO COORDINATES
  private async getCoordiantes(bb: Babysitter) {
    let res = await this.googleMapsService.getCoordinates(bb.info.address).then((res) => {
      const lat = res[0].geometry.location.lat();
      const lng = res[0].geometry.location.lng();
      const coordinates = {
        latitude: lat,
        longitude: lng
      }
      return coordinates
    })
    return res
  }
}
