import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function createCheckboxesValidator(minRequired: number): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    const checkboxes = group.value;
    let checkboxValues: boolean[] = Object.values(checkboxes);
    let checkboxTrue = 0;

    checkboxValues.forEach(value => {
      if (value === true) {
        checkboxTrue++;
      }
    });

    return checkboxTrue < minRequired ? { checkboxes: true } : null;
  }
}