<app-page-message [title]="title">
  <div *ngIf="isPaymentSuccessful">
    <p>Il pagamento è andato a buon fine. Verrà ricontattato nei tempi stabiliti dal Suo Family Tutor con i riferimenti
      del profilo su misura per la sua esigenza.
    </p>
    <p>Ricordiamo che il Suo Family Tutor è sempre a disposizione per urgenze o per darle aggiornamenti sullo stato di
      avanzamento della sua richiesta. Può contattarlo telefonicamente al numero diretto già in suo possesso o in
      alternativa all'indirizzo email <a href="mailto:servizioclienti@familyhappy.it">servizioclienti&#64;familyhappy.it</a>.</p>
  </div>
  <div *ngIf="!isPaymentSuccessful">
    <p><b>Attenzione</b>: Si è verificato un errore nel pagamento.</p>
    <div *ngIf="errorMessage !== ''">
      <p>L'errore è il seguente:</p>
      <p class="text-danger">{{errorMessage}}</p>
    </div>
    <p>Per tornare alla pagina di pagamento e riprovare, clicca <span
        class="text-decoration-underline text-primary pointer" (click)="goBack()">qui</span>.</p>
  </div>
</app-page-message>
