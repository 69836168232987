<app-header-bar></app-header-bar>
<div class="grey-gradient">
  <div class="limit-content welcome-container">
    <div style="place-self: center">
      <p>Benvenut* sulla <b>bacheca di annunci di Family+Happy</b>, qui troverai le nostre proposte di lavoro e potrai
        candidarti per l'offerta che preferisci.</p>
    </div>
  </div>

  <div class="pink-box"></div>
</div>

<div class="limit-content d-flex gap-3" [formGroup]="filters">
  <div class="filter-container">
    <label for="filter-provincia">Seleziona una provincia:</label>
    <select id="filter-provincia" formControlName="provincia">
      <option value="TO">Torino</option>
      <option value="MI">Milano</option>
      <option value="RM">Roma</option>
      <option value="BO">Bologna</option>
      <option value="PD">Padova</option>
      <option value="GE">Genova</option>
      <option value="FI">Firenze</option>
      <option value="BS">Brescia</option>
      <option value="BG">Bergamo</option>
      <option value="MB">Monza</option>
      <option value="VR">Altre province</option>
    </select>
  </div>
  <div class="filter-container">
    <label for="filter-provincia">Seleziona un ruolo:</label>
    <select id="filter-provincia" formControlName="role">
      <option value="BABYSITTER">Babysitter</option>
      <option value="PETSITTER">Petsitter</option>
      <option value="NURSE">Badanti</option>
      <option value="COLF">Colf</option>
    </select>
  </div>
</div>

<div class="limit-content">
  <div class="requests-container">
    <app-request-card *ngFor="let ad of ads" [req]="ad"></app-request-card>
    <p *ngIf="ads.length === 0" class="list-error-msg">Non vi sono annunci per la provincia e il ruolo selezionati.</p>
  </div>

  <div class="btn-container">
    <button type="button" (click)="changePage(false)" [ngClass]="{'disabled': page === 0}" [disabled]="page === 0">Indietro</button>
    <button type="button" (click)="changePage(true)" [disabled]="((page + 1) * 10) >= totalAds"
      [ngClass]="{'disabled': ((page + 1) * 10) >= totalAds}">Avanti</button>
  </div>
</div>

<app-cookie-banner></app-cookie-banner>
