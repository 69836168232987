import { Injectable, OnInit } from '@angular/core';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleMapsService implements OnInit {

  private geocoderService!: google.maps.Geocoder;
  private distanceMatrixService!: google.maps.DistanceMatrixService;

  constructor() {

    //LOADER FOR MAPS API-----
    const loader = new Loader({ apiKey: environment.googleMapsAPIKey, version: "weekly", libraries: ["places"], language: 'it-IT' }).load().then((google) => {
      this.geocoderService = new google.maps.Geocoder();
      this.distanceMatrixService = new google.maps.DistanceMatrixService();
    })
  }
  ngOnInit(): void {
  }

  getDistance(
    origin: string,
    destination: string
  ): Promise<google.maps.DistanceMatrixResponse | null> {
    return new Promise((resolve, reject) => {
      this.distanceMatrixService.getDistanceMatrix(
        {
          origins: [origin],
          destinations: [destination],
          travelMode: google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
          if (status === google.maps.DistanceMatrixStatus.OK) {
            resolve(response);
          } else {
            reject(status);
          }
        }
      );
    });
  }

  getCoordinates(address: string): Promise<google.maps.GeocoderResult[]> {
    return new Promise((resolve, reject) => {
      this.geocoderService.geocode({ 'address': address }, (result, status) => {
        if (status === "OK" && result) {
          resolve(result);
        } else {
          reject(status)
        }
      })
    })
  }
}
