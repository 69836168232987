<div style="margin: 50px auto;" class="limit-content">
  <div class="title-container">
    <h1>WEBINAR CORSO DISOSTRUZIONE PEDIATRICA</h1>

    <p>Rispondi correttamente almeno all'80% delle domande. Superato il test ti invieremo nel corso di 10 minuti
      l'attestato alla tua mail.</p>
  </div>

  <form [formGroup]="personalInfo">
    <div class="personal-info">
      <div>
        <label for="name">NOME</label>
        <input id="name" type="text" formControlName="name" autocomplete="name" required/>
        <p class="form-error-message" *ngIf="personalInfo.touched && personalInfo.get('name')?.errors">Questo campo è obbligatorio</p>
      </div>

      <div>
        <label for="surname">COGNOME</label>
        <input id="surname" type="text" formControlName="surname" autocomplete="surname" required />
        <p class="form-error-message" *ngIf="personalInfo.touched && personalInfo.get('surname')?.errors">Questo campo è obbligatorio</p>
      </div>

      <div>
        <label for="email">EMAIL</label>
        <input id="email" type="text" formControlName="email" autocomplete="email" required/>
        <p class="form-error-message" *ngIf="personalInfo.get('email')?.errors">Indica l'indirizzo email di iscrizione a Family+Happy</p>
      </div>
    </div>
  </form>

  <form [formGroup]="form" class="main-form">
    <div class="blue-box"></div>
    <div class="grid-container">
      <div>
        <span>Cosa accade in un'ostruzione parziale?</span>
        <div class="radio-list">
          <label for="1-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('1')?.value === 'A')}">
              <input style="display: none" type="radio" id="1-a" formControlName="1" name="1" value="A" />
              Il soggetto non emette più suoni
            </div>
          </label>

          <label for="1-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('1')?.value === 'B')}">
              <input style="display: none" type="radio" id="1-b" formControlName="1" name="1" value="B" />
              Il soggetto ancora emette suoni
            </div>
          </label>

          <label for="1-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('1')?.value === 'C')}">
              <input style="display: none" type="radio" id="1-c" formControlName="1" name="1" value="C" />
              Il soggetto va in arresto cardiaco
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>Quando inizio la Manovra di Heimlich?</span>
        <div class="radio-list">
          <label for="2-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('2')?.value === 'A')}">
              <input style="display: none" type="radio" id="2-a" formControlName="2" name="2" value="A" />
              Quando il soggetto perde coscienza
            </div>
          </label>

          <label for="2-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('2')?.value === 'B')}">
              <input style="display: none" type="radio" id="2-b" formControlName="2" name="2" value="B" />
              In caso di ostruzione totale
            </div>
          </label>

          <label for="2-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('2')?.value === 'C')}">
              <input style="display: none" type="radio" id="2-c" formControlName="2" name="2" value="C" />
              In caso di ostruzione parziale
            </div>
          </label>

          <label for="2-d">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('2')?.value === 'D')}">
              <input style="display: none" type="radio" id="2-d" formControlName="2" name="2" value="D" />
              Nessuna delle precedenti
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>La Manovra di Heimlich si usa solo su?</span>
        <div class="radio-list">
          <label for="3-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('3')?.value === 'A')}">
              <input style="display: none" type="radio" id="3-a" formControlName="3" name="3" value="A" />
              Su adulto, bambino ed infante in ostruzione totale
            </div>
          </label>

          <label for="3-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('3')?.value === 'B')}">
              <input style="display: none" type="radio" id="3-b" formControlName="3" name="3" value="B" />
              Su adulto e bambino in ostruzione totale
            </div>
          </label>

          <label for="3-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('3')?.value === 'C')}">
              <input style="display: none" type="radio" id="3-c" formControlName="3" name="3" value="C" />
              Sull'infante in ostruzione totale
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>Qual è la differenza tra la trachea dei bambini e quella degli adulti?</span>
        <div class="radio-list">
          <label for="4-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('4')?.value === 'A')}">
              <input style="display: none" type="radio" id="4-a" formControlName="4" name="4" value="A" />
              Non c'è nessuna differenza
            </div>
          </label>

          <label for="4-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('4')?.value === 'B')}">
              <input style="display: none" type="radio" id="4-b" formControlName="4" name="4" value="B" />
              È solo più piccola
            </div>
          </label>

          <label for="4-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('4')?.value === 'C')}">
              <input style="display: none" type="radio" id="4-c" formControlName="4" name="4" value="C" />
              Quella dei bambini è conica, quella degli adulti è cilindrica
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>Qual è la forma di oggetti e cibi più pericolosa per il soffocamento?</span>
        <div class="radio-list">
          <label for="5-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('5')?.value === 'A')}">
              <input style="display: none" type="radio" id="5-a" formControlName="5" name="5" value="A" />
              Tonda
            </div>
          </label>

          <label for="5-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('5')?.value === 'B')}">
              <input style="display: none" type="radio" id="5-b" formControlName="5" name="5" value="B" />
              Quadrata
            </div>
          </label>

          <label for="5-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('5')?.value === 'C')}">
              <input style="display: none" type="radio" id="5-c" formControlName="5" name="5" value="C" />
              Triangolare
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>Quale tra queste situazioni può aumentare il rischio di soffocamento?</span>
        <div class="radio-list">
          <label for="6-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('6')?.value === 'A')}">
              <input style="display: none" type="radio" id="6-a" formControlName="6" name="6" value="A" />
              Contemporaneità tra mangiare e svolgere altre attività
            </div>
          </label>

          <label for="6-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('6')?.value === 'B')}">
              <input style="display: none" type="radio" id="6-b" formControlName="6" name="6" value="B" />
              Riempire la bocca di cibo
            </div>
          </label>

          <label for="6-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('6')?.value === 'C')}">
              <input style="display: none" type="radio" id="6-c" formControlName="6" name="6" value="C" />
              Entrambe le risposte precedenti
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>Cosa devo fare in caso di un lattante o un bambino che soffoca?</span>
        <div class="radio-list">
          <label for="7-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('7')?.value === 'A')}">
              <input style="display: none" type="radio" id="7-a" formControlName="7" name="7" value="A" />
              Dargli un bicchiere d'acqua
            </div>
          </label>

          <label for="7-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('7')?.value === 'B')}">
              <input style="display: none" type="radio" id="7-b" formControlName="7" name="7" value="B" />
              Allertare il 112/118 e, se so come si fanno, le manovre di disostruzione
            </div>
          </label>

          <label for="7-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('7')?.value === 'C')}">
              <input style="display: none" type="radio" id="7-c" formControlName="7" name="7" value="C" />
              Nulla
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>In genere, qual è la forma più pericolosa che può provocare un'ostruzione totale?</span>
        <div class="radio-list">
          <label for="8-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('8')?.value === 'A')}">
              <input style="display: none" type="radio" id="8-a" formControlName="8" name="8" value="A" />
              La forma tonda
            </div>
          </label>

          <label for="8-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('8')?.value === 'B')}">
              <input style="display: none" type="radio" id="8-b" formControlName="8" name="8" value="B" />
              La forma quadrata
            </div>
          </label>

          <label for="8-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('8')?.value === 'C')}">
              <input style="display: none" type="radio" id="8-c" formControlName="8" name="8" value="C" />
              Qualsiasi forma è pericolosa
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>Quante pacche e quante compression su un infante in ostruzione totale?</span>
        <div class="radio-list">
          <label for="9-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('9')?.value === 'A')}">
              <input style="display: none" type="radio" id="9-a" formControlName="9" name="9" value="A" />
              2 pacche e 2 compressioni
            </div>
          </label>

          <label for="9-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('9')?.value === 'B')}">
              <input style="display: none" type="radio" id="9-b" formControlName="9" name="9" value="B" />
              4 pacche e 4 compressioni
            </div>
          </label>

          <label for="9-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('9')?.value === 'C')}">
              <input style="display: none" type="radio" id="9-c" formControlName="9" name="9" value="C" />
              5 pacche e 5 compressioni fino a quando non si disostruisce
            </div>
          </label>

          <label for="9-d">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('9')?.value === 'D')}">
              <input style="display: none" type="radio" id="9-d" formControlName="9" name="9" value="D" />
              Continuare solo con le pacche
            </div>
          </label>
        </div>
      </div>
    </div>

    <div class="submit-btn-container">
      <button type="submit" class="button" [disabled]="!form.valid || !personalInfo.valid" (click)="submitForm()">Invia</button>
    </div>
    <div class="pink-box"></div>
  </form>
</div>
<app-modal-loader [openModal]="modalLoader" *ngIf="modalLoader"></app-modal-loader>
