import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import jsPDF from 'jspdf';
import { Mail, Babysitter, MailService, Nurse, Petsitter } from 'npx-family-happy-common';
import { environment } from 'src/environments/environment';
import { BabysitterService } from '../babysitter-service/babysitter.service';
import { CaregiverScoreService } from '../caregiver-score-service/caregiver-score.service';
import { CaregiverScoreType } from 'src/app/shared/models/caregiver-score.model';
import { NurseService } from '../nurse-service/nurse.service';
import { PetsitterService } from '../petsitter-service/petsitter.service';
import html2canvas from 'html2canvas';
import { concatMap } from 'rxjs';

const EMAIL_TEST = 'colucci.ivan95@gmail.com'; // Cambiamento temporaneo indirizzo email di test

@Injectable({
  providedIn: 'root'
})
export class CertificateService {
  regular = '';

  constructor(
    private http: HttpClient,
    private storage: AngularFireStorage,
    private mailService: MailService,
    private babysitterService: BabysitterService,
    private nurseService: NurseService,
    private petsitterService: PetsitterService,
    private caregiverScoreService: CaregiverScoreService
  ) {
    this.http.get('../../../assets/regular.txt', { responseType: 'text' }).subscribe(data => {
      this.regular = data;
    })
  }

  createPDFCertificate(babysitter: Babysitter, senderEmail: string) {
    let name = babysitter.info.name;
    let babysitterEmail = environment.production ? babysitter.info.email : EMAIL_TEST;
    let fullName = name + ' ' + babysitter.info.surname;
    let fileName = name + babysitter.info.surname + '_disostruzione.pdf';
    let message = `<p>Car* ${name},<br> Complimenti! Hai superato il test sul corso di Disostruzione Pediatrica.<br> Trovi in allegato l'attestato rilasciato dall'ente
                    di certificazione Tiziano Formazione.</p> <p>A presto,<br> Il Team di Family+Happy</p>`;
    let doc = new jsPDF({ orientation: 'l', unit: 'px', format: [1113, 791] });
    doc.addImage('../../../assets/img/Attestato.jpg', 'JPG', 0, 0, 1113, 791);
    doc.addFileToVFS('JosefinSansRegular.ttf', this.regular);
    doc.addFont('JosefinSansRegular.ttf', 'JosefinSans', 'regular');
    doc.setFont('JosefinSans', 'regular');
    doc.setFontSize(40);
    doc.text(fullName, 160, 365);
    doc.setFontSize(25);
    doc.text(new Date().toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' }), 835, 560);
    const blob = doc.output('blob');
    return this.storage.upload(fileName, blob).then((task) => {
      task.task.on('state_changed', () => { }, () => { }, () => {
        this.storage.ref(fileName).getDownloadURL().subscribe((url: string) => {
          babysitter.certificates.disostruzioneCertURL = url;
          babysitter.certificates.disostruzione = true;

          // Aggiorna Babysitter e punteggio
          this.babysitterService.updateBabySitter(babysitter).pipe(
            concatMap(() =>
              this.caregiverScoreService.getScore(babysitter.docRef)
            ),
            concatMap((score) =>
              this.caregiverScoreService.updateScore(
                babysitter.docRef,
                score.disostuzioneCertified + 2,
                CaregiverScoreType.DISOSTRUZIONE_CERTIFIED
              )
            )
          ).subscribe({
            next: () => {
              console.log('Punteggio disostruzione aggiornato correttamente');
              let attachment = [
                {
                  path: url,
                  filename: fileName
                }
              ];

              let mail = new Mail(senderEmail, babysitterEmail, 'Esito test su Corso di Disostruzione Pediatrica', message, attachment);
              this.mailService.sendCustom(environment.baseURL, mail).subscribe(() => {
                window.location.href = 'https://babysitter.familyhappy.it/thank-you-disostruzione';
              });
            },
            error: (err) => console.error('Errore durante l\'aggiornamento:', err)
          });
        });
      })
    })
  }

  async createNurseCertificatePDF(nurse: Nurse, senderEmail: string) {
    let name = nurse.info.name;
    let babysitterEmail = environment.production ? nurse.info.email : EMAIL_TEST;
    let fullName = name + ' ' + nurse.info.surname;
    let fileName = nurse.docRef + '_nurse_certificate.pdf';
    let message = `<p>Complimenti,<br>
    Hai svolto con successo i tre step di formazione e certificazione di Family+Happy, che ti permettono di ricevere l’attestato di referenza utilizzabile per le tue opportunità di lavoro, a vita.<br>
    In allegato il tuo attestato.<br>
    Buon lavoro!<br>
    Il team di Family+Happy<br></p>`;
    // Generate nurse certificate
    (document.getElementById('certificate-name') as HTMLElement).innerText = fullName;
    const certificate = document.getElementById('nurse-certificate') as HTMLElement;
    const canvas = await html2canvas(certificate, { allowTaint: true, useCORS: true })
    let doc = new jsPDF({ orientation: 'l', unit: 'px', format: [1113, 791] });
    doc.addImage(canvas, 'JPEG', 0, 0, 1113, 791, undefined, 'SLOW');
    const blob = doc.output('blob');

    return this.storage.upload(fileName, blob).then((task) => {
      task.task.on('state_changed', () => { }, () => { }, () => {
        this.storage.ref(fileName).getDownloadURL().subscribe((url: string) => {
          nurse.certificate = url;

          // Aggiorna Nurse e punteggio
          this.nurseService.updateNurseFromForm(nurse).pipe(
            concatMap(() =>
              this.caregiverScoreService.getScore(nurse.docRef)
            ),
            concatMap((score) =>
              this.caregiverScoreService.updateScore(
                nurse.docRef,
                score.disostuzioneCertified + 2,
                CaregiverScoreType.DISOSTRUZIONE_CERTIFIED
              )
            )
          ).subscribe({
            next: () => {
              console.log('Punteggio disostruzione aggiornato correttamente');
              let attachment = [
                {
                  path: url,
                  filename: fileName
                }
              ];

              let mail = new Mail(senderEmail, babysitterEmail, `Esito test del Corso di Sicurezza dell'Anziano`, message, attachment);
              this.mailService.sendCustom(environment.baseURL, mail).subscribe(() => {
                window.location.href = 'https://babysitter.familyhappy.it/thank-you-disostruzione';
              });
            },
            error: (err) => console.error('Errore durante l\'aggiornamento:', err)
          });
        });
      })
    })
  }

  async createPetsitterCertificatePDF(petsitter: Petsitter, senderEmail: string) {
    let name = petsitter.info.name;
    let petsitterEmail = environment.production ? petsitter.info.email : EMAIL_TEST;
    let fullName = name + ' ' + petsitter.info.surname;
    let fileName = petsitter.docRef + '_pet_certificate.pdf';
    let message = `<p>Complimenti,<br>Hai svolto con successo i tre step di formazione e certificazione di Family+Happy, che ti permettono di ricevere
                  l’attestato di referenza utilizzabile per le tue opportunità di lavoro, a vita.</p><p>In allegato il tuo attestato.</p>
                  <p>Buon lavoro!</p><p>Il Team di Family+Happy</p>`;

    // Generate petsitter certificate
    (document.getElementById('certificate-name') as HTMLElement).innerText = fullName;
    const certificate = document.getElementById('pet-certificate') as HTMLElement;
    const canvas = await html2canvas(certificate, { allowTaint: true, useCORS: true })
    let doc = new jsPDF({ orientation: 'l', unit: 'px', format: [1113, 791] });
    doc.addImage(canvas, 'JPEG', 0, 0, 1113, 791, undefined, 'SLOW');
    const blob = doc.output('blob');

    return this.storage.upload(fileName, blob).then((task) => {
      task.task.on('state_changed', () => { }, () => { }, () => {
        this.storage.ref(fileName).getDownloadURL().subscribe((url: string) => {
          petsitter.certificate = url;
          // Aggiorna Nurse e punteggio
          this.petsitterService.updatePetsitterFromForm(petsitter).pipe(
            concatMap(() =>
              this.caregiverScoreService.getScore(petsitter.docRef)
            ),
            concatMap((score) =>
              this.caregiverScoreService.updateScore(
                petsitter.docRef,
                score.disostuzioneCertified + 2,
                CaregiverScoreType.DISOSTRUZIONE_CERTIFIED
              )
            )
          ).subscribe({
            next: () => {
              console.log('Punteggio disostruzione aggiornato correttamente');
              let attachment = [
                {
                  path: url,
                  filename: fileName
                }
              ];

              let mail = new Mail(senderEmail, petsitterEmail, `COMPLIMENTI! IN ALLEGATO L’ATTESTATO FAMILY+HAPPY`, message, attachment);
              this.mailService.sendCustom(environment.baseURL, mail).subscribe(() => {
                window.location.href = 'https://babysitter.familyhappy.it/thank-you-disostruzione';
              });
            },
            error: (err) => console.error('Errore durante l\'aggiornamento:', err)
          });
        });
      })
    })
  }
}
