<div class="">
  <header>
    <div class="d-flex justify-content-center mb-5 mt-5">
      <a href="#">
        <img class="logo" src="https://familyhappybenefit.com/wp-content/uploads/2023/11/family-happy-logo.svg"
          alt="family happy logo.svg">
      </a>
    </div>
  </header>

  <main class="content containerCustom">
    <div class="row">
      <div class="instructions col-12 col-md-12 col-xl-6 col-xxl-6">
        <div class="mb-3 pt-5">
          <h2 class="px-md-5">Come utente Unicredit, hai diritto al 50% di sconto su tutti i servizi di Family+Happy</h2>
        </div>
        <div class="mb-3 mt-5">
          <!-- <h2>Come utente Unicredit, hai diritto al 50% di sconto su tutti i servizi di Family+Happy</h2> -->
          <h3 class="px-md-5">Entro 3 ore dall’invio della richiesta, verrai
            ricontattato al telefono da un consulente
            che ti darà tutte le informazioni che desideri e ti
            seguirà nella selezione.</h3>
        </div>
        <div>
          <!-- <p>I servizi di Family+Happy sono dedicati ai dipendenti di Unicredit: ogni mese, per ciascun
            dipendente, sono state messe a disposizione 15 ore di servizi ad un costo di 7€/h*.</p> -->
          <!-- <ul class="ps-3 pb-1 m-0">
            <li>
              <p class="m-0">Consulenze GRATUITE ILLIMITATE con un Family Tutor che ti guiderà sulla ricerca e ti supporterà in modo dedicato*</p>
            </li>
            <li>
              <p class="m-0">L’accesso tramite il Family Tutor a un database di 52.000 profili certificati con il protocollo di sicurezza CertifiCARE</p>
            </li>
            <li>
              <p class="m-0">Colloqui ad hoc per una selezione su misura alle sue specifiche esigenze</p>
            </li>
            <li>
              <p class="m-0">Prima di ogni Pagamento, la possibilità di effettuare una video intervista gratuita con il Caregiver selezionato</p>
            </li>
            <li>
              <p class="m-0">Possibilità di sostituzione GRATUITA per il primo mese</p>
            </li>
            <li>
              <p class="m-0">La definizione della remunerazione oraria al caregiver (mediamente compresa tra e 8€/ora  alle  11€/ora netti)</p>
            </li>
            <li>
              <p class="m-0">Sconto 50% per il servizio di Selezione e certificazione della propria caregiver su misura (babysitter, badante , petsitter e aiuto compiti)</p>
            </li>
          </ul>
          <p class="footnote">*Il family tutor sarà disponibile dal Lunedì al Venerdì dalle ore 9.00 alle ore 18.00, mediante un contatto diretto Telefonico, in
            videoconferenza o via whatsapp e darà supporto alla famiglia per l’individuazione della soluzione più adeguata alle proprie esigenze</p> -->
        </div>
      </div>
      <div class="form col-12 col-md-12 col-xl-6 col-xxl-6 d-flex justify-content-center px-md-5 py-md-3">
        <form [formGroup]="registrationForm" class="registration-form">
          <div class="form-row col-12 d-flex justify-content-between">
            <div class="form-group">
              <label for="nome">NOME *</label>
              <input type="text" class="w-100" formControlName="nome" id="nome" required>
              <div *ngIf="registrationForm.get('nome')?.invalid && registrationForm.get('nome')?.touched"
                class="form-error">
                *Questo campo è obbligatorio.
              </div>
            </div>
            <div class="form-group">
              <label for="cognome">COGNOME *</label>
              <input type="text" class="w-100" formControlName="cognome" id="cognome" required>
              <div *ngIf="registrationForm.get('cognome')?.invalid && registrationForm.get('cognome')?.touched"
                class="form-error">
                *Questo campo è obbligatorio.
              </div>
            </div>
          </div>
          <div class="form-row col-12 d-flex justify-content-between">
            <div class="form-group">
              <label for="telefono">NUMERO DI TELEFONO *</label>
              <input class="w-100" type="tel" formControlName="telefono" id="telefono" required>
              <div *ngIf="registrationForm.get('telefono')?.invalid && registrationForm.get('telefono')?.touched"
                class="form-error">
                *Questo campo è obbligatorio.
              </div>
            </div>
            <div class="form-group">
              <label for="email">E-MAIL PERSONALE *</label>
              <input class="w-100" type="email" formControlName="email" id="email" required>
              <div *ngIf="registrationForm.get('email')?.invalid && registrationForm.get('email')?.touched"
                class="form-error">
                *Questo campo è obbligatorio.
              </div>
            </div>
          </div>
          <div class="form-row col-12 d-flex justify-content-between">
            <div class="form-group">
              <label for="citta">CITTÀ *</label>
              <input class="w-100" type="text" formControlName="citta" id="citta" required>
              <div *ngIf="registrationForm.get('citta')?.invalid && registrationForm.get('citta')?.touched"
                class="form-error">
                *Questo campo è obbligatorio.
              </div>
            </div>
            <div class="form-group">
              <label for="provincia">PROVINCIA *</label>
              <input class="w-100" type="text" formControlName="provincia" id="provincia" required>
              <div *ngIf="registrationForm.get('provincia')?.invalid && registrationForm.get('provincia')?.touched"
                class="form-error">
                *Questo campo è obbligatorio.
              </div>
            </div>
          </div>
          <div class="form-row col-12 d-flex">
            <div class="w-100">
              <label for="codiceFiscale">CODICE FISCALE *</label>
              <input type="text" class="w-100" id="codiceFiscale" formControlName="codiceFiscale">
              <p class="form-error"
                *ngIf="registrationForm.get('codiceFiscale')?.errors?.['required'] && registrationForm.get('codiceFiscale')?.touched">
                *Questo campo è obbligatorio.</p>
              <p class="form-error"
                *ngIf="registrationForm.get('codiceFiscale')?.errors?.['pattern'] && registrationForm.get('codiceFiscale')?.touched">
                *Il codice fiscale inserito non è valido.</p>
            </div>
          </div>
          <div class="form-row col-12 d-flex mt-3">
            <div class="w-100">
              <label for="servizio">QUAL È IL SERVIZIO DI CUI HAI BISOGNO? *</label>
              <select class="w-100" formControlName="servizio" id="servizio" required>
                <option value="babysitter-diurna">Babysitter diurna</option>
                <option value="babysitter-notturna">Babysitter notturna</option>
                <option value="taxi-sitter">Taxi-sitter</option>
                <option value="aiuto-compiti">Aiuto compiti</option>
                <option value="badante-diurna">Badante diurna</option>
                <option value="badante-convivente">Badante convivente</option>
                <option value="badante-occasionale">Badante occasionale</option>
                <option value="petsitter">Pet-sitter</option>
                <!-- Aggiungi altre opzioni qui -->
              </select>
              <div *ngIf="registrationForm.get('servizio')?.invalid && registrationForm.get('servizio')?.touched"
                class="form-error">
                *Questo campo è obbligatorio.
              </div>
            </div>
          </div>
          <div class="form-row col-12 d-flex mt-3">
            <div class="w-100">
              <label for="contactMode" class="contact">COME VUOI ESSERE RICONTATTATO? *</label>
              <select class="w-100" formControlName="contactMode" id="contactMode" required>
                <option>Telefono</option>
                <option>E-mail</option>
                <option>WhatsApp</option>
                <!-- Aggiungi altre opzioni qui -->
              </select>
              <div *ngIf="registrationForm.get('contactMode')?.invalid && registrationForm.get('contactMode')?.touched"
                class="form-error">
                *Questo campo è obbligatorio.
              </div>
            </div>
          </div>
          <div class="w-90 mt-3">
            <div class="d-flex flex-column">
              <span class="">
                <!-- <input type="checkbox" id="privacy" required> -->
                <label for="privacy"><input type="checkbox" formControlName="privacy" id="privacy" required> Acconsento
                  al trattamento dei dati in
                  base alla <a href="https://familyhappybenefit.com/informativa-privacy" target="_blank">Privacy Policy</a> per
                  comunicazioni su prodotti e servizi di Family+Happy.</label>
                <div *ngIf="registrationForm.get('privacy')?.invalid && registrationForm.get('privacy')?.touched"
                  class="form-error">
                  Prima di proseguire, bisogna accettare la Privacy Policy.
                </div>
              </span>
              <span class="">
                <!-- <input type="checkbox" id="privacy" required> -->
                <label for="terms"><input type="checkbox" formControlName="terms" id="terms" required> Accetto i <a href="https://familyhappybenefit.com/informativa-privacy" target="_blank">Termini e le Condizioni del servizio</a>.</label>
                <div *ngIf="registrationForm.get('terms')?.invalid && registrationForm.get('terms')?.touched"
                  class="form-error">
                  Prima di proseguire, bisogna accettare i Termini e Condizioni del servizio.
                </div>
              </span>
            </div>
          </div>
          <button type="submit" (click)="sendForm()" class="submit-btn my-3">INVIA LA TUA RICHIESTA</button>
          <div *ngIf="registrationForm.invalid && formSubmitted"
            class="form-error">
            Attenzione, sembra che tu non abbia compilato tutti i campi correttamente.
          </div>
        </form>
      </div>
    </div>
  </main>

  <!-- <div>
    <section class="help-section spacing">
      <div class="background-overlay"></div>
      <div class="help-content">
        <div class="">
          <img class="responsive-image-help" src="https://familyhappybenefit.com/wp-content/uploads/2023/11/smiling-brunette-woman-with-long-hair-in-white-car-2022-09-29-22-26-05-utc-scaled-1.jpg" alt="">
        </div>
        <div class="help-title-container">
          <div class="help-content-title">
            <h2>Hai bisogno di una mano nella gestione di figli, anziani o animali domestici? Desideri un aiuto sicuro e
              vuoi trovarlo in breve tempo?</h2>
          </div>
          <div class="help-content-text">
            <p>Unicredit ha stretto un accordo con Family+Happy, realtà premiata in Italia nella
              certificazione di babysitter, petsitter e badanti.</p>
              <button class="submit-btn">SCOPRI IL SERVIZIO</button>
          </div>
        </div>
        <div class="wrapper">
          <div class="help-content-text-responsive">
            <p>Unicredit ha stretto un accordo con Family+Happy, realtà premiata in Italia nella
              certificazione di babysitter, petsitter e badanti e aiuto commissioni.</p>
              <button class="submit-btn">SCOPRI DI PIù</button>
          </div>
        </div>
      </div>
    </section>
  </div> -->
  <!-- Nuova sezione testimonianze -->
  <section class="testimonial-section spacing">
    <div class="">
      <div class="d-flex justify-content-center mb-4">
        <h2 class="w-50">Ecco cosa dicono i lavoratori e le lavoratrici che si sono affidati a Family+Happy:</h2>
      </div>
      <div class="video-container">
        <div class="responsive-iframe">
          <!-- <iframe width="560" height="315" src="https://www.youtube.com/watch?v=OHpCRHbZIe4"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> -->
          <iframe width="560" height="315" src="https://www.youtube.com/embed/OHpCRHbZIe4?si=VE0vKDRHNr2sgmsN"
            title="YouTube video player" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </div>
    </div>
  </section>

  <!-- Nuova sezione vantaggi -->
  <section class="benefits-section spacing">
    <div class="d-flex">
      <div class="col-12 col-md-7 col-lg-7 col-xl-7 d-flex benefits-container">
        <div class="benefits-list">
          <h2>Perché Family+Happy può fare al caso tuo?</h2>
          <p class="intro-text">Family+Happy supporta moltissime famiglie in tutta Italia per aumentare il loro livello
            di serenità. Offre un servizio completo di assistenza con elevati standard qualitativi, pensato per
            migliorare la qualità della vita quotidiana.</p>
          <ul class="custom-list">
            <li>
              <div class="benefit-item">
                <h3><b>UNICITÀ DEL SERVIZIO</b></h3>
                <p>Family+Happy è l'unico servizio di caregiving certificato che forma, profila e associa il profilo
                  ideale
                  per professionalità e intelligenza emotiva.</p>
              </div>
            </li>
            <li>
              <div class="benefit-item">
                <h3><b>ASSISTENZA CONTINUATIVA</b></h3>
                <p>Scegliendo uno o più dei servizi di Family+Happy, potrai contare su un'assistenza dedicata fornita
                  direttamente da un Family Tutor.</p>
              </div>
            </li>
            <li>
              <div class="benefit-item">
                <h3><b>COPERTURA NO-PROBLEM</b></h3>
                <p>Potrai sostituire la tua caregiver fino a due volte al mese, senza dover pagare
                  alcun extra (una volta al mese in città sotto i 100 mila abitanti).</p>
              </div>
            </li>
            <li>
              <div class="benefit-item">
                <h3><b>SUPPORTO FISCALE DEDICATO</b></h3>
                <p>Grazie alla collaborazione con un team specializzato, Family+Happy può gestire per te l'attività di
                  messa in
                  regola dei caregiver.</p>
              </div>
            </li>
          </ul>
          <div class="container-button">
            <button class="submit-btn mb-5 mb-md-0">PRENOTA IL SERVIZIO</button>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-xl-5 img-container d-flex align-items-center">
        <div class="benefits-image">
          <img
            src="https://familyhappybenefit.com/wp-content/uploads/2023/11/pexels-blue-bird-7210754-scaled-1-1024x683.jpg"
            alt="Dog walker con cani" class="image-placeholder">
          <div class="spinner"></div>
        </div>
        <div class="spacer ms-auto"></div>
        <!-- <div class="spinner"></div> -->
      </div>
    </div>
  </section>

  <section class="services-section d-flex justify-content-center mb-5">
    <div class="service-container">
      <div>
        <h2 class="text-center">Alcuni dei nostri servizi comprendono:</h2>
      </div>
      <div>
        <p class="services-list text-center">
          Babysitter diurna | Babysitter notturna | Taxi-sitter | Pet-sitter soggiorno | Pet-sitter domicilio |
          Passeggiata | Badante diurna | Badante occasionale | Badanti conviventi
        </p>
      </div>
      <!-- <div>
        <p class="additional-info text-center">
          Inoltre, Family+Happy garantisce un servizio SOS babysitter entro 90 minuti dalla tua richiesta (verifica se
          attivo nella tua città con il tuo family tutor)
        </p>
      </div> -->
      <div>
        <!-- <p class="price-info text-center">
          In quanto dipendente di un'azienda partner di Family+Happy puoi richiedere le tue ore al prezzo di 7€/h.
        </p> -->
        <button class="submit-btn">ACCEDI AL SERVIZIO</button>
      </div>
    </div>
  </section>


  <section class="family-happy-section">
    <div class="mb-5">
      <h2>Unicredit ha stretto una preziosa collaborazione con Family+Happy, premiata nel 2023 da
        Fortune tra le migliori 3 realtà nell'Healthcare innovativo in Italia.</h2>
    </div>

    <div class="services-grid">
      <div class="service-card">
        <img src="https://familyhappybenefit.com/wp-content/uploads/2023/11/family_happy_7.jpg"
          alt="Babysitter e aiuto compiti">
        <h3>BABYSITTER E AIUTO COMPITI</h3>
      </div>
      <div class="service-card">
        <img
          src="https://familyhappybenefit.com/wp-content/uploads/2023/11/an-elderly-grandmother-with-an-adult-granddaughter-2021-08-26-12-08-49-utc-scaled-1.jpg"
          alt="Badanti">
        <h3>BADANTI</h3>
      </div>
      <div class="service-card">
        <img
          src="https://familyhappybenefit.com/wp-content/uploads/2023/11/happy-pet-sitters-walking-group-of-dogs-on-a-leash-2022-10-10-22-14-34-utc-scaled-1.jpg"
          alt="Pet-sitter">
        <h3>PET-SITTER</h3>
      </div>
      <!-- <div class="service-card">
        <img src="https://familyhappybenefit.com/wp-content/uploads/2023/12/Screenshot-2023-09-21-alle-10.22.29.png" alt="Aiuto commissioni">
        <h3>AIUTO COMMISSIONI</h3>
      </div> -->
    </div>
    <span>
      <p>Affidando i tuoi cari ai professionisti certificati Family+Happy, potrai concentrarti sui tuoi impegni in
        totale
        tranquillità, sapendo che a casa va tutto bene.</p>
    </span>
    <button class="submit-btn">PRENOTA IL SERVIZIO</button>
  </section>

  <section class="benefits-section spacing">
    <div class="d-flex mb-5">
      <div class="col-12 col-md-7 d-flex benefits-container">
        <div class="benefits-list">
          <h2>Come funziona la certificazione in 3 step di Family+Happy, e perché ti garantisce solo i migliori
            professionisti sul mercato?</h2>
          <ul class="custom-list">
            <li>
              <div class="benefit-item">
                <h3><b>Primo step di certificazione: Sicurezza</b></h3>
                <p>Attraverso dei test specifici per ogni tipo di specialista (babysitter, pet-sitter e badante)
                  valutiamo le competenze necessarie per prendersi cura dei tuoi familiari e animali domestici.
                  Ci basiamo su ben 50 parametri, che includono competenze come il primo soccorso, alimentazione sicura
                  e l’esperienza pregressa.</p>
              </div>
            </li>
            <li>
              <div class="benefit-item">
                <h3><b>Secondo step di certificazione: Intelligenza emotiva</b></h3>
                <p>Effettuiamo dei test psicoattitudinali sviluppati dall’università di psicologia, per identificare il
                  profilo più adeguato per la famiglia richiedente.
                  Reputiamo infatti importante che il caregiver abbia una attenzione emotiva facendo sentire amore e
                  apprezzamento anche quando non ci sei.</p>
              </div>
            </li>
            <li>
              <div class="benefit-item">
                <h3><b>Terzo step di certificazione: Screening finale</b></h3>
                <p>Il professionista deve sostenere un colloquio con i nostri Family Tutor, per dimostrare le sue
                  competenze nel prendersi cura materialmente ed emotivamente dei tuoi cari.
                  La selezione è estremamente rigorosa: solo 1 candidato su 20 supera il nostro processo di
                  certificazione.</p>
              </div>
            </li>
          </ul>
          <div class="container-button">
            <button class="submit-btn mb-5 mb-md-0">PRENOTA ORA</button>
          </div>
        </div>
      </div>
      <div class="col-xl-5 col-md-5 img-container d-flex align-items-center">
        <div class="benefits-image">
          <img src="https://familyhappybenefit.com/wp-content/uploads/2023/11/family_happy_17-1024x683.jpg"
            alt="Dog walker con cani" class="image-placeholder">
          <div class="spinner"></div>
        </div>
        <div class="spacer ms-auto"></div>
        <!-- <div class="spinner"></div> -->
      </div>
    </div>
  </section>

  <section class="">
    <div class="media-mentions">
      <h3>Parlano del nostro servizio</h3>
      <div class="media-logos d-flex">
        <div class="row logos-row">
          <div class="col-6 col-lg-3 col-xl-3 col-xxl-3 pt-sm-3 pt-3 m-0">
            <a target="_blank"
              href="https://www.corriere.it/economia/lavoro/24_luglio_17/badanti-caregiver-o-pet-sitter-col-bollino-blu-ci-pensa-family-h-che-batte-anche-il-nero-a305206c-3431-465b-889f-df180ea3bxlk.shtml">
              <source type="image/webp"
                data-lazy-srcset="https://familyhappybenefit.com/wp-content/uploads/2023/11/2.png.webp"
                srcset="https://familyhappybenefit.com/wp-content/uploads/2023/11/2.png.webp">
              <img src="https://familyhappybenefit.com/wp-content/uploads/2023/11/2.png" alt="La Repubblica"
                class="media-logo">
            </a>
          </div>
          <div class="col-6 col-lg-3  col-xl-3 col-xxl-3 pt-sm-3 pt-3 m-0">
            <a target="_blank"
              href="https://www.corriere.it/economia/lavoro/24_luglio_17/badanti-caregiver-o-pet-sitter-col-bollino-blu-ci-pensa-family-h-che-batte-anche-il-nero-a305206c-3431-465b-889f-df180ea3bxlk.shtml">

              <source type="image/webp"
                data-lazy-srcset="https://familyhappybenefit.com/wp-content/uploads/2023/11/4.png.webp"
                srcset="https://familyhappybenefit.com/wp-content/uploads/2023/11/4.png.webp">
              <img src="https://familyhappybenefit.com/wp-content/uploads/2023/11/4.png" alt="Corriere Della Sera"
                class="media-logo">
            </a>
          </div>
          <div class="col-6 col-lg-3  col-xl-3 col-xxl-3 pt-sm-3 pt-3 m-0">
            <a target="_blank"
              href="https://www.corriere.it/economia/lavoro/24_luglio_17/badanti-caregiver-o-pet-sitter-col-bollino-blu-ci-pensa-family-h-che-batte-anche-il-nero-a305206c-3431-465b-889f-df180ea3bxlk.shtml">
              <source type="image/webp"
                data-lazy-srcset="https://familyhappybenefit.com/wp-content/uploads/2023/11/3.png.webp"
                srcset="https://familyhappybenefit.com/wp-content/uploads/2023/11/3.png.webp">
              <img src="https://familyhappybenefit.com/wp-content/uploads/2023/11/3.png" alt="Il Sole 24 ORE"
                class="media-logo">
            </a>
          </div>
          <div class="col-6 col-lg-3 col-xl-3 col-xxl-3 pt-sm-3 pt-3 m-0">
            <a target="_blank"
              href="https://www.corriere.it/economia/lavoro/24_luglio_17/badanti-caregiver-o-pet-sitter-col-bollino-blu-ci-pensa-family-h-che-batte-anche-il-nero-a305206c-3431-465b-889f-df180ea3bxlk.shtml">
              <source type="image/webp"
                data-lazy-srcset="https://familyhappybenefit.com/wp-content/uploads/2023/11/1.png.webp"
                srcset="https://familyhappybenefit.com/wp-content/uploads/2023/11/1.png.webp">
              <img src="https://familyhappybenefit.com/wp-content/uploads/2023/11/1.png" alt="Techstars"
                class="media-logo">
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>

  <footer class="family-happy-footer">
    <div class="company-info">
      <div class="col-12">
        <img src="https://familyhappybenefit.com/wp-content/uploads/2023/11/logowhite.svg" alt="Family+Happy logp"
          class="company-logo">
      </div>
      <div class="contact-info col-12">
        <div class="row contact-info-container gap-3">
          <div class="col-12 col-lg d-flex contatct-mail">
            <a href="mailto:servizioclienti@familyhappybenefit.com" class="contact-item">
              <i aria-hidden="true" class="fas fa-mail-bulk"></i>servizioclienti&#64;familyhappybenefit.com
            </a>
          </div>
          <div class="col-12 col-lg d-flex contact-phone">
            <a href="tel:+393463233515" class="contact-item">
              <i class="fa-solid fa-phone"></i>+393463233515
            </a>
          </div>
          <div class="col-12 col-lg d-flex contact-address">
            <a class="contact-item">
              <i class="fa-solid fa-location-dot"></i>Sede legale: Corso Dante 102, Torino
            </a>
          </div>
        </div>
      </div>
      <p class="copyright pt-3">Copyright 2023 Family+Happy, tutti i diritti riservati P.IVA 12591710012</p>
      <!-- <p class="credits">Creato da Hanami - Web marketing agency</p> -->
    </div>
  </footer>
</div>
