import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Petsitter } from 'npx-family-happy-common';
import { map } from 'rxjs';
import { CaregiverCreationBody } from 'src/app/shared/models/caregiver-creation-body.model';
import { environment } from 'src/environments/environment';

const DEFAULT_FILTERS = {
  age_lower: null,
  age_upper: null,
  cats: false,
  cluster1: false,
  cluster2: false,
  cluster3: false,
  contract: null,
  days: null,
  disability: false,
  doc_ref: null,
  dogs: false,
  dogs1: false,
  dogs2: false,
  dogs3: false,
  dogs4: false,
  drugs: false,
  email: null,
  fhsub: false,
  homeService: false,
  hosting: false,
  hours: null,
  illness: false,
  limit: 10,
  name: null,
  nightShift: false,
  page: 0,
  pension: false,
  phone: null,
  province: null,
  walking: false
}

@Injectable({
  providedIn: 'root'
})
export class PetsitterService {
  baseURL = `${environment.baseURL}/v1/petsitters`;

  constructor(private http: HttpClient) { }

  getSinglePetsitter(id: string) {
    const filters = {
      ...DEFAULT_FILTERS,
      doc_ref: id,
      limit: 1
    };

    return this.http.post<Petsitter[]>(this.baseURL + '/find', filters);
  }

  createPetsitterFromPayment(body: CaregiverCreationBody) {
    return this.http.post<Petsitter>(this.baseURL + '/import-from-payment', body);
  }

  createPetsitter(requestData: Petsitter) {
    return this.http.post(this.baseURL + '/import', requestData);
  }

  updatePetsitterFromForm(petsitter: Petsitter) {
    return this.http.post(this.baseURL + '/import-form', petsitter);
  }

  findPetsitterByEmail(email: string) {
    let request = {
      age_lower: null,
      age_upper: null,
      car: false,
      cleaning: false,
      cluster1: false,
      cluster2: false,
      cluster3: false,
      cluster4: false,
      contract: null,
      cooking: false,
      cv: false,
      days: null,
      disostruzione: false,
      doc_ref: null,
      email: email,
      english: false,
      fhsub: false,
      french: false,
      german: false,
      greenpass: false,
      homework: false,
      hours: null,
      italian: false,
      licence: false,
      limit: 1,
      minimum_evaluation: false,
      name: null,
      not_selected: false,
      not_smoker: false,
      one_ref: false,
      page: 1,
      phone: null,
      province: null,
      spanish: false,
      taxi: false
    };

    return this.http.post<Petsitter[]>(this.baseURL + '/find', request);
  }

  /* Utilities methods */

  // It converts an object to a string. e.g.: {sun: true, mon: false, tue: true} -> 'sun-tue'
  fromAvailObjectToString(object: any, type: string) {
    let result = '';
    if (object) {
      switch (type) {
        case 'hours':
          result += object['mor'] ? 'mor' : '';
          result += object['aft'] ? (result !== '' ? '-aft' : 'aft') : '';
          result += object['eve'] ? (result !== '' ? '-eve' : 'eve') : '';
          return result;
        case 'days':
          result += object['sun'] ? 'sun' : '';
          result += object['mon'] ? (result !== '' ? '-mon' : 'mon') : '';
          result += object['tue'] ? (result !== '' ? '-tue' : 'tue') : '';
          result += object['wed'] ? (result !== '' ? '-wed' : 'wed') : '';
          result += object['thu'] ? (result !== '' ? '-thu' : 'thu') : '';
          result += object['fri'] ? (result !== '' ? '-fri' : 'fri') : '';
          result += object['sat'] ? (result !== '' ? '-sat' : 'sat') : '';
          return result;
        case 'contract':
          result += object['occ'] ? 'occ' : '';
          result += object['part'] ? (result !== '' ? '-part' : 'part') : '';
          result += object['full'] ? (result !== '' ? '-full' : 'full') : '';
          return result;
        case 'catHealing':
          result += object['cat'] ? 'cat' : '';
          return result;
        case 'catHosting':
          result += object['cat'] ? 'cat' : '';
          return result;
        case 'dogHealing':
          result += object['dog'] ? 'dog' : '';
          result += object['dog1'] ? (result !== '' ? '-dog1' : 'dog1') : '';
          result += object['dog2'] ? (result !== '' ? '-dog2' : 'dog2') : '';
          result += object['dog3'] ? (result !== '' ? '-dog3' : 'dog3') : '';
          result += object['dog4'] ? (result !== '' ? '-dog4' : 'dog4') : '';
          return result;
        case 'dogHosting':
          result += object['dog'] ? 'dog' : '';
          result += object['dog1'] ? (result !== '' ? '-dog1' : 'dog1') : '';
          result += object['dog2'] ? (result !== '' ? '-dog2' : 'dog2') : '';
          result += object['dog3'] ? (result !== '' ? '-dog3' : 'dog3') : '';
          result += object['dog4'] ? (result !== '' ? '-dog4' : 'dog4') : '';
          return result;
        case 'tasks1':
          result += object['peeing'] ? 'peeing' : '';
          result += object['games'] ? (result !== '' ? '-games' : 'games') : '';
          result += object['social'] ? (result !== '' ? '-social' : 'social') : '';
          result += object['muzzle'] ? (result !== '' ? '-muzzle' : 'muzzle') : '';
          result += object['other'] ? (result !== '' ? '-other' : 'other') : '';
          return result;
        case 'tasks2':
          result += object['interactions'] ? 'interactions' : '';
          result += object['walking'] ? (result !== '' ? '-walking' : 'walking') : '';
          result += object['other'] ? (result !== '' ? '-other' : 'other') : '';
          return result;
        case 'tasks3':
          result += object['helpers'] ? 'helpers' : '';
          result += object['diseases'] ? (result !== '' ? '-diseases' : 'diseases') : '';
          result += object['other'] ? (result !== '' ? '-other' : 'other') : '';
          return result;
        default:
          return '';
      }
    } else {
      return result;
    }
  }

  // It converts a string to an object (hour, contract or days property of Petsitter). e.g.: 'sun-tue' -> {sun: true, mon: false, tue: true}
  fromStringToAvailObject(origin: string, type: string) {
    if (origin) {
      let keys = origin.split('-') || [];
      switch (type) {
        case 'hours':
          return {
            mor: keys.findIndex(item => item === 'mor') !== -1,
            aft: keys.findIndex(item => item === 'aft') !== -1,
            eve: keys.findIndex(item => item === 'eve') !== -1,
          }
        case 'days':
          return {
            sun: keys.findIndex(item => item === 'sun') !== -1,
            mon: keys.findIndex(item => item === 'mon') !== -1,
            tue: keys.findIndex(item => item === 'tue') !== -1,
            wed: keys.findIndex(item => item === 'wed') !== -1,
            thu: keys.findIndex(item => item === 'thu') !== -1,
            fri: keys.findIndex(item => item === 'fri') !== -1,
            sat: keys.findIndex(item => item === 'sat') !== -1,
          };
        case 'contract':
          return {
            occ: keys.findIndex(item => item === 'occ') !== -1,
            part: keys.findIndex(item => item === 'part') !== -1,
            full: keys.findIndex(item => item === 'full') !== -1,
          };
        case 'catHealing':
          return {
            cat: keys.findIndex(item => item === 'cat') !== -1,
          };
        case 'catHosting':
          return {
            cat: keys.findIndex(item => item === 'cat') !== -1,
          };
        case 'dogHealing':
          return {
            dog: keys.findIndex(item => item === 'dog') !== -1,
            dog1: keys.findIndex(item => item === 'dog1') !== -1,
            dog2: keys.findIndex(item => item === 'dog2') !== -1,
            dog3: keys.findIndex(item => item === 'dog3') !== -1,
            dog4: keys.findIndex(item => item === 'dog4') !== -1,
          };
        case 'dogHosting':
          return {
            dog: keys.findIndex(item => item === 'dog') !== -1,
            dog1: keys.findIndex(item => item === 'dog1') !== -1,
            dog2: keys.findIndex(item => item === 'dog2') !== -1,
            dog3: keys.findIndex(item => item === 'dog3') !== -1,
            dog4: keys.findIndex(item => item === 'dog4') !== -1,
          };
        case 'tasks1':
          return {
            peeing: keys.findIndex(item => item === 'peeing') !== -1,
            games: keys.findIndex(item => item === 'games') !== -1,
            social: keys.findIndex(item => item === 'social') !== -1,
            muzzle: keys.findIndex(item => item === 'muzzle') !== -1,
            other: keys.findIndex(item => item === 'other') !== -1
          }
        case 'tasks2':
          return {
            interactions: keys.findIndex(item => item === 'interactions') !== -1,
            walking: keys.findIndex(item => item === 'walking') !== -1,
            other: keys.findIndex(item => item === 'other') !== -1
          }
        case 'tasks3':
          return {
            helpers: keys.findIndex(item => item === 'helpers') !== -1,
            diseases: keys.findIndex(item => item === 'diseases') !== -1,
            other: keys.findIndex(item => item === 'other') !== -1
          }
        default:
          return {
            occ: false,
            part: false,
            full: false,
          };
      }
    } else {
      switch (type) {
        case 'hours':
          return {
            mor: false,
            aft: false,
            eve: false,
          }
        case 'days':
          return {
            sun: false,
            mon: false,
            tue: false,
            wed: false,
            thu: false,
            fri: false,
            sat: false,
          };
        case 'contract':
          return {
            occ: false,
            part: false,
            full: false,
          };
        case 'catHealing':
          return {
            cat: false,
          };
        case 'catHosting':
          return {
            cat: false,
          };
        case 'dogHealing':
          return {
            dog: false,
            dog1: false,
            dog2: false,
            dog3: false,
            dog4: false,
          };
        case 'dogHosting':
          return {
            dog: false,
            dog1: false,
            dog2: false,
            dog3: false,
            dog4: false,
          };
        case 'tasks1':
          return {
            peeing: false,
            games: false,
            social: false,
            muzzle: false,
            other: false
          }
        case 'tasks2':
          return {
            interactions: false,
            walking: false,
            other: false
          }
        case 'tasks3':
          return {
            helpers: false,
            diseases: false,
            other: false
          }
        default:
          return undefined;
      }
    }
  }

  increaseApplicationsCounter(docRef: string) {
    return this.getSinglePetsitter(docRef).pipe(map(petsitters => {
      let petsitter = petsitters[0];
      petsitter.engagement.applications = petsitter.engagement.applications + 1;
      return this.updatePetsitterFromForm(petsitter);
    }));
  }
}
