import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export function createAddressValidator(addressParts: string[]): ValidatorFn {
  return (): ValidationErrors | null => {
    let requiredParts = ['route', 'street_number', 'postal_code'];

    if (requiredParts.every(part => addressParts.includes(part))) {
      return null;
    } else {
      return { address: true };
    }
  }
}