<div id="cookie-banner" [ngClass]="{'d-none': hideBanner}">
  <button type="button" class="btn-close" (click)="closeBanner(false)"></button>
  <h5 class="mb-3">Informativa</h5>
  <p>Noi e terze parti selezionate utilizziamo cookie o tecnologie simili per finalità tecniche e, con il tuo consenso,
    anche per le finalità di funzionalità, esperienza, misurazione e marketing (con annunci personalizzati) come
    specificato nella <a
      href="https://www.iubenda.com/privacy-policy/56625884/cookie-policy?an=no&s_ck=false&newmarkup=yes"
      class="text-primary">cookie policy</a>.</p>
  <p>Puoi liberamente prestare, rifiutare o revocare il tuo consenso, in qualsiasi momento, accedendo al pannello delle
    preferenze. Il rifiuto del consenso può rendere non disponibili le relative funzioni.</p>
  <p>Usa il pulsante "Accetta" per acconsentire. Usa il pulsante "Rifiuta" o chiudi questa informativa per continuare
    senza accettare.</p>
  <div class="d-flex justify-content-between mt-4">
    <!-- <button id="scopri-di-piu" class="btn btn-secondary">Scopri di più e personalizza</button> -->
    <div>
      <button id="rifiuta-cookies" class="btn btn-primary me-2" (click)="closeBanner(false)">Rifiuta</button>
      <button id="accetta-cookies" class="btn btn-primary" (click)="closeBanner(true)">Accetta</button>
    </div>
  </div>
</div>
