import { environment } from 'src/environments/environment';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { catchError, finalize } from 'rxjs/operators';
import { ModalService } from 'npx-family-happy-common';



@Injectable()
export class CoreHttpInterceptor implements HttpInterceptor {
  private apiKey = environment.BACHECA_KEY;
  constructor(private modalService: ModalService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Display loading modal
    this.modalService.showLoading.next(true);
    let headers: HttpHeaders;

    if (this.apiKey !== '' && !req.url.match('zapier')) {
      headers = new HttpHeaders({
        'X-API-KEY': this.apiKey,
        'Accept': 'application/json, text/plain, */*'
      })
    } else {
      headers = new HttpHeaders({
      })
      console.log('Token expired.')
      //this.router.navigate(['/login']);
    }

    if (req.url.match('https://test.request.familyhappy.xyz/v1/annunci/create')) {
      headers = new HttpHeaders({
        'X-API-KEY': 'sk_test_annunci_vkDzLS1Mf24F03mHJ0lfDYv9H89NP4D8jPVzRHFJoqZYRFsSCJOVGEPO1juw95uM4NHedJ5FuIqB71yMUHoKQzg2pm34mJB3viSG83es7qPsAQoT0j4SOSCCNDkP2MyjI9eCfkHK5vAUjwQSbAYPdVtM7GR8DuU4MTyQiWzX9EpyjtJfe1DIo1Pi3GQJQK39poaJELEngxYPyfX1kukdZ1dLUyzq1fUYWBVCveqvLYHkdNnqA9k9pwc7viAbBUJOY9jj7TiLKHaS9DEPrVfi29GOX1zLZbOeo8R9ABHMvfzrQPt5UdRsxxALHzrNbUzfxo2QzWXkTyX7YDa3tDEDBfFBZAscVZeUHtFU8FDew6vQv9Yuh14embge09SZjBZGBh3bxfC6i5C229hZKouJ6CYFvEq8olRdkEzVkRpd87KDRkB0hYmwP05tr2RSvIB6JTOeKcnQawIZ1eS6Q9pbV8tM34gkH0FzSm3uRWXNgEoIXu7P',
        'Accept': 'application/json, text/plain, */*'
      })
    }

    if (req.url.match('https://request.familyhappy.xyz/v1/annunci/create')) {
      headers = new HttpHeaders({
        'X-API-KEY': 'sk_live_annunci_LwRC1JOKJgVOmGcsExHESsMFtkcbrZIfe7tzGxvEhaU1YXmRER6dKJJsCe8Jj4FgXatt9T8UNiA4OnW4FgaxWe9uOXFD113wDI6p1CAMbZ9O8DyEY5GhyYakijCUzEJrytO733TWEErzrcuiSqzt2LspF7jhIofXAk08V0gEytyeZJGweKJ0FppxM3VR6puvEtWlzRdbgL1AJNfy4hCSubzBeYvowmr22T8R9TwjqTCfIFAMsIQqGHSXLGGoGoFvQ41Ev9q6yEJ72OSQJmCv38GUYPuChyeRIAcKahGxrllKYPh1Anr7zlF0aEvIuJfxfPGzUkRyLHBP3UTkYig7cUy8jCkMA9mVwvm4LKNdHyWggBVDO11hhs620LKChLi2GteuNVLQ7bA8tfvbgWkh5IThb44XvlAyybrttGWgI96wUr6tvhPrJ0Uaw7AYue37NsdNrojRAEOabRDj5kxhMIRnPsktUxIutRNdeEu4oDqaXMyp',
        'Accept': 'application/json, text/plain, */*'
      })
    }

    if (req.url.match('https://test.request.familyhappy.xyz/v1/requests/import-from-unicredit')) {
      headers = new HttpHeaders({
        'X-API-KEY': 'sk_test_firebase_CqdWphhwIZkI6F0yh0Q5Dx9M2pYCwYD617F5BZLkMoBdeSEhFnLOstwcqHPCmjNgDBs7nxBUCwuv6eYuORbMr4BSykXw5uo3LQOOOUNJqnfHM02JMxKxHp1dzjsnTPJMxdztur3QNvpuDaV6DwdDFWtkLFwKMVe76NAveNOGoduR8mUW53NRIedIc2SW1Y86uzW8eKoDGyGjqNbBeXrj3hnsblTI4i3p8dXS3o7fRbIF8ppd9yG0gPRtvVK7GVFpaiMGyGXRXLMncAxC5zHEIYSzqOyEPZo3elb1ty1Mq3Z5VleSnkSCMh9lswficbYcIaexEkhhGFk8iSklelY5VLt6zrbKSZRQL7kDVTXi5krFngr6uIiLtjsKHVF9mN6V3dv0lFVoOsidBHgIL1jFEM4DCmxnCqc6QOYUmfqGKE96fyF3gNvbFVFXvClnftpirG74Ny2TBusssknXmxEXjGX6NyvZBGq0xTC2MiRau2d6LOjliMCt',
        'Accept': 'application/json, text/plain, */*'
      })
    }

    req = req.clone({ headers });

    if (!environment.production)
      console.log(req);

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (!environment.production)
          console.log('HttpErrorResponse: ', error);
        if (error.status == 500) {
          this.showErrorToast(`Sembra si sia verificato un problema sul server,
      si prega di riprovare. Se l'errore dovesse persistere, contatta l'amministratore
      della piattaforma.`, '500 - ERRORE SERVER');
        } else if (error.status == 401) {
          this.showErrorToast('Il token potrebbe essere scaduto. Effettua nuovamente il login.', '401 - NON AUTORIZZATO');
        } else if (error.status == 403) {
          this.showErrorToast(`Non sei autorizzato ad accedere a queste risorse.
      Se pensi ci sia un errore, contatta l'amministratore della piattaforma.`, '403 - ACCESSO NEGATO');
        } else if (error.status == 404) {
          this.showErrorToast(`Non è stato possibile trovare la risorsa indicata. Potrebbe non essere più presente in database.`, '404 - RISORSA NON TROVATA');
        } else {
          this.showErrorToast(error.error?.message, `Si è verificato un errore generico - ${error.status.toString()}`);
        }

        return throwError(error);
      }),
      finalize(() => {
        // Hide loading modal
        this.modalService.showLoading.next(false);
      }),
    ) as Observable<HttpEvent<any>>;
  }

  private showErrorToast(message: string, title: string) {
    this.modalService.outcomeMessage.next(`${title} - ${message}`);
    this.modalService.outcomeType.next('Error');
    this.modalService.showOutcome.next(true);
  }

}
