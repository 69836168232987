import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Babysitter } from 'npx-family-happy-common';
import { map } from 'rxjs';
import { CaregiverCreationBody } from 'src/app/shared/models/caregiver-creation-body.model';

@Injectable({
  providedIn: 'root'
})
export class BabysitterService {
  baseURL = `${environment.baseURL}/v1/babysitters`;

  constructor(private http: HttpClient) { }

  createBabysitterFromPayment(body: CaregiverCreationBody) {
    return this.http.post<Babysitter>(this.baseURL + '/import-from-payment', body);
  }

  createBabySitter(requestData: Babysitter) {
    return this.http.post(this.baseURL + '/import-form', requestData);
  }

  updateBabySitter(requestData: Babysitter) {
    return this.http.post(this.baseURL + '/import', requestData)
  }

  getBabySitter(id: string) {
    let request = {
      age_lower: null,
      age_upper: null,
      car: false,
      cleaning: false,
      cluster1: false,
      cluster2: false,
      cluster3: false,
      cluster4: false,
      contract: null,
      cooking: false,
      cv: false,
      days: null,
      disostruzione: false,
      doc_ref: id,
      email: null,
      english: false,
      fhsub: false,
      french: false,
      german: false,
      greenpass: false,
      homework: false,
      hours: null,
      italian: false,
      licence: false,
      limit: 1,
      minimum_evaluation: false,
      name: null,
      not_selected: false,
      not_smoker: false,
      one_ref: false,
      page: 1,
      phone: null,
      province: null,
      spanish: false,
      taxi: false
    };

    return this.http.post<Babysitter[]>(this.baseURL + '/find', request);
  }

  findBabysitterByEmail(email: string) {
    let request = {
      age_lower: null,
      age_upper: null,
      car: false,
      cleaning: false,
      cluster1: false,
      cluster2: false,
      cluster3: false,
      cluster4: false,
      contract: null,
      cooking: false,
      cv: false,
      days: null,
      disostruzione: false,
      doc_ref: null,
      email: email,
      english: false,
      fhsub: false,
      french: false,
      german: false,
      greenpass: false,
      homework: false,
      hours: null,
      italian: false,
      licence: false,
      limit: 1,
      minimum_evaluation: false,
      name: null,
      not_selected: false,
      not_smoker: false,
      one_ref: false,
      page: 1,
      phone: null,
      province: null,
      spanish: false,
      taxi: false
    };

    return this.http.post<Babysitter[]>(this.baseURL + '/find', request);
  }

  setAttitude(data: Object, id: string) {
    return this.http.patch(this.baseURL + '/update-dangerous-attitude', data, { params: { docRef: id } });
  }

  checkBabysitterCertification(email: string) {
    return this.http.get<boolean>(this.baseURL + '/stripe', { params: { email: email } });
  }

  fromAvailObjectToString(object: any, type: string) {
    let result = '';
    if (object) {
      switch (type) {
        case 'hours':
          result += object['mor'] ? 'mor' : '';
          result += object['aft'] ? (result !== '' ? '-aft' : 'aft') : '';
          result += object['eve'] ? (result !== '' ? '-eve' : 'eve') : '';
          return result;
        case 'days':
          result += object['sun'] ? 'sun' : '';
          result += object['mon'] ? (result !== '' ? '-mon' : 'mon') : '';
          result += object['tue'] ? (result !== '' ? '-tue' : 'tue') : '';
          result += object['wed'] ? (result !== '' ? '-wed' : 'wed') : '';
          result += object['thu'] ? (result !== '' ? '-thu' : 'thu') : '';
          result += object['fri'] ? (result !== '' ? '-fri' : 'fri') : '';
          result += object['sat'] ? (result !== '' ? '-sat' : 'sat') : '';
          return result;
        case 'contract':
          result += object['occ'] ? 'occ' : '';
          result += object['part'] ? (result !== '' ? '-part' : 'part') : '';
          result += object['full'] ? (result !== '' ? '-full' : 'full') : '';
          return result;
        default:
          return '';
      }
    } else {
      return result;
    }
  }

  // It converts a string to an object (hour, contract or days property of Babysitter). e.g.: 'sun-tue' -> {sun: true, mon: false, tue: true}
  fromStringToAvailObject(origin: string, type: string) {
    if (origin) {
      let keys = origin.split('-') || [];
      switch (type) {
        case 'hours':
          return {
            mor: keys.findIndex(item => item === 'mor') !== -1,
            aft: keys.findIndex(item => item === 'aft') !== -1,
            eve: keys.findIndex(item => item === 'eve') !== -1,
          }
        case 'days':
          return {
            sun: keys.findIndex(item => item === 'sun') !== -1,
            mon: keys.findIndex(item => item === 'mon') !== -1,
            tue: keys.findIndex(item => item === 'tue') !== -1,
            wed: keys.findIndex(item => item === 'wed') !== -1,
            thu: keys.findIndex(item => item === 'thu') !== -1,
            fri: keys.findIndex(item => item === 'fri') !== -1,
            sat: keys.findIndex(item => item === 'sat') !== -1,
          };
        case 'contract':
          return {
            occ: keys.findIndex(item => item === 'occ') !== -1,
            part: keys.findIndex(item => item === 'part') !== -1,
            full: keys.findIndex(item => item === 'full') !== -1,
          };
        default:
          return {
            occ: false,
            part: false,
            full: false,
          };
      }
    } else {
      switch (type) {
        case 'hours':
          return {
            mor: false,
            aft: false,
            eve: false,
          }
        case 'days':
          return {
            sun: false,
            mon: false,
            tue: false,
            wed: false,
            thu: false,
            fri: false,
            sat: false,
          };
        case 'contract':
          return {
            occ: false,
            part: false,
            full: false,
          };
        default:
          return undefined;
      }
    }
  }

}
