import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Ad } from 'npx-family-happy-common';
import { MyDatePipe } from 'src/app/date.pipe';

@Component({
  selector: 'app-request-card',
  templateUrl: './request-card.component.html',
  styleUrls: ['./request-card.component.scss'],
  standalone:true,
  imports:[CommonModule, MyDatePipe, RouterModule]
})
export class RequestCardComponent implements OnInit {
  @Input() req!: Ad;

  constructor() { }

  ngOnInit(): void {
  }

}
