import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RequestService } from 'src/app/services/request-service/request.service';
import { FormService } from 'src/app/services/form-service/form.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-matching-form',
  templateUrl: './matching-form.component.html',
  styleUrls: ['./matching-form.component.scss'],
  standalone:true,
  imports:[CommonModule, ReactiveFormsModule]
})
export class MatchingFormComponent implements OnInit {
  form = new UntypedFormGroup({
    1: new UntypedFormControl('', Validators.required),
    2: new UntypedFormControl('', Validators.required),
    3: new UntypedFormControl('', Validators.required),
    4: new UntypedFormControl('', Validators.required),
    5: new UntypedFormControl('', Validators.required),
    6: new UntypedFormControl('', Validators.required),
    7: new UntypedFormControl('', Validators.required),
    8: new UntypedFormControl('', Validators.required),
    9: new UntypedFormControl('', Validators.required),
    10: new UntypedFormControl('', Validators.required),
    11: new UntypedFormControl('', Validators.required),
    12: new UntypedFormControl('', Validators.required)
  });

  requestID!: string;
  requestEmail!: string;
  results = {
    "Coach": 0,
    "Command & Control": 0,
    "Hippy": 0,
    "Insicura": 0
  };

  allAnswers = {
    requestID: '',
    name: '',
    surname: '',
    email: '',
    1: '',
    2: '',
    3: '',
    4: '',
    5: '',
    6: '',
    7: '',
    8: '',
    9: '',
    10: '',
    11: '',
    12: ''
  };

  parentProfile!: string;

  constructor(private activatedRoute: ActivatedRoute, private requestService: RequestService, private formService: FormService) { }

  ngOnInit(): void {
    this.activatedRoute.queryParamMap.subscribe(map => {
      this.requestEmail = map.get('email') ?? '';
      this.allAnswers.email = this.requestEmail;
    });

    this.form.valueChanges.subscribe(value => {
      this.results = {
        "Coach": 0,
        "Command & Control": 0,
        "Hippy": 0,
        "Insicura": 0
      };

      let array: Array<string> = Object.values(value);
      if (this.form.valid) {
        array.forEach((element: string) => {
          switch (element) {
            case "C":
              this.results['Coach']++;
              break;

            case "C&C":
              this.results['Command & Control']++;
              break;

            case "H":
              this.results['Hippy']++;
              break;

            case "I":
              this.results['Insicura']++;
              break;
          }
        });
      }
    });

    this.requestService.getRequestByEmail(this.requestEmail).subscribe((requests: any) => {
      let request = requests[0];
      this.requestID = request.id;
      this.allAnswers.name = request.requestPersonalInfo.parentName;
      this.allAnswers.surname = request.requestPersonalInfo.parentSurname;
      this.allAnswers.requestID = this.requestID;
    });
  }

  shuffle(array: Array<any>) {
    let currentIndex = array.length, randomIndex;

    while (currentIndex != 0) {

      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  getResults() {
    let maxNumber = Math.max(this.results['Coach'], this.results['Command & Control'], this.results['Hippy'], this.results['Insicura']);
    let results = this.shuffle(Object.entries(this.results));
    for (let i = 0; i < results.length; i++) {
      if (results[i][1] === maxNumber) {
        this.parentProfile = results[i][0];
        break;
      }
    }

    let answers = this.form.value;

    this.allAnswers[1] = answers[1];
    this.allAnswers[2] = answers[2];
    this.allAnswers[3] = answers[3];
    this.allAnswers[4] = answers[4];
    this.allAnswers[5] = answers[5];
    this.allAnswers[6] = answers[6];
    this.allAnswers[7] = answers[7];
    this.allAnswers[8] = answers[8];
    this.allAnswers[9] = answers[9];
    this.allAnswers[10] = answers[10];
    this.allAnswers[11] = answers[11];
    this.allAnswers[12] = answers[12];
  }

  submitForm() {
    this.getResults();
    this.requestService.setAttitude(this.parentProfile, this.requestID).subscribe((res) => {
      console.log('Form data sent to Zapier: ', res);
      window.location.href = 'https://babysitter.familyhappy.it/thank-you-matching-genitore';
    });
  }

}
