<app-header-bar></app-header-bar>
<div class="limit-content" style="padding: 10%;">
  <h1 *ngIf="!hasCV">Ci siamo quasi - <b>completa il tuo CV!</b></h1>
  <h1 *ngIf="hasCV && needCVUpdate">Ci siamo quasi - <b>aggiorna il tuo CV!</b></h1>
  <h1 *ngIf="hasCV && !needCVUpdate && !hasPayed && !fromCV"><b>Candidatura completata - {{getCategory() | titlecase }} NON certificata</b></h1>
  <h1 *ngIf="hasCV && !needCVUpdate && hasPayed"><b>Candidatura completata</b></h1>
  <h1 *ngIf="fromCV && !needCVUpdate && !hasPayed" style="line-height: 55px;"><b>Complimenti, hai completato il processo di candidatura.</b></h1>

  <div class="divider" style="margin-bottom: 10px;"></div>

  <div *ngIf="!hasCV || needCVUpdate">
    <p>{{ caregiverName }}, grazie per la tua candidatura alla richiesta {{ requestID }}. Ti
      ricordiamo che le nostre Family Tutor inviano alla famiglia i profili {{getCategory()}} completi di curriculum.</p>
    <p>Per rendere efficace la tua candidatura clicca sul pulsante qui sotto e completa il tuo CV.</p>
    <p>Dovrai farlo solo una volta: lo utilizzeremo anche per tutte le tue prossime candidature.</p>
  </div>

  <div *ngIf="hasCV && !needCVUpdate && !hasPayed && !fromCV">
    <p>{{ caregiverName }}, grazie per la tua candidatura alla richiesta{{ requestID ? requestID + ' ' : ''}},
      sei stata inserita tra le candidate non certificate Family+Happy.</p>
    <p>Perché la certificazione è importante?</p>
    <p>Le famiglie preferiscono le {{getCategory(true)}} certificate Family+Happy, perché acquisiscono competenze che massimizzano la
      sicurezza dei loro cari. Il 58% delle {{getCategory(true)}} certificate ha trovato lavoro entro 1 mese!</p>
  </div>

  <div *ngIf="hasCV && !needCVUpdate && hasPayed">
    <p>{{ caregiverName }}, grazie per la tua candidatura alla richiesta{{ requestID ? requestID + ' ' : ''}}.
      Le nostre Family Tutor selezioneranno i profili più in linea con le esigenze della famiglia.</p>
    <p>Riceviamo tantissime candidature per ogni annuncio, ricontattiamo solo le {{getCategory(true)}} selezionate.</p>
  </div>

  <div *ngIf="fromCV && !needCVUpdate && !hasPayed">
    <p>
      Cosa succederà ora? Il sistema selezionerà i profili che sono in linea con le richieste espresse nell’annuncio.<br>
      Se supererai la prima fase di selezione sarai contattat* da un* nostr* recruiter, entro massimo 3 giorni lavorativi circa dalla tua candidatura.<br>
      Per passare alla seconda e ultima fase della selezione dovrai certificarti (tempo necessario 3 ore totali).
    </p>

    <p>
      <b>
        Desideri velocizzare i tempi e accedere a tutte le offerte comodamente da WhatsApp?<br>
        Con la certificazione il 68% delle {{getCategory(true)}} trova lavoro entro un mese.<br>
      </b>
      Oltre a questo, insieme ai nostri partner, abbiamo sviluppato una selezione di corsi di professionalizzazione con specialisti del settore.
    </p>
  </div>

  <div class="btn-container" style="margin-top: 10px">
    <a *ngIf="(!hasCV || needCVUpdate) && caregiverId && requestCategory === 'BABYSITTER'" routerLink="/babysitter-form" [queryParams]="{id: caregiverId}">
      <button class="my-button" type="button">Compila CV</button>
    </a>
    <a *ngIf="(!hasCV || needCVUpdate) && caregiverId && requestCategory === 'PETSITTER'" routerLink="/petsitter-form" [queryParams]="{id: caregiverId}">
      <button class="my-button" type="button">Compila CV</button>
    </a>
    <a *ngIf="(!hasCV || needCVUpdate) && caregiverId && requestCategory === 'NURSE'" routerLink="/nurse-form" [queryParams]="{id: caregiverId}">
      <button class="my-button" type="button">Compila CV</button>
    </a>
    <a *ngIf="(!hasCV || needCVUpdate) && caregiverId && requestCategory === 'COLF'" routerLink="/colf-form" [queryParams]="{id: caregiverId}">
      <button class="my-button" type="button">Compila CV</button>
    </a>

    <a *ngIf="hasCV && !needCVUpdate && !hasPayed && requestCategory === 'BABYSITTER'" href="https://familyhappybenefit.com/babysitter-lavora-con-noi-vsl">
      <button class="my-button" type="button">Scopri la certification Family+Happy</button>
    </a>

    <a *ngIf="hasCV && !needCVUpdate && !hasPayed && requestCategory === 'PETSITTER'" href="https://familyhappybenefit.com/lp-petsitter-lavora-con-noi-vsl">
      <button class="my-button" type="button">Scopri la certification Family+Happy</button>
    </a>
    <a *ngIf="hasCV && !needCVUpdate && !hasPayed && requestCategory === 'NURSE'" href="https://familyhappybenefit.com/lp-badanti-lavora-con-noi-vsl">
      <button class="my-button" type="button">Scopri la certification Family+Happy</button>
    </a>
    <a *ngIf="hasCV && !needCVUpdate && !hasPayed && requestCategory === 'COLF'" href="https://familyhappybenefit.com/lp-colf-lavora-con-noi-vsl">
      <button class="my-button" type="button">Scopri la certification Family+Happy</button>
    </a>

    <a *ngIf="hasCV && !needCVUpdate && hasPayed" routerLink="/bacheca">
      <button class="my-alt-button" type="button">Torna agli annunci</button>
    </a>
  </div>
</div>
