<div style="margin: 50px auto;" class="limit-content">
  <div class="title-container">
    <h1>WEBINAR CORSO SICUREZZA ANIMALE</h1>

    <p style="margin: 16px auto;max-width: 800px;text-align: center;">Rispondi correttamente almeno all'80% delle domande. Superato il test ti invieremo nel corso di 72 ore l'attestato al tuo indirizzo email.</p>
  </div>

  <form [formGroup]="personalInfo">
    <div class="personal-info">
      <div>
        <label for="email">EMAIL</label>
        <input id="email" type="text" formControlName="email" autocomplete="email" required/>
        <p style="padding: 0; margin-top: 10px;" id="email-alert" *ngIf="personalInfo.value.email === '' || personalInfo.get('email')?.errors">Indica l'indirizzo email di iscrizione a Family+Happy</p>
        <p style="padding: 0" id="email-alert" *ngIf="personalInfo.get('email')?.errors">Fornisci un indirizzo email valido</p>
      </div>
    </div>
  </form>

  <form [formGroup]="form" class="main-form">
    <div class="blue-box"></div>
    <div class="grid-container">
      <div>
        <span>1. Il colpo di calore cosa può causare?</span>
        <div class="radio-list">
          <label for="1-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('1')?.value === 'A')}">
              <input style="display: none" type="radio" id="1-a" formControlName="1" name="1" value="A" />
              Coma
            </div>
          </label>

          <label for="1-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('1')?.value === 'B')}">
              <input style="display: none" type="radio" id="1-b" formControlName="1" name="1" value="B" />
              Convulsioni
            </div>
          </label>

          <label for="1-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('1')?.value === 'C')}">
              <input style="display: none" type="radio" id="1-c" formControlName="1" name="1" value="C" />
              Morte
            </div>
          </label>

          <label for="1-d">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('1')?.value === 'D')}">
              <input style="display: none" type="radio" id="1-d" formControlName="1" name="1" value="D" />
              Tutte le precedenti
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>2. Come possiamo abbassare la temperatura corporea dell'animale?</span>
        <div class="radio-list">
          <label for="2-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('2')?.value === 'A')}">
              <input style="display: none" type="radio" id="2-a" formControlName="2" name="2" value="A" />
              Mettere ghiaccio sull'inguine e sulla testa
            </div>
          </label>

          <label for="2-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('2')?.value === 'B')}">
              <input style="display: none" type="radio" id="2-b" formControlName="2" name="2" value="B" />
              Cospargere il corpo di alcol, compresa la testa
            </div>
          </label>

          <label for="2-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('2')?.value === 'C')}">
              <input style="display: none" type="radio" id="2-c" formControlName="2" name="2" value="C" />
              Bagnare l'animale, compresa la testa
            </div>
          </label>

          <label for="2-d">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('2')?.value === 'D')}">
              <input style="display: none" type="radio" id="2-d" formControlName="2" name="2" value="D" />
              Nessuna delle precedenti
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>3. Di cosa soffre più frequentemente il gatto?</span>
        <div class="radio-list">
          <label for="3-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('3')?.value === 'A')}">
              <input style="display: none" type="radio" id="3-a" formControlName="3" name="3" value="A" />
              Bronchite asmatica
            </div>
          </label>

          <label for="3-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('3')?.value === 'B')}">
              <input style="display: none" type="radio" id="3-b" formControlName="3" name="3" value="B" />
              Vomito
            </div>
          </label>

          <label for="3-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('3')?.value === 'C')}">
              <input style="display: none" type="radio" id="3-c" formControlName="3" name="3" value="C" />
              Nessuna delle precedenti
            </div>
          </label>

          <label for="3-d">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('3')?.value === 'D')}">
              <input style="display: none" type="radio" id="3-d" formControlName="3" name="3" value="D" />
              Tutte le precedenti
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>4. La dispnea: come riconoscerla?</span>
        <div class="radio-list">
          <label for="4-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('4')?.value === 'A')}">
              <input style="display: none" type="radio" id="4-a" formControlName="4" name="4" value="A" />
              Respirazione superficiale
            </div>
          </label>

          <label for="4-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('4')?.value === 'B')}">
              <input style="display: none" type="radio" id="4-b" formControlName="4" name="4" value="B" />
              Respirazione accelerata
            </div>
          </label>

          <label for="4-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('4')?.value === 'C')}">
              <input style="display: none" type="radio" id="4-c" formControlName="4" name="4" value="C" />
              Nessuna delle precedenti
            </div>
          </label>
          <label for="4-d">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('4')?.value === 'D')}">
              <input style="display: none" type="radio" id="4-d" formControlName="4" name="4" value="D" />
              Tutte le precedenti
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>5. L'epistassi: quali sono le cause?</span>
        <div class="radio-list">
          <label for="5-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('5')?.value === 'A')}">
              <input style="display: none" type="radio" id="5-a" formControlName="5" name="5" value="A" />
              Infettive
            </div>
          </label>

          <label for="5-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('5')?.value === 'B')}">
              <input style="display: none" type="radio" id="5-b" formControlName="5" name="5" value="B" />
              Traumatiche
            </div>
          </label>

          <label for="5-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('5')?.value === 'C')}">
              <input style="display: none" type="radio" id="5-c" formControlName="5" name="5" value="C" />
              Nessuna delle precedenti
            </div>
          </label>

          <label for="5-d">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('5')?.value === 'D')}">
              <input style="display: none" type="radio" id="5-d" formControlName="5" name="5" value="D" />
              Tutte le precedenti
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>6. In che età si presentano le malattie virali?</span>
        <div class="radio-list">
          <label for="6-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('6')?.value === 'A')}">
              <input style="display: none" type="radio" id="6-a" formControlName="6" name="6" value="A" />
              Principalmente nei cuccioli
            </div>
          </label>

          <label for="6-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('6')?.value === 'B')}">
              <input style="display: none" type="radio" id="6-b" formControlName="6" name="6" value="B" />
              Principalmente negli adulti
            </div>
          </label>

          <label for="6-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('6')?.value === 'C')}">
              <input style="display: none" type="radio" id="6-c" formControlName="6" name="6" value="C" />
              Principalmente negli anziani
            </div>
          </label>

          <label for="6-d">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('6')?.value === 'D')}">
              <input style="display: none" type="radio" id="6-d" formControlName="6" name="6" value="D" />
              Nessuna delle precedenti
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>7. La Leishmaniosi è tipica di quale zona?</span>
        <div class="radio-list">
          <label for="7-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('7')?.value === 'A')}">
              <input style="display: none" type="radio" id="7-a" formControlName="7" name="7" value="A" />
              Del Sud Italia
            </div>
          </label>

          <label for="7-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('7')?.value === 'B')}">
              <input style="display: none" type="radio" id="7-b" formControlName="7" name="7" value="B" />
              Del Nord Italia
            </div>
          </label>

          <label for="7-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('7')?.value === 'C')}">
              <input style="display: none" type="radio" id="7-c" formControlName="7" name="7" value="C" />
              Di tutta Italia
            </div>
          </label>

          <label for="7-d">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('7')?.value === 'D')}">
              <input style="display: none" type="radio" id="7-d" formControlName="7" name="7" value="D" />
              Nessuna delle precedenti
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>8. Quali sono i sintomi della Leishmaniosi?</span>
        <div class="radio-list">
          <label for="8-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('8')?.value === 'A')}">
              <input style="display: none" type="radio" id="8-a" formControlName="8" name="8" value="A" />
              Pelo secco
            </div>
          </label>

          <label for="8-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('8')?.value === 'B')}">
              <input style="display: none" type="radio" id="8-b" formControlName="8" name="8" value="B" />
              Aspetto "di cane con occhiali"
            </div>
          </label>

          <label for="8-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('8')?.value === 'C')}">
              <input style="display: none" type="radio" id="8-c" formControlName="8" name="8" value="C" />
              Tutte le precedenti
            </div>
          </label>

          <label for="8-d">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('8')?.value === 'D')}">
              <input style="display: none" type="radio" id="8-d" formControlName="8" name="8" value="D" />
              Nessuna delle precedenti
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>9. Le malattie intestinali sono tipiche di?</span>
        <div class="radio-list">
          <label for="9-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('9')?.value === 'A')}">
              <input style="display: none" type="radio" id="9-a" formControlName="9" name="9" value="A" />
              Dei cuccioli
            </div>
          </label>

          <label for="9-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('9')?.value === 'B')}">
              <input style="display: none" type="radio" id="9-b" formControlName="9" name="9" value="B" />
              Del cane adulto
            </div>
          </label>

          <label for="9-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('9')?.value === 'C')}">
              <input style="display: none" type="radio" id="9-c" formControlName="9" name="9" value="C" />
              Tutte le precedenti
            </div>
          </label>

          <label for="9-d">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('9')?.value === 'D')}">
              <input style="display: none" type="radio" id="9-d" formControlName="9" name="9" value="D" />
              Nessuna delle precedenti
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>10. Quali sono le malattie geriatriche canine più frequenti?</span>
        <div class="radio-list">
          <label for="10-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('10')?.value === 'A')}">
              <input style="display: none" type="radio" id="10-a" formControlName="10" name="10" value="A" />
              Patologie neurodegenerative
            </div>
          </label>

          <label for="10-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('10')?.value === 'B')}">
              <input style="display: none" type="radio" id="10-b" formControlName="10" name="10" value="B" />
              Patologie respiratorie
            </div>
          </label>

          <label for="10-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('10')?.value === 'C')}">
              <input style="display: none" type="radio" id="10-c" formControlName="10" name="10" value="C" />
              Patologie autoimmuni
            </div>
          </label>

          <label for="10-d">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('10')?.value === 'D')}">
              <input style="display: none" type="radio" id="10-d" formControlName="10" name="10" value="D" />
              Nessuna delle precedenti
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>11. Quali sono le differenze tra cani e gatti?</span>
        <div class="radio-list">
          <label for="11-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('11')?.value === 'A')}">
              <input style="display: none" type="radio" id="11-a" formControlName="11" name="11" value="A" />
              Il ritmo cardiaco del gatto è superiore a quello del cane
            </div>
          </label>

          <label for="11-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('11')?.value === 'B')}">
              <input style="display: none" type="radio" id="11-b" formControlName="11" name="11" value="B" />
              Il gatto ha perso le capacità di caccia
            </div>
          </label>

          <label for="11-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('11')?.value === 'C')}">
              <input style="display: none" type="radio" id="11-c" formControlName="11" name="11" value="C" />
              I dosaggi dei medicinali del gatto sono inferiori rispetto al cane
            </div>
          </label>

          <label for="11-d">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('11')?.value === 'D')}">
              <input style="display: none" type="radio" id="11-d" formControlName="11" name="11" value="D" />
              Tutte le precedenti
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>12. Repellenti antiparassitari:</span>
        <div class="radio-list">
          <label for="12-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('12')?.value === 'A')}">
              <input style="display: none" type="radio" id="12-a" formControlName="12" name="12" value="A" />
              Possono essere usati gli stessi antiparassitari sia per cani che per gatti
            </div>
          </label>

          <label for="12-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('12')?.value === 'B')}">
              <input style="display: none" type="radio" id="12-b" formControlName="12" name="12" value="B" />
              Vanno differenziati in base alla specie dell'animale
            </div>
          </label>

          <label for="12-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('12')?.value === 'C')}">
              <input style="display: none" type="radio" id="12-c" formControlName="12" name="12" value="C" />
              Vanno usate le stesse dosi di antiparassitari rispetto alla specie, ma possono essere usati gli stessi nelle diverse razze
            </div>
          </label>

          <label for="12-d">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('12')?.value === 'D')}">
              <input style="display: none" type="radio" id="12-d" formControlName="12" name="12" value="D" />
              Tutte le precedenti
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>13. Le esigenze alimentari:</span>
        <div class="radio-list">
          <label for="13-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('13')?.value === 'A')}">
              <input style="display: none" type="radio" id="13-a" formControlName="13" name="13" value="A" />
              Cambiano rispetto all'età
            </div>
          </label>

          <label for="13-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('13')?.value === 'B')}">
              <input style="display: none" type="radio" id="13-b" formControlName="13" name="13" value="B" />
              Sono uguali a discapito dell'età
            </div>
          </label>

          <label for="13-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('13')?.value === 'C')}">
              <input style="display: none" type="radio" id="13-c" formControlName="13" name="13" value="C" />
              Cambiano rispetto allo stato di salute
            </div>
          </label>

          <label for="13-d">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('13')?.value === 'D')}">
              <input style="display: none" type="radio" id="13-d" formControlName="13" name="13" value="D" />
              A e C
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>14. Alimentazione consigliata per cuccioli di razza grande:</span>
        <div class="radio-list">
          <label for="14-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('14')?.value === 'A')}">
              <input style="display: none" type="radio" id="14-a" formControlName="14" name="14" value="A" />
              Consigliabile alimentazione casalinga
            </div>
          </label>

          <label for="14-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('14')?.value === 'B')}">
              <input style="display: none" type="radio" id="14-b" formControlName="14" name="14" value="B" />
              Consigliabile alimentazione industriale
            </div>
          </label>

          <label for="14-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('14')?.value === 'C')}">
              <input style="display: none" type="radio" id="14-c" formControlName="14" name="14" value="C" />
              Entrambe le alimentazioni sono consigliate
            </div>
          </label>
        </div>
      </div>

      <div>
        <span>15. Alimentazione consigliata per cuccioli di razza piccola:</span>
        <div class="radio-list">
          <label for="15-a">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('15')?.value === 'A')}">
              <input style="display: none" type="radio" id="15-a" formControlName="15" name="15" value="A" />
              Consigliabile alimentazione casalinga
            </div>
          </label>

          <label for="15-b">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('15')?.value === 'B')}">
              <input style="display: none" type="radio" id="15-b" formControlName="15" name="15" value="B" />
              Consigliabile alimentazione industriale
            </div>
          </label>

          <label for="15-c">
            <div class="radio-btn" [ngClass]="{'selected': (form.get('15')?.value === 'C')}">
              <input style="display: none" type="radio" id="15-c" formControlName="15" name="15" value="C" />
              Entrambe le alimentazioni sono consigliate
            </div>
          </label>
        </div>
      </div>
    </div>

    <div class="submit-btn-container">
      <button type="submit" class="button" [disabled]="!form.valid || !personalInfo.valid" (click)="submitForm()">Invia</button>
    </div>
    <div class="pink-box"></div>
  </form>
</div>
<div class="position-absolute bg-white" style="height: 100vh; width: 100vw; z-index: -5; left: 0px;bottom: 0px;">

</div>
<div class="position-absolute" style="z-index: -10; left: 0px;bottom: 0px;">
  <div class="position-relative" id="pet-certificate">
    <img src="/assets/img/attestato_pet.png" class="w-100" alt="">
    <p id="certificate-name" class="position-absolute text-center w-100 fw-bold" style="top: 56%;font-size: 40px;">Ivan Colucci</p>
  </div>
</div>
<app-modal-loader [openModal]="modalLoader" *ngIf="modalLoader"></app-modal-loader>
